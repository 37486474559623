import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, useRef, useCallback} from "react";
import { Text, View, Dimensions, TouchableOpacity, Linking,PixelRatio, Image, ImageBackground } from "react-native";
import { Card, TextInput, List, ActivityIndicator, } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import * as Animatable from 'react-native-animatable';  
import Feather from 'react-native-vector-icons/Feather';
Feather.loadFont();
import * as Device from 'expo-device';//Device.osName

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';


const {width, height} = Dimensions.get('screen');
const DadosNovaSenha = [];

export default function App({route, navigation}) {
    const { signUp, signOut, signed, alterperfil, setAlterPerfil} = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso } = useContext(UtilContext);
    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [novasenha, setNovaSenha] = useState('');
    const [coligada, setColigada] = useState('');
    const [filial, setFilial] = useState('');
    const [listafilial, setListaFilial] = useState([]);
    const [isfilial, setIsFilial] = useState(true);
    const [isnewpwd, setIsNewPwd] = useState(false);
    const [isdev, setDev] = useState('');
    
    /* const listafilial = [
            {id: "0", name: "Filial 0", title: "Filial 00", filial: 0, coligada: 0},
            {id: "1", name: "Filial 1", title: "Filial 11", filial: 1, coligada: 0},
            {id: "2", name: "Filial 2", title: "Filial 22", filial: 2, coligada: 0},
            {id: "3", name: "Filial 3", title: "Filial 33", filial: 3, coligada: 0},
            {id: "4", name: "Filial 4", title: "Filial 44", filial: 4, coligada: 0},
            {id: "5", name: "Filial 5", title: "Filial 55", filial: 5, coligada: 0}
    ]; */

    const searchRefFilial = useRef(null);
    const onOpenSuggestionsListFilial = useCallback(isOpened => {}, []);
    const dropdownControllerfilial = useRef(null);
    const getItemFiltro = (item) => {
        //setFilial(item.filial);
        //alert(`filial:${item.filial}\ncodcoligada: ${item.codcoligada}\nnomefantasia: ${item.title}\n`);
        setColigada(item.codcoligada);
        setFilial(item.filial);
      };

    
    //const searchRefTipo = useRef(null);
    useEffect(() => { 
        document.title = dotenv.TITLE;  
        //fetchDataFiliais();   
        //console.log("Idex-Login: "+signed); 
        Linking.getInitialURL().then((url) => {
            const newUrl = url.split(".br/");
            if(newUrl[0] === 'https://devgestor.literatus.edu' ){
              setDev('- Dev');
            }else {
              setDev('');
            }
          }); 
    }, []);

    /* const fetchDataFiliais = async () => {  
        try {        
              var url = `${dotenv.LITERATUS}/filial`;
              //console.log(`RA DO ALUNO: ${route.params.contratos[0].RA}`);   
              setIsFilial(false);           
              await fetch(url, {
                      method: 'GET',
                      // body: JSON.stringify({
                      //    raaluno: route.params.raaluno?? route.params.contratos[0].RA,
                      //}),
                      timeout: 5000,
                      headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          //"x-access-token": user.token,
                      },
                    })
                  .then((response) => response.json())
                  .then((responseJson) => {                
                    if(responseJson.sucesso){
                      setListaFilial(responseJson.filiais);
                      setIsFilial(true);
                    }else{
                      alert("Erro1: " + responseJson.message);
                      setIsFilial(true);
                    }            
                  })
                  .catch((error) => {
                    setIsFilial(true);
                    if(error.message === 'Failed to fetch'){
                        alert("Erro na Consulta com Servidor");                      
                      }else{
                        alert("Erro2: " + error.message);
                      }
                                                     
                  })
                  .finally(()=>{
                    setIsFilial(true);
                  })
            
        } catch (err) {
            alert(err.message);
        } finally {{}}
    } */

    async function AtualizaSenha(){

              setIsFilial(false);           
              await fetch(`${dotenv.RESTGESTOR}/novasenha`, {
                      method: 'POST',
                      body: JSON.stringify({
                          id: DadosNovaSenha[0].id,
                          login: DadosNovaSenha[0].login,
                          senha: novasenha
                      }),
                      timeout: 5000,
                      headers: {
                          "Content-type": "application/json; charset=UTF-8",
                          //"x-access-token": user.token,
                      },
                    })
                  .then((response) => response.json())
                  .then((responseJson) => {                
                    if(responseJson.sucesso){                        
                        setIsFilial(true);
                        signUp(DadosNovaSenha[0].nome, DadosNovaSenha[0].superadmin, DadosNovaSenha[0].admin, DadosNovaSenha[0].gestor, DadosNovaSenha[0].administrativo, DadosNovaSenha[0].foto, DadosNovaSenha[0].coligada, DadosNovaSenha[0].filial, DadosNovaSenha[0].predio, DadosNovaSenha[0].codpredio, DadosNovaSenha[0].ativo, 0, DadosNovaSenha[0].token );                        
                        //LIMPAR ARRAY
                        DadosNovaSenha.slice(0);
                        Aviso("", responseJson.message, "success", 3000);
                    }else{
                      //signOut();
                      //alert(responseJson.message);
                      alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## ERROR ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )

                      setIsFilial(true);
                    }            
                  })
                  .catch((error) => {
                    //signOut();
                    setIsFilial(true);
                    if(error.message === 'Failed to fetch'){
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## COMUNICAÇÃO - REST ##', // This is a title
                            `Erro na Consulta com Servidor`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )                      
                      }else{
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## ERROR ##', // This is a title
                            `ErroNovaSenha: ${error.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        //console.log(error.message);
                      }                                                     
                  })
                  .finally(()=>{
                    setIsFilial(true);
                  })

        
    }

    async function validtokenacesso (){
        let url = `https://restlicense.t2kconsultoria.com.br/acesso`;
        //valid token
        await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                sistema:'literatusempresacontrato',
                //token: token
            }),
            timeout: 30000,
            headers: {
              "Content-type": "application/json; charset=UTF-8"
            },
          })
        .then((response) => response.json())
        .then((responseJson) => {
            //
            if (responseJson.acesso == true){
                //acesso válido

            }else{
                setValid(false);
                alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
                signOut();
                return false;
            }
        })
        .catch((error) => {
            alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
            signOut();
            return false;
        })
        .finally(function () {}); 
    }

    const fetchDataLogin = async (login, senha/* , coligada, filial */) => {  
        try {        
                   
        
        var url = `${dotenv.RESTGESTOR}/validlogin`;
                //console.log(`RA DO ALUNO: ${route.params.contratos[0].RA}`);   
                setIsFilial(false);           
                await fetch(url, {
                        method: 'POST',
                        body: JSON.stringify({
                            login: login,
                            senha: senha,
                            //coligada: coligada,
                            //filial: filial
                        }),
                        timeout: 5000,
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            //"x-access-token": user.token,
                        },
                      })
                    .then((response) => response.json())
                    .then((responseJson) => {                
                      if(responseJson.sucesso){
                          //console.log(responseJson);


                          validtokenacesso ();


                          if(responseJson.dados.trocasenha == 1){
                              setIsNewPwd(true);
                              alert('Identificamos que é seu primeiro login, informe uma nova senha!');
                              
                              DadosNovaSenha.push({
                                  id: responseJson.dados.idlogin,
                                  login: responseJson.dados.login,
                                  nome: responseJson.dados.nome,
                                  superadmin: responseJson.dados.superadmin, 
                                  admin: responseJson.dados.admin, 
                                  gestor: responseJson.dados.gestor, 
                                  administrativo: responseJson.dados.administrativo, 
                                  foto: responseJson.dados.foto, 
                                  coligada: responseJson.dados.coligada, 
                                  filial: responseJson.dados.filial, 
                                  predio: responseJson.dados.predio, 
                                  codpredio: responseJson.dados.codpredio,
                                  ativo: responseJson.dados.ativo, 
                                  trocasenha: responseJson.dados.trocasenha, 
                                  token: responseJson.token,
                              });
                              if(responseJson.dados.codpredio=='000')
                              setAlterPerfil(true);
                          }else{
                              if(responseJson.dados.codpredio=='000')
                              setAlterPerfil(true);
                          
                              signUp(responseJson.dados.nome,responseJson.dados.superadmin, responseJson.dados.admin, responseJson.dados.gestor, responseJson.dados.administrativo, responseJson.dados.foto, responseJson.dados.coligada, responseJson.dados.filial, responseJson.dados.predio, responseJson.dados.codpredio, responseJson.dados.ativo, responseJson.dados.trocasenha, responseJson.token, responseJson.dados.codpredio=='000'?true:false);                        
                          }
                          setIsFilial(true);
                      }else{
                          //alert(responseJson.message);
                          Aviso("Error:", responseJson.message, "danger", 3000); 
                          //signOut();
                        setIsFilial(true);
                      }            
                    })
                    .catch((error) => {
                      //signOut();
                      setIsFilial(true);
                      if(error.message === 'Failed to fetch'){
                          alert("Erro na Consulta com Servidor");                      
                        }else{
                          alert("Erro2: " + error.message);
                          console.log(error.message);
                        }
                                                       
                    })
                    .finally(()=>{
                      setIsFilial(true);
                    })
            
            
            
            
            
            
            
            
            
            
             
            
        } catch (err) {
            alert(err.message);
        } finally {{}}
    }

    return (
        <ImageBackground
            //source={require(Device.osName=='Windows' ? '../src/img/background6.png' : '../src/img/background6.1.png')}
            //source={require('../../imagem/BG---1980-x-1080.png')} 
            source={require('../../imagem/tela-portal-gestores.png')} 
            style={[styles.imagebg,{}]}
            
        >
        <View style={[styles.container,{backgroundColor:null}]}>            
            <View style={styles.layoutdirsup}>    
            <Animatable.View 
                style={{position: 'absolute', top:20}}
                animation={'fadeInDown'}
                delay={500}
            >
                {/* <Text style={{flex:1, color:'#FFF', fontWeight: 'bold' ,fontSize: Device.osName=='Windows'? '30px' : '17px', textAlign:'center', padding: 5, width: width}}>Bem-vindo ao</Text>
                <Text style={{flex:1, color:'#FFF', fontWeight: 'bold', fontSize: Device.osName=='Windows'? '50px' : '23px', textAlign:'center', padding: 5, width: width}}>{`Portal do Gestor ${isdev?? ''}`}</Text> */}
                {/* <Text style={{flex:1, color:'#FFF', fontWeight: 'bold', fontSize: Device.osName=='Windows'? '25px' : '17px', textAlign:'center', padding: 5, width: width}}>É um enorme prazer tê-lo conosco!</Text> */}
            </Animatable.View>        
                <View style={[styles.layoutdirsup, {flex:1, position:'relative',alignItems: 'center', justifyContent:'center',top: Device.osName=='Windows' ? 10 : -30}]}>
                    <Card style={[styles.card,{flexWrap:'wrap',  maxWidth: 500, height: Device.osName=='Windows' ? '40%' : 180}]}>
                      <Container>
                          <Form style={{/* width: '35vw', */  maxWidth: 450}}>
                              <Row>
                                  <Form.Group as={Col}>
                                      {/* CAMPOS INPUTS */}
                                      <Col>
                                      <TextInput 
                                          label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Informe o Login</Text>}
                                          style={{marginBottom: 10}}
                                          mode='outlined'
                                          selectionColor={'#004b9e'}
                                          underlineColor={'#004b9e'}
                                          activeUnderlineColor={'#004b9e'}
                                          outlineColor={'#004b9e'}
                                          activeOutlineColor={'#004b9e'}
                                          textColor={'#004b9e'}
                                          dense={true}
                                          value={login}
                                          onChangeText={(text) => {setLogin(text)}}
                                          left={<TextInput.Icon icon="account" disabled={true}/>}                                          
                                      />
                                      </Col>
                                      <Col>
                                      <TextInput 
                                        //label={'Informe a Senha'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Informe a Senha</Text>}                                          
                                        style={{marginBottom: 10}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={senha}
                                        secureTextEntry={true}
                                        onChangeText={(text) => {setSenha(text)}}
                                        disabled={isnewpwd}
                                        onSubmitEditing={()=>{
                                            if(login=="" || senha==""){
                                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                                    '## LOGIN - PORTAL GESTOR ##', // This is a title
                                                    `Informações Pendednte!`,
                                                    {
                                                        textConfirm: 'OK',      // Label of button confirm
                                                        textCancel: '',     // Label of button cancel
                                                        onCancel: () => { { } }, // Call your cancel function 
                                                        onConfirm: () => { { } } // Call your confirm function 
                                                    }
                                                )
                                            }else{
                                                fetchDataLogin(login, senha/* , coligada,filial */);
                                            }
                                        }}
                                        left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>} 
                                    />
                                    </Col>
                                    {isnewpwd ? (
                                      <Col>
                                      <TextInput 
                                          //label={'Nova Senha'}
                                          label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Nova Senha</Text>}
                                          style={{marginBottom: 10}}
                                          mode='flat'
                                          selectionColor={'#004b9e'}
                                          underlineColor={'#004b9e'}
                                          activeUnderlineColor={'#004b9e'}
                                          outlineColor={'#004b9e'}
                                          activeOutlineColor={'#004b9e'}
                                          textColor={'#004b9e'}
                                          dense={true}
                                          value={novasenha}
                                          secureTextEntry={true}
                                          onChangeText={(text) => {setNovaSenha(text)}} 
                                          left={<TextInput.Icon icon="form-textbox-password" disabled={true}/>}                                        
                                      />
                                      </Col>
                                    ):null}
                                      
                                  </Form.Group> 
                              </Row> 
                              <Row>
                              <View style={{position: 'relative', alignItems:'center', justifyContent:'space-between', flexDirection:'row', width:'100vw', marginBottom:10,}}>
                                  <Form.Group as={Col}>
                                      {/* BOTÃO ENTRAR */}
                                      <TouchableOpacity 
                                          style={{position: 'relative', width: 150, height: 35, backgroundColor:'#004b9e', borderRadius: 10, alignItems:'center', justifyContent:'center'}}
                                          onPress={()=> {
                                              if(login=="" || senha=="" ){
                                                  alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                                      '## LOGIN - PORTAL GESTOR ##', // This is a title
                                                      `Informações Pendente!`,
                                                      {
                                                          textConfirm: 'OK',      // Label of button confirm
                                                          textCancel: '',     // Label of button cancel
                                                          onCancel: () => { { } }, // Call your cancel function 
                                                          onConfirm: () => { { } } // Call your confirm function 
                                                      }
                                                  )
                                              }else{
                                                  if(!isnewpwd){
                                                      fetchDataLogin(login, senha);
                                                  }else{
                                                      AtualizaSenha();
                                                  }
                                              }
                                          }}
                                      >
                                          <Text style={{fontWeight: 'bold', fontSize: 13, /* top: 10, */ color:'#FFF'}}>{!isnewpwd ? 'ENTRAR' : 'SALVAR'}</Text>
                                      </TouchableOpacity>

                                  </Form.Group>
                              </View>
                              </Row>
                          </Form>
                      </Container>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#FFF'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
        <StatusBar style="auto" />
        </View>
        </ImageBackground>
    )
}
