import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import ReactDOM from 'react-dom'
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, ActivityIndicator } from "react-native";

import { Card, Checkbox, TextInput } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';

import {Buffer} from 'buffer';
window.Buffer = window.Buffer || Buffer;

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';


const {width, height} = Dimensions.get('screen');

//var mount = document.querySelectorAll('div.browser-mount');
export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso } = useContext(UtilContext);
    const [loading, setLoading]=useState(false);
    const [dados, setDados]=useState([]);
    const [checkedInst, setCheckedInst]=useState(false);
    const [checkedPMat, setCheckedPMat]=useState(false);
    const [checkedMat, setCheckedMat]=useState(false);
    const [checkedCan, setCheckedCan]=useState(false);
    const [checkedOu, setCheckedOu]=useState(false);
    const [mesano, setMesAno]=useState('');
    const [dias, setDias]=useState(60);
    const [ranome, setRANOME]=useState('');
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const [timeoutloading2, setTimeOutLoading2]=useState(100);
    const [timeoutloading3, setTimeOutLoading3]=useState(0);
    const tempotimeout = 77900;
    const [tempolimite, setTempoLimite] = useState('');

    function unescape (str) {
        return (str + '==='.slice((str.length + 3) % 4))
          .replace(/-/g, '+')
          .replace(/_/g, '/')
      }
      
      function escape (str) {
        return str.replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=/g, '')
      }
      
      function Base64_Encode (str, encoding) {
        return escape(Buffer.from(str, encoding || 'utf8').toString('base64'))
      }
      
      function Base64_Decode (str, encoding) {
        return Buffer.from(unescape(str), 'base64').toString(encoding || 'utf8')
      }

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            document.title = dotenv.TITLE;
            //fetchData();
            setDados([]);
            setCheckedInst(false);
            setCheckedPMat(false);
            setCheckedMat(false);
            setCheckedCan(false);
            setCheckedOu(false);
            setMesAno('');

        }
    }, [focus]);

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading){
            setTimeOutLoading(0);
            setTimeOutLoading2(100);
            setTimeOutLoading3(0);
            setTempoLimite('');
        }
    
        if(loading)
        setTimeout(() => {
            const tempoaut = parseInt(timeoutloading)+1;         
            let tempoaut2 = parseInt(timeoutloading3)+1000;         
            let ms = parseInt(tempotimeout) - parseInt(tempoaut2);
            let sec = Math.floor((ms / 1000) % 60);
            let min = Math.floor((ms / 1000 / 60) % 60);         
            let temp = (min < 10 ? '0' : '') + min + ":" + (sec < 10 ? '0' : '') + sec;                  
            setTimeOutLoading(   tempoaut    )
            setTimeOutLoading2(    /* Math.round( */convertRange( tempoaut, [ 0, tempotimeout/1000 ], [ 99, 0 ] )/* ) */        )
            setTimeOutLoading3(    tempoaut2     )
            setTempoLimite(temp);         
       }, 1000);
    }, [loading, timeoutloading]);

    function timeoutPromise(ms, promise) {
        return new Promise((resolve, reject) => {
          const timeoutId = setTimeout(() => {
            reject(new Error("Servidor não respondeu em tempo hábil"))
          }, ms);
          promise.then(
            (res) => {
              clearTimeout(timeoutId);
              resolve(res);
            },
            (err) => {
              clearTimeout(timeoutId);
              reject(err);
            }
          );
        })
      }

      function convertRange( value, r1, r2 ) { 
        return (( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ]).toFixed(1);
      }

    async function fetchData(dados){
        
        if(!dados)
            return false;
        //alert(JSON.stringify(dados));
        //return false;
        setLoading(true);
        setDados([]);
        await timeoutPromise(tempotimeout,fetch(`${dotenv.RESTGESTOR}/inadimplente`, {
                method: 'POST',
                body: JSON.stringify({
                    ano:dados.ano,
                    mes:dados.mes,
                    predio:dados.predio,
                    dias: dados.dias,
                    ra: dados.ra,
                    nome: dados.nome
                }),
                //timeout: 5000,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-access-token": user.token,
                },
            }))
            .then((response) => response.json())
            .then((responseJson) => {     
                
                if (responseJson.validtoken==false){
                    //alert(responseJson.message); 
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## TOKEN - EXPIRADO ##', // This is a title
                        `${responseJson.message}`,
                        {
                            textConfirm: 'OK',       // Label of button confirm
                            textCancel: '',          // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { { 
                                signOut();
                            } } // Call your confirm function 
                        }
                    )
                    return
                }
                    
                    if(responseJson.sucesso == true){
                        //console.log(responseJson)
                        if(responseJson.dados.length>0){
                            setDados(responseJson.dados);
                        }else{
                            Aviso("", 'Não foi encontrado informações para está pesquisa.', "danger", 3000);
                        }
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);                         
                    }
                    
            })
            .catch((error) => {
                Aviso("Error:", error.message, "danger", 3000);                
            })
            .finally(function () { setLoading(false);}); 
    }

    function validarfiltroData(v){
        v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        setMesAno(v);
      }

    function filtroinadimplente(){
        //const filtropesq = [];
        //alert(Base64_Encode('teste 1234;123456') );
        //return false;
        
        if(mesano=='' && dias=='' && ranome==''){
            alert('Informe mês/ano, dias de atraso, ra ou parte nome');
            return false;
        }
        
        let data = mesano!= '' ? mesano?.split('/') : '';
        let search = {
            mes: data!= '' ? data[0] : '',
            ano: data!= '' ? data[1] : '',
            predio: user?.codpredio=='000' ? '' : user?.codpredio,
            dias: dias!='' ? dias : '0',
            ra:  (!isNaN(ranome) && ranome!='') ? ranome : '', 
            nome:(isNaN(ranome) && ranome!='' ) ? ranome : ''
        }

        //alert(JSON.stringify(search));
        fetchData(search);

    }

    const imprimirlista = async () => {  
        
        if(mesano==''){
            alert('Informe mês e ano');
            return false;
           }
           
           
           let filtropesq='';
           if(checkedInst!='')
               filtropesq= `'INSCRITO'`;
           if(checkedPMat!='')
               filtropesq= filtropesq=='' ? `'PRÉ-MATRICULADO'` : `${filtropesq},'PRÉ-MATRICULADO'`;
           if(checkedMat!='')
               filtropesq= filtropesq=='' ? `'MATRICULADO'` : `${filtropesq},'MATRICULADO'`;
           if(checkedCan!='')
               filtropesq= filtropesq=='' ? `'CANCELADO'` : `${filtropesq},'CANCELADO'`;
           if(checkedOu!='')
               filtropesq= filtropesq='OUTROS';
   
           let data = mesano.split('/');
   
           if(filtropesq==''){
               alert('Selecione um dos filtros');
               return false;
           }
        
        Linking.openURL(`${dotenv.SERVER_PHP}/relatoriopdf/procseletivogestor.php?token=${user?.token}&mes=${data[0]}&ano=${data[1]}&predio=${user.codpredio=='000' ? '' : user.codpredio}&filtro=${filtropesq}`);   
            
        
    }

    const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);
    
    const diasvenc = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">DIAS DE ATRASO</Popover.Header>
          <Popover.Body>
            Informe quantidade de <strong>DIAS</strong> para o sistema procurar registros
            com mais de XX Dias de vencido.
          </Popover.Body>
        </Popover>
      );

    const mesano_ = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">MÊS/ANO</Popover.Header>
          <Popover.Body>
            Informe <strong>MÊS/ANO</strong> para o sistema procurar registros
            com base na data do vencimento.
          </Popover.Body>
        </Popover>
      );

    const ranome_ = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">RA/NOME</Popover.Header>
          <Popover.Body>
            Informe <strong>RA OU PARTE DO NOME</strong> para o sistema procurar registros
            com base no nome do aluno.
          </Popover.Body>
        </Popover>
      );


    
    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5, right: 10 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : -20 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{width: Device.osName=='Windows' ? '100%' : 350, top: Device.osName=='Windows' ? null : 10,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                    :: ALUNOS - INADIMPLENTES ::
                                </Text>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', top: 5}}>
                    <Card style={styles.card}>
                        <View style={{flex:1, position:'relative',width:'100%', height:'100%', top: Device.osName!='Windows'? -30 : null}}>
                        <Container fluid className="Rules-Container">
                            <View style={{position:'relative',/* overflow:'scroll', */ height: 450, minWidth: '70vw', width: Device.osName=='Windows'? '83vw':'83vw'}}>
                                <View style={{position:'relative', flexDirection: 'row', left: Device.osName=='Windows'? null : -50, marginTop: Device.osName=='Windows'? null : -50 , flexWrap:'wrap', width: Device.osName=='Windows'? null: 500 }}>
                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="auto" overlay={mesano_}>
                                            <Button variant="">
                                                <TextInput 
                                                        //label={'Mês/Ano'}
                                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Mês/Ano</Text>}
                                                        autoCorrect={false}
                                                        autoComplete={false}
                                                        //placeholder={'Ex.: João Silva'}
                                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                                        style={{margin: 1, marginLeft: 20, width: Device.osName=='Windows'? null : 150}}
                                                        mode='outlined'
                                                        selectionColor={'#004b9e'}
                                                        underlineColor={'#004b9e'}
                                                        activeUnderlineColor={'#004b9e'}
                                                        outlineColor={'#004b9e'}
                                                        activeOutlineColor={'#004b9e'}
                                                        textColor={'#004b9e'}
                                                        dense={true}
                                                        value={mesano}
                                                        onChangeText={(text) => {validarfiltroData(text)}}       
                                                        onSubmitEditing={()=>filtroinadimplente()}                 
                                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroinadimplente()}} />} 
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                        
                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="auto" overlay={diasvenc}>
                                            <Button variant="">
                                                    <TextInput 
                                                        //label={'Dias de Atraso'}
                                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Dias de Atraso</Text>}
                                                        autoCorrect={false}
                                                        autoComplete={false}
                                                        //placeholder={'Ex.: João Silva'}
                                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                                        style={{margin: 1, marginLeft: 20, width: Device.osName=='Windows'? null : 150}}
                                                        mode='outlined'
                                                        selectionColor={'#004b9e'}
                                                        underlineColor={'#004b9e'}
                                                        activeUnderlineColor={'#004b9e'}
                                                        outlineColor={'#004b9e'}
                                                        activeOutlineColor={'#004b9e'}
                                                        textColor={'#004b9e'}
                                                        dense={true}
                                                        value={dias}
                                                        onChangeText={(text) => {setDias(text)}}       
                                                        onSubmitEditing={()=>filtroinadimplente()}                 
                                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroinadimplente()}} />} 
                                                    />
                                            </Button>
                                        </OverlayTrigger>
                                                                      
                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="auto" overlay={ranome_}>
                                            <Button variant="">
                                                    <TextInput 
                                                        //label={'Ra / Parte Nome'}
                                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Ra / Parte Nome</Text>}
                                                        autoCorrect={false}
                                                        autoComplete={false}
                                                        //placeholder={'Ex.: João Silva'}
                                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                                        style={{margin: 1, marginLeft: 20, width: Device.osName=='Windows'? null : 150}}
                                                        mode='outlined'
                                                        selectionColor={'#004b9e'}
                                                        underlineColor={'#004b9e'}
                                                        activeUnderlineColor={'#004b9e'}
                                                        outlineColor={'#004b9e'}
                                                        activeOutlineColor={'#004b9e'}
                                                        textColor={'#004b9e'}
                                                        dense={true}
                                                        value={ranome}
                                                        onChangeText={(text) => {setRANOME(text)}}       
                                                        onSubmitEditing={()=>filtroinadimplente()}                 
                                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroinadimplente()}} />} 
                                                    />
                                            </Button>
                                        </OverlayTrigger>                                        
                                    
                                    {/* <TouchableOpacity
                                        style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 50, alignItems: 'center', justifyContent: 'center', top: 10, marginLeft: 100 }}
                                        onPress={() => {
                                            imprimirlista();
                                        }}
                                        disabled={false}
                                    >               
                                        <View style={{flexDirection: 'row',}}>
                                            <Icon name="print" size={20} color="#FFD700" style={{padding:  3,}}/>    
                                        </View>
                                    </TouchableOpacity>  */}                                   
                                </View>
                            {loading ? (
                                <>
                                <ActivityIndicator size={50} color='#004b9e' />
                                {timeoutloading2 <= 33 ? (<ProgressBar variant="danger" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                {timeoutloading2 > 33 && timeoutloading2 <= 66  ? (<ProgressBar variant="warning" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                {timeoutloading2 > 66 ? (<ProgressBar variant="success" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    
                                </>
                            ):null}
                                    <Table striped bordered hover /* size="sm" fixed="top" */ responsive>
                                        <thead style={{position: 'sticky',top: '0px',backgroundColor:'#FFF', zIndex: 100,}}>
                                            <tr>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>BOLETO</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>PRÉDIO</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>RA</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>ALUNO</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>PARCELA</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>VALOR</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>VENCIMENTO</Text></th>
                                                <th colSpan={2}><Text style={{fontSize: 13, fontWeight:'bold'}}>HISTORICO</Text></th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody style={{position:'relative', zIndex: 10}}>  
                                            {   
                                                dados?.map(obj => (
                                                <tr key={obj.ITEM.toString()}>
                                                    <td>                                                        
                                                        <TouchableOpacity style={{paddingBottom: 5}} onPress={()=>{
                                                               if(obj.STATUSBOLETO==0){
                                                                    let valuehistorico = (obj.HISTORICO.length >200) ? obj.HISTORICO.slice(0,200)+", ..." : obj.HISTORICO;
                                                                    let dadosboleto =   obj.VENCIMENTO+";"+obj.DATAEMISSAO+";"+obj.DATACRIACAO+";"+
                                                                                        obj.VALORBOLETO+";"+obj.NOSSONUMERO+";"+obj.ALUNO+";"+
                                                                                        obj.IDBOLETO+";"+obj.CODIGOBARRA+";"+obj.CODIGOBARRADIG+";"+                                 
                                                                                        obj.LOGRADOURO+";"+obj.CIDADE+";"+obj.UF+";"+
                                                                                        obj.CEP+";"+obj.BAIRRO+";"+obj.CPF+";"+valuehistorico;
                                                                                    
                                                                
                                                                    //alert(`'${(responseJson.dados[0].CODIGOBARRADIG).slice(0,3)}'`);
                                                                    //alert(Base64_Encode(dadosboleto));
                                                                    //alert(dadosboleto);
                                                                    //return false;
                                                                    
                                                                    const banco = (obj.CODIGOBARRADIG.toString()).slice(0,3)=="237" ? "bradesco" : (obj.CODIGOBARRADIG.toString()).slice(0,3)=="033" ? "santander" : ""
                                                                    const nomebanco = banco?? null;
                                                
                                                                    if(nomebanco==null || nomebanco ==""){
                                                                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                                                        '## Boleto ##', // This is a title
                                                                        `Banco Desconhecido no criação do Boleto Banco Cód: ${(obj.CODIGOBARRADIG.toString()).slice(0,3)}, informe o suporte!`, // This is a alert message
                                                                        {
                                                                        textConfirm: 'OK',                // Label of button confirm
                                                                        //textCancel: '',                 // Label of button cancel
                                                                        onCancel: () => {{}},             // Call your cancel function 
                                                                        onConfirm: () => {{}} // Call your confirm function 
                                                                        }
                                                                    )
                                                                    return false;
                                                                    }
                                                
                                                                    Linking.openURL(`${dotenv.SERVER_PHP}/boletos5/${nomebanco}.php?token=${Base64_Encode(dadosboleto) }`);
                                                                    //Linking.openURL(`${dotenv.SERVER_PHP}/boletos5/testebanco.php?token=${Base64_Encode(dadosboleto) }`);
                                                               }else{
                                                                alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                                                        '## Boleto ##', // This is a title
                                                                        `Boleto encontra-se pago!`, // This is a alert message
                                                                        {
                                                                        textConfirm: 'OK',      // Label of button confirm
                                                                        //textCancel: '',     // Label of button cancel
                                                                        onCancel: () => {{}}, // Call your cancel function 
                                                                        onConfirm: () => {{}} // Call your confirm function 
                                                                        }
                                                                    )
                                                               }            
                                                        }}>
                                                            <Icon name={obj.STATUSBOLETO==1 ? "check-circle":"file-invoice-dollar"} size={20} color={obj.STATUSBOLETO==1 ? "green" : "red"} style={{padding:  3,}}/>
                                                        </TouchableOpacity>
                                                    </td>
                                                    <td>{obj.SEDE}</td>
                                                    <td>{obj.RA}</td>
                                                    <td>{obj.ALUNO}</td>
                                                    <td>{obj.PARCELA}</td>
                                                    <td>{numberFormat(obj.VALORBOLETO)}</td>
                                                    <td>{obj.VENCIMENTO}</td>
                                                    <td colSpan={2}>{obj.HISTORICO}</td>                  
                                                </tr>))
                                                
                                            }               
                                        </tbody>                                    
                                    </Table>
                            </View>
                                
                        </Container>
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
        <StatusBar style="auto" />
        </View>
    )
}
