'use strict';
import { StyleSheet, Dimensions } from 'react-native';
import * as Device from 'expo-device';//Device.osName

const { width, height } = Dimensions.get('window');
const windowWidth = Dimensions.get('window').width;
const windowHeigth = Dimensions.get('window').height;

const styles = StyleSheet.create({
container: {
    //flex: 1,
    width: windowWidth,
    height: windowHeigth,
    flexDirection: 'row',
    backgroundColor: '#ddd',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: "wrap",
    //padding: 1 
},
layoutesq: {
    flex:1.5,
    position:'relative',
    //backgroundColor:'#000',
    minWidth: 150, 
    height: windowHeigth, 
    minHeight: 200, 
    alignItems: 'center', 
    justifyContent:'center' 
},
layoutdirsup: {
    position: 'relative',
    flex:10,
    flexDirection:'column',
    height: windowHeigth
},

card:{
    width: '98%', 
    height: '98%',
    //right: Device.osName=='Windows' ? null : 20, 
    alignItems:'center', 
    justifyContent:'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 10,
    elevation: 5,
},
logomenu: {
    resizeMode: 'stretch',
    width: Device.osName=='Windows'? 160 : 60,
    height: Device.osName=='Windows'? 55 : 60,
    overflow: 'hidden',
    borderRadius: 5
  },
logouser: {
    resizeMode: 'stretch',
    width: Device.osName=='Windows'? 45 : 45,
    height: Device.osName=='Windows'? 45 : 45,
    overflow: 'hidden',
    borderRadius: 50
  },
logohomecel: {
    resizeMode: 'stretch',
    width: 40,
    height: 40,
    overflow: 'hidden',
    borderRadius: 5
  },
imagebg: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#836FFF',
    overflow: 'hidden',
    resizeMode: 'stretch',
    //resizeMode: 'cover',
    //width: '100%',
    //height: '100%',

  },
viewmodal: {
    backgroundColor: '#ddd',
    flex: 1,
    width: width,
    height: height,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    zIndex: -100
  },
      
});

export default styles