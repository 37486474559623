import React, {} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
//import { Dimensions } from 'react-native';
import AuthProvider from './src/contexts/auth';
import UtilProvider from './src/contexts/utilidade';
import SuperAlert from 'react-native-super-alert';
import FlashMessage from 'react-native-flash-message';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AuthLogin from './src/routes';
//import Login from './screen/login';
import Home from './src/screen/home';
import PolitInst from './src/screen/politicainst';
import VideoInst from './src/screen/videoinst';
import Cadlogin from './src/screen/cadlogin';
import PecasMark from './src/screen/pecasmark';
import CadDashboard from './src/screen/caddashboard';
import Faq from './src/screen/faq';
import ProcSelInscrito from './src/screen/processoseletivo';
import DadosAluno from './src/screen/dadosaluno';
import AlunoNota from './src/screen/alunonota';
import Diretorios from './src/screen/implantacao';
import FinPolo from './src/screen/finanpolo';
import LogFatura from './src/screen/logfatura';
import Inadimplente from './src/screen/inadimplente';


const dotenv = require('./app.config');
const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
        <AuthProvider>
            <UtilProvider>
                <Stack.Navigator
                            initialRouteName="AuthLogin"
                            screenOptions={screenOptions}>
                            <Stack.Screen
                                name='AuthLogin'
                                component={AuthLogin}
                                options={({ navigation }) => {
                                    return {
                                        headerTintColor: '#FFF',
                                        headerShown: false,
                                        title:  dotenv.TITLE,
                                    }
                                }}
                            />
                            <Stack.Screen
                                name='Home'
                                component={Home}
                                options={({ navigation }) => {
                                    return{
                                        headerTintColor: '#FFF',
                                        headerShown: false,
                                        title:  dotenv.TITLE,
                                    }
                                }}
                            />
                            <Stack.Screen
                                name='PolitInst'
                                component={PolitInst}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />
                            <Stack.Screen
                                name='VideoInst'
                                component={VideoInst}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='Cadlogin'
                                component={Cadlogin}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='PecasMark'
                                component={PecasMark}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='CadDashboard'
                                component={CadDashboard}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='Faq'
                                component={Faq}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='ProcSelInscrito'
                                component={ProcSelInscrito}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='DadosAluno'
                                component={DadosAluno}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='AlunoNota'
                                component={AlunoNota}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='Diretorios'
                                component={Diretorios}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='FinPolo'
                                component={FinPolo}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='LogFatura'
                                component={LogFatura}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                            <Stack.Screen
                                name='Inadimplente'
                                component={Inadimplente}
                                options={{
                                    headerTintColor: '#FFF',
                                    headerShown: false,
                                    title:  dotenv.TITLE,
                                }}
                            />                        
                        </Stack.Navigator>
            </UtilProvider>
        </AuthProvider>
    <FlashMessage position="top" /> {/* <--- here as last component */}
    <SuperAlert customStyle={customStyle} />
    </NavigationContainer>
  
  );
}

const screenOptions = {
    headerStyle: {
        backgroundColor: '#f4511e'
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
        fontWeight: 'bold'
    },
  
  }
  

const customStyle = {
    container: {
        backgroundColor: '#e8e8e8',
        borderRadius: 10,
        //position: 'absolute', 
        zIndex: 2000
        //paddingTop: 0,
    },
    buttonCancel: {
        backgroundColor: '#b51919',
        borderRadius: 5,
        width: 90,
        height: 35,
        left: -30,
    },
    buttonConfirm: {
        backgroundColor: '#4490c7',
        borderRadius: 5,
        width: 90,
        height: 35,
        left: 30,
    },
    textButtonCancel: {
        color: '#fff',
        fontWeight: 'bold'
    },
    textButtonConfirm: {
        color: '#fff',
        fontWeight: 'bold'
    },
    title: {
        color: '#003d69',
        fontSize: 15
    },
    message: {
        color: '#4f4f4f',
        fontSize: 12,
        fontWeight: 'bold',
        paddingBottom: 25,
    }
  }


