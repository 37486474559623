import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState} from "react";
import { Text, View, Dimensions, TouchableOpacity, Linking,PixelRatio, Image } from "react-native";
import { Card, TextInput, Switch  } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import IconTab from 'react-native-vector-icons/Ionicons';
import { UtilContext } from '../../contexts/utilidade';
import { useIsFocused  } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as Device from 'expo-device';//Device.osName

const Tab = createBottomTabNavigator();
import CadastroLogin from './cadastro';
import ConsultaLogin from './consulta';

const {width, height} = Dimensions.get('screen');

export default function App({navigation}) {
    const { ismenu, setIsMenu } = useContext(UtilContext);
    const [nomeuser, setNomeUser] = useState('');
    const [loginuser, setLoginUser] = useState('');
    const [celularuser, setCelularUser] = useState('');
    const [emailuser, setEmailUser] = useState('');
    const [isadmin, setIsAdmin] = useState(false);
    const [isgestor, setIsGestor] = useState(false);
    const [isadministrativo, setIsAdministrativo] = useState(false);

    function ConsultaLoginFunc() {
        return(
            <ConsultaLogin />
        )
    }
    
    function CadastroLoginFunc() {               
      return(
              <CadastroLogin />
      )
    }


    useEffect(() => { 
        document.title = dotenv.TITLE;
    }, []);
    const focus = useIsFocused();  // useIsFocused as shown 
   useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
       if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
                    /* alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## CADATRO NOVO LOGIN ##', // This is a title
                        `Nome Usuário: ex.: João Silva\nLogin: ex.:joao.silva\nCelular: ex.: número válido preferencialmente com whatsapp\nEmail: email válido (ativação será por email)!\nPerfil: (Admin(somente TI), Gestor ou Administrativo. Escolha somente 1 (um))`,
                        {
                            textConfirm: 'ENTENDIDO!',      // Label of button confirm
                            textCancel: '',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { { } } // Call your confirm function 
                        }
                    ) */
       }
   }, [focus]);

    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5, right: 10 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : 10 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            {/* <View style={{width: Device.osName=='Windows' ? 200 : 60, padding:Device.osName=='Windows'?30: 15, height: Device.osName=='Windows' ? 60 : 50,borderColor:'#D3D3D3',borderWidth:2,backgroundColor: '#FFF', borderRadius: 10, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'10px':'8px',fontWeight:'bold', color:'#000080'}}>ALUNOS ATIVOS</Text>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#000080'}}>100</Text>
                            </View> */}
                            <View style={{width: '100%', top: Device.osName=='Windows' ? null : -20,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'10px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                    :: CADASTRO/CONSULTA - LOGIN ::
                                </Text>
                            </View>
                            {/* <View style={{width: Device.osName=='Windows' ? 200 : 60, padding:Device.osName=='Windows'?30: 15, height: Device.osName=='Windows' ? 60 : 50, borderColor:'#D3D3D3',borderWidth:2,backgroundColor: '#FFF', borderRadius: 10, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'10px':'8px',fontWeight:'bold', color:'#000080'}}>NOVAS MATRÍCULAS</Text>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#000080'}}>50</Text>
                            </View>  */}
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'100%',alignItems: 'center', justifyContent:'center', top: 5}}>
                    <Card style={styles.card}>
                        <View style={{position:'relative', width: width*0.83, height:Device.osName=='Windows'?height*0.65: height ,/*  alignItems:'flex-start', justifyContent:'flex-start', */}}>
                            <Tab.Navigator
                                initialRouteName='Login Cadastro'
                                //barStyle={{ marginLeft:10, marginRight:10 }}
                                //barStyle={{ zIndex: 15 }}
                                screenOptions={({ route }) => ({
                                        tabBarIcon: ({ focused, color, size }) => {
                                        let iconName;
                                        let bckclr = '#000';

                                        size = Device.osName=='Windows' ? 25 : 20;
                                        
                                        if (route.name === 'Login Cadastro') {
                                                iconName = focused
                                                ? 'person-add'
                                                : 'person-add-outline';
                                        } else if (route.name === 'Login Consulta') {
                                                iconName = focused 
                                                ? 'people-sharp' 
                                                : 'people-outline';
                                        }
                        
                                        // You can return any component that you like here!
                                        return <IconTab name={iconName} size={size} color={color} backgroundColor={bckclr} />;
                                        },
                                        tabBarActiveTintColor: '#004b9e',//'tomato',
                                        tabBarInactiveTintColor: 'gray',
                                        //tabBarStyle: {alignSelf:'stretch', bottom: 4}            
                                        tabBarStyle:{
                                            paddingVertical: 5,
                                            borderTopLeftRadius:15,
                                            borderTopRightRadius:15,
                                            backgroundColor:'#ddd',
                                            position:'absolute',
                                            bottom: 1,//height*0.005,
                                            width:width * 0.83,
                                            left: 0,
                                            zIndex: 1
                                            //bordertWidth: 2, 
                                            //borderColor: '#000',
                                        },
                                        tabBarLabelStyle:{paddingBottom:3},                    
                                })}
                                
                                >
                                <Tab.Screen 
                                        name="Login Cadastro"
                                        //title="TESTE CAD"
                                        component={CadastroLoginFunc}
                                        options={{
                                                headerTintColor: '#FFF',
                                                headerShown: false,
                                                unmountOnBlur: true,
                                                tabBarLabel: 'CADASTRO',
                                                //tabBarBadge: 3                                        
                                                title: 'PORTAL DO GESTOR'
                                        }}
                                />
                                <Tab.Screen
                                        name="Login Consulta" 
                                        component={ConsultaLoginFunc}
                                        options={{
                                                headerTintColor: '#FFF',
                                                headerShown: false,
                                                unmountOnBlur: true,
                                                tabBarLabel: 'CONSULTA',
                                                title: 'PORTAL DO GESTOR'
                                                //tabBarBadge: 2
                                        }}                                
                                />              
                            </Tab.Navigator>                           
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
        <StatusBar style="auto" />
        </View>
    )
}
