
import React, {useContext, useEffect, useState,} from 'react';
import { Image, Text, TouchableOpacity, View, Dimensions, Linking,  Modal, ScrollView} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/Octicons';
import IconFeather from 'react-native-vector-icons/Feather';
import { Card, RadioButton, Checkbox } from "react-native-paper";
import styles from '../../css/globalstyle';
import * as Animatable from 'react-native-animatable';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const { width, height } = Dimensions.get('window');


export default function App({navigation}) {
    const { signUp, signOut, user, alterperfil, setAlterPerfil } = useContext(AuthContext);
    const { ismenu, setIsMenu, iscoligada3, setIsColigada3, iscoligada5, setIsColigada5 } = useContext(UtilContext);
    const [mnuinstitucional, setMnuInstitucional]=useState(false);
    const [mnumarketing, setMnuMarketing]=useState(false);
    const [mnuproceletivo, setMnuProcSelecitvo]=useState(false);
    const [mnuacademico, setMnuAcademico]=useState(false);
    const [mnufinanceiro, setMnuFinanceiro]=useState(false);
    const [mnulink, setMnuLink]=useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [checked, setChecked]=useState([]);
    
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
           
    }, []);

    function fecharmenu(){
        //Institucional
        setMnuInstitucional(false);
        setMnuMarketing(false);
        setMnuProcSelecitvo(false);
        setMnuAcademico(false);
        setMnuFinanceiro(false);
        setMnuLink(false);
    }

    return(
        
        <View style={{ paddingTop: 6, width: '100%', height: '100%', borderTopRightRadius: 10, borderBottomRightRadius: 10, overflow:'auto' }}>
                  {Device.osName=='Windows' ? (
                  <View style={{ position: 'relative', width: Device.osName=='Windows'?'90%':'70%', backgroundColor: '#FFF' , marginLeft: Device.osName=='Windows' ? 10: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
                    <Image style={styles.logomenu} source={require('../../imagem/literatus3.jpg')} />
                  </View>
                  ) : null}
                  <View style={{ position: 'relative', width: Device.osName=='Windows'?'90%':'70%', backgroundColor: '#DCDCDC', marginLeft: Device.osName=='Windows' ? 10: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
                    <Image style={styles.logouser} source={require('../../imagem/user.png')} />
                    <Text style={{fontSize: 12, fontWeight:'bold'}}>{`${user?.nome?? ""}`}</Text>
                  </View>
                  {/* SELECIONAR A COLIGADA */} 
                  <View /* style={{width:Device.osName=='Windows'? '50%':'70%', margin: 5 }} */>
                        
                        <View style={{width:Device.osName=='Windows'? '30%':'70%',flexDirection: 'row', alignItems: 'center'}}>
                            <Text style={{fontWeight:'bold', padding: 5}}>Coligadas:</Text>
                            <Checkbox.Item 
                                style={{fontWeight:'bold', width: 10, height: 30, left: -18, position: 'relative'}}
                                label="3" 
                                //status="checked"
                                status={iscoligada3 ? 'checked' : 'unchecked'}
                                onPress={()=> {
                                    setIsColigada3(!iscoligada3);
                                }} 
                            />
                            <Checkbox.Item 
                                label="5" 
                                style={{fontWeight:'bold', width: 20, height: 30, left: 5}}
                                //status="checked"
                                status={iscoligada5 ? 'checked' : 'unchecked'}
                                onPress={()=> {
                                    setIsColigada5(!iscoligada5);
                                }} 
                            />
                        </View>
                  </View> 
                  <View style={{width:Device.osName=='Windows'?null:'70%',marginTop: 5, marginLeft: 5, marginRight: 5,borderRadius: 5,  backgroundColor: '#004b9e',  height: 35, alignItems: 'center', justifyContent: 'center'}}>
                    
                    <View style={{width: '100%',flexDirection: 'column',}}>
                     {!user.alterperfil ? ( <>
                          <Text style={{fontSize:  Device.osName=='Windows'? 10 : 9, color: '#FFF', paddingLeft: 5, paddingTop: !user.alterperfil ? 0 : 12}} >
                              PRÉDIO
                          </Text>
                    
                          <Text style={{fontSize:  Device.osName=='Windows'? 11 : 9, alignSelf:'center',color: '#FFF', fontWeight: 'bold', paddingLeft: 5, paddingTop: 1}} >
                              {user?.predio}
                          </Text> </>    
                     ):(
                                                   
                      <View style={{width:Device.osName=='Windows'?null:'70%',marginTop: 5, marginLeft: 5, marginRight: 5}}>
                      <TouchableOpacity
                          style={{ borderRadius: 5,  /*backgroundColor:  stsmenu=='INICIO'?'#A9A9A9': '#004b9e',*/  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                          onPress={() => {
                            setModalVisible(!modalVisible);
                          }}
                          disabled={false}
                      >               
                          <View style={{width: '100%',flexDirection: 'row',}}>
                            <Icon name="sitemap" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: Device.osName=='Windows'? 8: 3,paddingLeft: 0}}/>
                            <Text style={{ padding: Device.osName=='Windows'? null: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                                PRÉDIO {user?.predio}
                            </Text>
                          </View>
                      </TouchableOpacity>
                    </View>
                          
                    )}                          
                    </View>
                    
                  </View>
                  
                  
                  <View style={{width:Device.osName=='Windows'?null:'70%',marginTop: 5, marginLeft: 5, marginRight: 5}}>
                    <TouchableOpacity
                        style={{ borderRadius: 5,  backgroundColor: /* stsmenu=='INICIO'?'#A9A9A9': */'#004b9e',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                        onPress={() => {
                          //FECHAAR OS MENUS
                          fecharmenu();
                          navigation.navigate('Home');
                          if(Device.osName!='Windows'){
                            setIsMenu(!ismenu);
                        }
                        }}
                        disabled={false}
                    >               
                        <View style={{width: '100%',flexDirection: 'row',}}>
                          <Icon name="home" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5}}/>
                          <Text style={{ width:'100%', textAlign:'left', alignSelf:'center', padding: Device.osName=='Windows'? null: 3 , fontSize: Device.osName=='Windows'? 12 : 9, color: '#FFF', fontWeight: 'bold' }} >
                              INÍCIO
                          </Text>
                        </View>
                    </TouchableOpacity>
                  </View>
                  
                  {user?.superadmin == 1 || user?.admin == 1 ? (
                  <View style={{width:Device.osName=='Windows'?null:'70%',marginTop: 5, marginLeft: 5, marginRight: 5}}>
                    <TouchableOpacity
                        style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                        onPress={() => {
                          //FECHAAR OS MENUS
                          fecharmenu();
                          navigation.navigate('Cadlogin');
                          if(Device.osName!='Windows'){
                              setIsMenu(!ismenu);
                          }
                        }}
                        disabled={false}
                    >               
                        <View style={{width: '100%',flexDirection: 'row',}}>
                          <Icon name="users" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5}}/>
                          <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} >
                              LOGINS
                          </Text>
                        </View>
                    </TouchableOpacity>
                  </View>
                  ) : null}                  
                  
                  
                  {user?.superadmin == 1 ? (
                  <View style={{width:Device.osName=='Windows'?null:'70%',marginTop: 5, marginLeft: 5, marginRight: 5}}>
                    <TouchableOpacity
                        style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                        onPress={() => {
                          //FECHAAR OS MENUS
                          fecharmenu();
                          navigation.navigate('CadDashboard');
                          if(Device.osName!='Windows'){
                              setIsMenu(!ismenu);
                          }
                        }}
                        disabled={false}
                    >               
                        <View style={{width: '100%',flexDirection: 'row',}}>
                          <Icon name="globe" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: Device.osName=='Windows'? 15: 3,paddingLeft: 5}}/>
                          <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} >
                              CAD. DASHBOARD
                          </Text>
                        </View>
                    </TouchableOpacity>
                  </View>
                  ) : null}                  
                  {/* {user?.superadmin == 1 || user?.admin == 1 || user?.gestor == 1   ? ( */}
                  <View style={{width:Device.osName=='Windows'?null:'70%',marginTop: 5, marginLeft: 5, marginRight: 5}}>
                    <TouchableOpacity
                        style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                        onPress={() => {
                          //FECHAAR OS MENUS
                          fecharmenu();
                          navigation.navigate('Diretorios');
                          if(Device.osName!='Windows'){
                              setIsMenu(!ismenu);
                          }
                        }}
                        disabled={false}
                    >               
                        <View style={{width: '100%',flexDirection: 'row',}}>
                          <Icon2 name="file-directory" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: Device.osName=='Windows'? 15: 3,paddingLeft: 5}}/>
                          <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} > 
                              IMPLANTAÇÃO
                          </Text>
                        </View>
                    </TouchableOpacity>
                  </View> 
                  {/* ):null} */}                
                  
                  <View style={{width:Device.osName=='Windows'?null:'77%',flexDirection: 'column'}}>
                        <View style={{ marginTop: 5, marginLeft: 5, marginRight: 5}}>
                          <TouchableOpacity
                            style={{ borderRadius: 5, backgroundColor: /* stsmenu=='ALUNOS'?'#A9A9A9': */'#004b9e', height: 35, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() =>  {  
                                //FECHAAR OS MENUS
                                fecharmenu();                              
                                setMnuInstitucional(!mnuinstitucional);    
                                //if(Device.osName!='Windows'){
                                  //setIsMenu(!ismenu);
                                //}                          
                            }}
                          >
                            <View style={{ width: '100%', flexDirection: 'row', }}>
                              <Icon name={!mnuinstitucional ? "building" : "chevron-circle-down"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 8: 3,paddingLeft: 5 }} />
                              <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                                  INSTITUCIONAL                        
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {mnuinstitucional ? (
                          <>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('PolitInst');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 3 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                   POLÍTICAS INSTITUCIONAIS
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{marginBottom: 5, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('VideoInst');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 3 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                   VÍDEO INSTITUCIONAL
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        </>
                        ):null}
                  </View>
                  <View style={{width:Device.osName=='Windows'?null:'77%',flexDirection: 'column'}}>
                        <View style={{ marginTop: 5, marginLeft: 5, marginRight: 5}}>
                          <TouchableOpacity
                            style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() =>  {  
                                //FECHAAR OS MENUS
                                fecharmenu();                              
                                setMnuMarketing(!mnumarketing);         
                                /* if(Device.osName!='Windows'){
                                  setIsMenu(!ismenu);
                                }  */                   
                            }}
                          >
                            <View style={{ width: '100%', flexDirection: 'row', }}>
                              <Icon name={!mnumarketing ? "file-export" : "chevron-circle-down"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 15: 3,paddingLeft: 5 }} />
                              <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} >
                                MARKETING
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {mnumarketing ? (
                          <>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('PecasMark');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 3 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    PEÇAS DE MARKETING
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        </>
                        ):null}
                  </View>
                  <View style={{width:Device.osName=='Windows'?null:'77%',flexDirection: 'column'}}>
                        <View style={{ marginTop: 5, marginLeft: 5, marginRight: 5}}>
                          <TouchableOpacity
                            style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() =>  {  
                                navigation.navigate('ProcSelInscrito');
                                if(Device.osName!='Windows'){
                                  setIsMenu(!ismenu);
                                }
                                //FECHAAR OS MENUS
                                //fecharmenu();                              
                                //setMnuProcSelecitvo(!mnuproceletivo);      
                                //if(Device.osName!='Windows'){
                                //  setIsMenu(!ismenu);
                                //}                        
                            }}
                          >
                            <View style={{ width: '100%', flexDirection: 'row', }}>
                              <Icon name={!mnuproceletivo ? "book-reader" : "chevron-circle-down"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 18: 3,paddingLeft: 5 }} />
                              <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} >
                                  PROCESSO SELETIVO                        
                              </Text>                              
                            </View>
                          </TouchableOpacity>
                        </View>
                        {mnuproceletivo ? (
                          <>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#A9A9A9',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('ProcSelInscrito');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 3 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                   INSCRITOS (LEADS)
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#A9A9A9',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  //navigation.navigate('');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 3 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    RESULTADO
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#A9A9A9',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  //navigation.navigate('Initial');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 1,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                     CANCEL. DE INSCRIÇÕES
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        </>
                        ):null}
                  </View>
                  <View style={{width:Device.osName=='Windows'?null:'77%',flexDirection: 'column'}}>
                        <View style={{ marginTop: 5, marginLeft: 5, marginRight: 5}}>
                          <TouchableOpacity
                            style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() =>  {  
                                //FECHAAR OS MENUS
                                fecharmenu();                              
                                setMnuAcademico(!mnuacademico);    
                                /* if(Device.osName!='Windows'){
                                  setIsMenu(!ismenu);
                                } */                          
                            }}
                          >
                            <View style={{ width: '100%', flexDirection: 'row', }}>
                              <Icon name={!mnuacademico?"user-graduate" : "chevron-circle-down"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 21: 3,paddingLeft: 5 }} />
                              <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} >
                                  ACADÊMICO                        
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {mnuacademico ? (
                          <>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('DadosAluno');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                   DADOS ALUNO
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        {/* <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#A9A9A9',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  //navigation.navigate('Initial');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                   STATUS DE MATRÍCULA
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View> */}
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('AlunoNota');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                   NOTAS DE AVALIAÇÕES
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        {/* <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#A9A9A9',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  //navigation.navigate('Initial');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 1,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                     RESET DE SENHA
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View> */}
                        </>
                        ):null}
                  </View>
                  <View style={{width:Device.osName=='Windows'?null:'77%',flexDirection: 'column'}}>
                        <View style={{ marginTop: 5, marginLeft: 5, marginRight: 5}}>
                          <TouchableOpacity
                            style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() =>  {  
                                //FECHAAR OS MENUS
                                fecharmenu();                              
                                setMnuFinanceiro(!mnufinanceiro);    
                                /* if(Device.osName!='Windows'){
                                  setIsMenu(!ismenu);
                                } */                          
                            }}
                          >
                            <View style={{ width: '100%', flexDirection: 'row', }}>
                              <Icon name={!mnufinanceiro?"hand-holding-usd" : "chevron-circle-down"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 18: 3,paddingLeft: 5 }} />
                              <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} >
                                  FINANCEIRO                        
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {mnufinanceiro ? (
                          <>
                        {user?.superadmin == 1 || user?.admin == 1 || user?.gestor == 1   ? (
                          <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                            <TouchableOpacity
                                style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                                onPress={() => {
                                    navigation.navigate('FinPolo');
                                    if(Device.osName!='Windows'){
                                      setIsMenu(!ismenu);
                                    }
                                }}
                                disabled={false}
                            >               
                                <View style={{width: '100%',flexDirection: 'row',}}>
                                  <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                  <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                      FATURAMENTO TOTAL
                                  </Text>
                                </View>
                            </TouchableOpacity>
                          </View>
                        ) : null}

                        {user?.superadmin == 1  ? (
                          <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                            <TouchableOpacity
                                style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                                onPress={() => {
                                    navigation.navigate('LogFatura');
                                    if(Device.osName!='Windows'){
                                      setIsMenu(!ismenu);
                                    }
                                }}
                                disabled={false}
                            >               
                                <View style={{width: '100%',flexDirection: 'row',}}>
                                  <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                  <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                      LOG FATURAMENTO
                                  </Text>
                                </View>
                            </TouchableOpacity>
                          </View>
                        ) : null}


                        {/* <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#A9A9A9',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  //navigation.navigate('Initial');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    REPASSE
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View> */}
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('Inadimplente');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 1,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    INADIMPLENTES
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        {/* <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#A9A9A9',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  //navigation.navigate('Initial');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 1,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    BOLETOS
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View> */}
                        </>
                        ):null}
                  </View>
                  <View style={{width:Device.osName=='Windows'?null:'77%',flexDirection: 'column'}}>
                        <View style={{ marginTop: 5, marginLeft: 5, marginRight: 5}}>
                          <TouchableOpacity
                            style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() =>  {  
                                //FECHAAR OS MENUS
                                fecharmenu();                              
                                setMnuLink(!mnulink); 
                                /* if(Device.osName!='Windows'){
                                  setIsMenu(!ismenu);
                                } */                             
                            }}
                          >
                            <View style={{ width: '100%', flexDirection: 'row', }}>
                              <Icon name={!mnulink?"external-link-alt" : "chevron-circle-down"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 22: 3,paddingLeft: 5 }} />
                              <Text style={{ width:'100%', textAlign:'left', alignSelf:'center',padding: Device.osName=='Windows'? null: null , fontSize: Device.osName=='Windows'? 12 : 9,  color: '#FFF', fontWeight: 'bold' }} >
                                  LINK ÚTEIS                        
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {mnulink ? (
                          <>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                let url = "https://suporte.literatus.edu.br/";                  
                                Linking.openURL(url)
                                  .then(data => {
                                    //console.log("WhatsApp Opened successfully " + data);  //<---Success
                                  })
                                  .catch(() => {
                                    //alert("Make sure WhatsApp installed on your device");  //<---Error
                                  });
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    CHAMADOS - GLPI
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  //https://ava.literatus.edu.br/login/index.php
                                  let url = "https://ava.literatus.edu.br/login/index.php";                  
                                  Linking.openURL(url)
                                    .then(data => {
                                      //console.log("WhatsApp Opened successfully " + data);  //<---Success
                                    })
                                    .catch(() => {
                                      //alert("Make sure WhatsApp installed on your device");  //<---Error
                                    });
                                    if(Device.osName!='Windows'){
                                      setIsMenu(!ismenu);
                                    }
                                  
                              }}
                              disabled={false}
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 3,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    AVA LITERATUS
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{marginBottom: 1, marginLeft: 5, marginRight: 5, width: '92%', left: 4}}>
                          <TouchableOpacity
                              style={{ borderRadius: 5,  backgroundColor: '#87CEFA',  height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {
                                  navigation.navigate('Faq');
                                  if(Device.osName!='Windows'){
                                    setIsMenu(!ismenu);
                                  }
                              }}
                              disabled={false}
                              
                          >               
                              <View style={{width: '100%',flexDirection: 'row',}}>
                                <IconFeather name="corner-down-right" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding: 1,}}/>
                                <Text style={{ padding: 1 , fontSize:  Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#000', fontWeight: 'bold' }} >
                                    FAQ
                                </Text>
                              </View>
                          </TouchableOpacity>
                        </View>                        
                        </>
                        ):null}
                  </View>
               
              
             
              <View style={{width:Device.osName=='Windows'?null:'70%', margin: 5 }}>
                <TouchableOpacity
                  style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: '100%', alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => { 
                    //FECHAR TODOS OS MENUS
                    fecharmenu();                              
                                
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## SAIR ##', // This is a title
                        `Deseja realmente sair?`,
                        {
                            textConfirm: 'SIM',      // Label of button confirm
                            textCancel: 'NÃO',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { {
                              signOut();
                             } } // Call your confirm function 
                        }
                    )
                    
                  }}
                >
                  <View style={{ width: '100%', flexDirection: 'row', }}>
                    <Icon name="door-open" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 8: 3,paddingLeft: 5}} />
                    <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                      SAIR
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              
              <Modal animationType='slide' transparent={true} visible={modalVisible}>
                    <ScrollView>
                    <Card style={[styles.card,{width: Device.osName=='Windows'? '70vw':'95%', height: Device.osName=='Windows'?'70vh':'100%', alignSelf:'center', top: 10}]} elevation={5} mode={'elevated'}> 
                    <Container>
                            <Form><View style={{width: Device.osName=='Windows'? '60vw':'94%', borderColor:Device.osName=='Windows'?'#004b9e': null, borderWidth: Device.osName=='Windows'? 1 : null, borderRadius: 5 }}>
                                <Text style={{position: 'relative', margin: 5,fontSize: 13, fontWeight:'bold'}}>SELECIONE A SEDE:</Text>
                                <Row style={{position: 'relative', margin: 10}}>
                                    <Form.Group as={Col} >
                                      <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width: 200}}>
                                          <Text>TODOS</Text>
                                          <RadioButton
                                            value="000"
                                            status={ checked.cod === '000' ? 'checked' : 'unchecked' }
                                            onPress={() => setChecked({
                                                      cod: "000",
                                                      nomepredio: "TODOS"
                                                    })}
                                          />
                                      </View>
                                    </Form.Group> 
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>SEDE PARA</Text>
                                        <RadioButton
                                          value="001"
                                          status={ checked.cod === '001' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                cod: '001',
                                                nomepredio: "SEDE PARA"
                                            })}
                                        />   
                                    </View>
                                    </Form.Group> 
                                </Row> 
                                <Row style={{position: 'relative', margin: 10}}> 
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>SEDE DJALMA</Text>
                                        <RadioButton
                                          value="002"
                                          status={ checked.cod === '002' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                      cod: '002',
                                                      nomepredio: "SEDE DJALMA"
                                                  })}
                                        />   
                                    </View>
                                    </Form.Group>  
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>SEDE PARAIBA</Text>
                                        <RadioButton
                                          value="003"
                                          status={ checked.cod === '003' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                      cod: '003',
                                                      nomepredio: "SEDE PARAIBA"
                                                  })}
                                        />   
                                    </View>
                                    </Form.Group> 
                                </Row>
                                <Row style={{position: 'relative', margin: 10}}> 
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>SEDE GRANDE CIRCULAR</Text>
                                        <RadioButton
                                          value="004"
                                          status={ checked.cod === '004' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                    cod: '004',
                                                    nomepredio: "SEDE GRANDE CIRCULAR"
                                                })}
                                        />   
                                    </View>
                                    </Form.Group> 
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>POLO CENTRO</Text>
                                        <RadioButton
                                          value="005"
                                          status={ checked.cod === '005' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                    cod: '005',
                                                    nomepredio: "POLO CENTRO"
                                                })}
                                        />   
                                    </View>
                                    </Form.Group>  
                                </Row>
                                <Row style={{position: 'relative', margin: 10}}> 
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>POLO TABATINGA</Text>
                                        <RadioButton
                                          value="006"
                                          status={ checked.cod === '006' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                              cod: '006',
                                                              nomepredio: "POLO TABATINGA"
                                                          })}
                                        />   
                                    </View>
                                    </Form.Group> 
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>POLO TEFÉ</Text>
                                        <RadioButton
                                          value="007"
                                          status={ checked.cod === '007' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                      cod: '007',
                                                      nomepredio: "POLO TEFE"
                                                  })}
                                        />   
                                    </View>
                                    </Form.Group>   
                                </Row>
                                <Row style={{position: 'relative', margin: 10}}> 
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>POLO PRES. FIGUEIREDO</Text>
                                        <RadioButton
                                          value="008"
                                          status={ checked.cod === '008' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                    cod: '008',
                                                    nomepredio: "POLO PRESIDENTE FIGUEIREDO"
                                                })}
                                        />   
                                    </View>
                                    </Form.Group>  
                                    <Form.Group as={Col} style={{flexDirection:'row'}}>
                                    <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', width:200}}>
                                        <Text>POLO IRANDUBA</Text>
                                        <RadioButton
                                          value="009"
                                          status={ checked.cod === '009' ? 'checked' : 'unchecked' }
                                          onPress={() => setChecked({
                                                    cod: '009',
                                                    nomepredio: "POLO IRANDUBA"
                                                })}
                                        />   
                                    </View>
                                    </Form.Group>  
                                </Row>
                                <Row> 
                                    <Form.Group as={Row} style={{position:'relative', width: Device.osName=='Windows'? '50vw' : '95%', height: Device.osName=='Windows'?null:'100%', alignItems:'center', justifyContent:'space-between', margin: 30}}>
                                        <TouchableOpacity
                                            style={{ borderRadius: 5,  backgroundColor:'#004b9e',  height: 35,  width: 100, alignItems: 'center', justifyContent:'center' }}
                                            onPress={() => {

                                                                                                
                                                signUp(
                                                    user.nome,
                                                    user.superadmin, 
                                                    user.admin, 
                                                    user.gestor, 
                                                    user.administrativo, 
                                                    user.foto, 
                                                    user.coligada, 
                                                    user.filial, 
                                                    checked.cod=='000' ? 'TODOS' : 
                                                    checked.cod=='001' ? 'SEDE PARA' : 
                                                    checked.cod=='002' ? 'SEDE DJALMA' : 
                                                    checked.cod=='003' ? 'SEDE PARAIBA' : 
                                                    checked.cod=='004' ? 'SEDE GRANDE CIRCULAR' : 
                                                    checked.cod=='005' ? 'POLO CENTRO' : 
                                                    checked.cod=='006' ? 'POLO TABATINGA' : 
                                                    checked.cod=='007' ? 'POLO TEFE' : 
                                                    checked.cod=='008' ? 'POLO PRESIDENTE FIGUEIREDO' :
                                                    'POLO IRANDUBA', 
                                                    checked.cod, user.ativo, 
                                                    user.trocasenha, 
                                                    user.token, user.alterperfil );                        
                                                    setModalVisible(!modalVisible);
                                                    window.location.reload(false); 
                                            }}
                                        >               
                                            <View>
                                              <Text style={{ padding: Device.osName=='Windows'? 10: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                                                  SELECIONAR
                                              </Text>
                                            </View>
                                        </TouchableOpacity>  
                                        <TouchableOpacity
                                            style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 100, alignItems: 'center', justifyContent:'center' }}
                                            onPress={() => {
                                              setModalVisible(!modalVisible);
                                            }}
                                        >               
                                            <View>
                                              <Text style={{ padding: Device.osName=='Windows'? 10: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                                                  SAIR
                                              </Text>
                                            </View>
                                        </TouchableOpacity>             
                                                   
                                    </Form.Group>  
                                </Row>
                                </View></Form>
                    </Container>
                    </Card>
                    </ScrollView>
        </Modal>            
        </View>
    )

}
