import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, useRef, useCallback} from "react";
import { Text, View, Dimensions, TouchableOpacity, Linking,PixelRatio, Image, ActivityIndicator } from "react-native";
import { Card, TextInput, Switch  } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { UtilContext } from '../../contexts/utilidade';
import { AuthContext } from '../../contexts/auth';
import { useIsFocused  } from '@react-navigation/native';
import * as Device from 'expo-device';//Device.osName
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import Feather from 'react-native-vector-icons/Feather';
Feather.loadFont();

//import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
const {width, height} = Dimensions.get('screen');

export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso, Perfil } = useContext(UtilContext);
    const [nomeuser, setNomeUser] = useState('');
    const [loginuser, setLoginUser] = useState('');
    const loginsenha = 'gestao@123';
    const [celularuser, setCelularUser] = useState('');
    const [emailuser, setEmailUser] = useState('');
    const [issuperadmin, setIsSuperAdmin] = useState(false);
    const [isadmin, setIsAdmin] = useState(false);
    const [isgestor, setIsGestor] = useState(false);
    const [isadministrativo, setIsAdministrativo] = useState(false);
    const [isupload, setIsUpload] = useState(false);
    const [loading, setLoading] = useState(false);

    const [predio, setPredio] = useState('');
    const [idpredio, setIdPredio] = useState('');
    const [codcoligada, setCodColigada] = useState('');
    const [codfilial, setCodFilial] = useState('');
    const [codpredio, setCodPredio] = useState('');
    const nome = useRef(null);
    const login = useRef(null);
    const senha = useRef(null);

    
    const onOpenSuggestionsList = useCallback(isOpened => {}, []);
    const dropdownController = useRef(null);
    const searchRef = useRef(null);
    //const [dadospermissao, setDadosPermissao] = useState([]);
    
    const dadospermissao = Perfil
    /* const dadospermissao  = [
        {id: 1, title:'000-TODOS', name: 'TODOS', codcoligada: 3, codfilial: 1, codpredio: '000'},
        {id: 2, title:'001-SEDE PARA', name: 'SEDE PARA', codcoligada: 3, codfilial: 1, codpredio: '001'},
        {id: 3, title:'002-SEDE DJALMA', name: 'SEDE DJALMA', codcoligada: 3, codfilial: 1, codpredio: '002'},
        {id: 4, title:'003-SEDE PARAIBA', name: 'SEDE PARAIBA', codcoligada: 3, codfilial: 1, codpredio: '003'},
        {id: 5, title:'004-SEDE GRANDE CIRCULAR', name: 'SEDE GRANDE CIRCULAR', codcoligada: 3, codfilial: 1, codpredio: '004'},
        {id: 6, title:'005-POLO CENTRO', name: 'POLO CENTRO', codcoligada: 3, codfilial: 1, codpredio: '005'}
      ]; */

    //console.log(dadospermissao);
    /* const ItemViewPermissao = ({ item }) => {
        return (
            <Text onPress={() => getItem(item)}>
                {item.name}
            </Text>
        );
      } */
      
      const perupload = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Permissão Upload</Popover.Header>
          <Popover.Body>
            Com essa permissão o usuário poderá <strong>Criar Diretórios</strong>, <strong>Enviar Arquivos</strong> e 
            <strong> Deletar Arquivos/Diretório</strong>.
            Desmarcado só terá permissão de <strong>Download</strong> e acesso aos diretórios.
          </Popover.Body>
        </Popover>
      );

      const persuperadmin = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Permissão SuperAdmin</Popover.Header>
          <Popover.Body>
            Essa permissão só deve ser marcada se for funcionário TI.
            Terá funcionalidades exclusiva para TI
          </Popover.Body>
        </Popover>
      );

          
      //GET DO FORM MODAL - TIPO
      const getItem = (item) => {
        setPredio(item.name);
        setIdPredio(item.id);
        setCodColigada(item.codcoligada);
        setCodFilial(item.codfilial);
        setCodPredio(item.codpredio);
        //alert(item.value)
      };


    useEffect(() => { 
        document.title = dotenv.TITLE;
    }, []);

    useEffect(() => { 

        if(!issuperadmin && !isadmin && !isgestor && !isadministrativo && isupload)
         setIsUpload(false);
        
        if(!issuperadmin && !isadmin && isgestor && !isadministrativo && isupload)
         setIsUpload(false);

        if(issuperadmin && !isadmin && !isgestor && !isadministrativo)
         setIsUpload(true);

        if(!issuperadmin && isadmin && !isgestor && !isadministrativo)
         setIsUpload(true);


    }, [issuperadmin, isadmin, isgestor, isadministrativo, isupload]);


    const focus = useIsFocused();  // useIsFocused as shown 
   useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
       if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
                    /* alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## CADATRO NOVO LOGIN ##', // This is a title
                        `Nome Usuário: ex.: João Silva\nLogin: ex.:joao.silva\nCelular: ex.: número válido preferencialmente com whatsapp\nEmail: email válido (ativação será por email)!\nPerfil: (Admin(somente TI), Gestor ou Administrativo. Escolha somente 1 (um))\n\nOBSERVAÇÃO: Validação é por email informe email válido!`,
                        {
                            textConfirm: 'ENTENDIDO!',      // Label of button confirm
                            textCancel: '',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { { } } // Call your confirm function 
                        }
                    ) */              
       }
   }, [focus]);

   function formatCelular(v){
        var r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 5) {
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
            r = r.replace(/^(\d*)/, "($1");
        }
        setCelularUser(r);
   }

   async function salvar_login(){        
        try {
            
            if(nomeuser==''||loginuser==''||celularuser==''||emailuser==''){
                Aviso("Error Novo Login:", 'Campos Obrigatórios Pendentes!!!', "danger", 3000);   
                return false;
            }
            
            if(predio==''||codcoligada==''||codfilial==''||codpredio==''){
                Aviso("Error Novo Login:", 'permissão Pendentes!!!', "danger", 3000);   
                return false;
            }
            
            if(isadmin==false && isgestor==false&&isadministrativo==false && issuperadmin==false){
                Aviso("Error Novo Login:", 'Perfil Pendente!!!', "danger", 3000);   
                return false;
            }
            if(celularuser.length < 15){
                Aviso("Error:", 'Celular no formato Inválido!', "danger", 3000);
                return false;
            }
          setLoading(true);
          await fetch(`${dotenv.RESTGESTOR}/salvarlogin`, {
                    method: 'POST',
                    body: JSON.stringify({
                        nome: PrimeiraLetraMaiuscula(nomeuser),
                        login: loginuser,
                        senha: loginsenha,
                        celular: celularuser,
                        email: emailuser,
                        superadmin: issuperadmin,
                        admin: isadmin,
                        gestor: isgestor,
                        administrativo: isadministrativo,
                        upload: isupload,
                        predio: predio,
                        idpredio: idpredio,
                        codcoligada: codcoligada,
                        codfilial: codfilial,
                        codpredio: codpredio                        
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                    setLoading(false);

                    if (responseJson.validtoken==false){
                        //alert(responseJson.message); 
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TOKEN - EXPIRADO ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        
                        //setLoading(-1);
                        //signOut();
                        return
                    }
                    //console.log(JSON.stringify(responseJson));
                    //console.log(responseJson)
                    if (responseJson.validtoken==false){
                        //console.log(responseJson);                    
                        //Aviso("Error:", responseJson.message, "danger", 3000); 
                        //signOut();
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TOKEN - FALHA ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        return
                    }
                    
                    if(responseJson.sucesso == true){
                        Aviso("", responseJson.message, "success", 3000); 
                        setNomeUser('');
                        setLoginUser('');
                        setCelularUser('');
                        setEmailUser('');
                        setIsAdministrativo(false);
                        setIsAdmin(false);
                        setIsGestor(false);
                        setIsSuperAdmin(false);
                        setIsUpload(false);
                        nome.current.focus();
                        dropdownController.current.clear();  
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);                         
                    }
              })
              .catch((error) => {
                  setLoading(false);
                  Aviso("Error:", error.message, "danger", 3000);                                       
              })
              .finally(function () { setLoading(false); });  
            
        } catch (err) {
            setLoading(false);
            Aviso("Error:", err.message, "danger", 3000);
            
        } finally {{setLoading(false);}}
   }

   function PrimeiraLetraMaiuscula(value){
        const frase = value;
        const palavras = frase.split(" ");
        for (let i = 0; i < palavras.length; i++) {
            palavras[i] = palavras[i][0].toUpperCase() + palavras[i].substr(1);
        }
        return palavras.join(" ");
   }

    return (
        <Container fluid="md">
            <Form style={{overflow:'auto', height: '100vh'}}>
                <Row>
                    <Form.Group as={Col}>
                    <TextInput 
                        //label={'Nome Usuário'}
                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Nome Usuário</Text>}
                        ref={nome}
                        autoCorrect={false}
                        autoComplete={false}
                        placeholder={'Ex.: João Silva'}
                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                        style={{margin: 10}}
                        mode='outlined'
                        selectionColor={'#004b9e'}
                        underlineColor={'#004b9e'}
                        activeUnderlineColor={'#004b9e'}
                        outlineColor={'#004b9e'}
                        activeOutlineColor={'#004b9e'}
                        textColor={'#004b9e'}
                        dense={true}
                        value={nomeuser}
                        onChangeText={(text) => {setNomeUser(text)}}                        
                        left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                    />
                    </Form.Group>

                    <Form.Group as={Col}>
                    <TextInput 
                        //label={'Login Usuário'}
                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Login Usuário</Text>}
                        ref={login}
                        placeholder={'Ex.: joao.silva'}
                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                        style={{margin: 10}}
                        mode='outlined'
                        selectionColor={'#004b9e'}
                        underlineColor={'#004b9e'}
                        activeUnderlineColor={'#004b9e'}
                        outlineColor={'#004b9e'}
                        activeOutlineColor={'#004b9e'}
                        textColor={'#004b9e'}
                        dense={true}
                        value={loginuser}
                        onChangeText={(text) => {setLoginUser(text)}}
                        left={<TextInput.Icon icon="account-edit" disabled={true}/>}
                    />
                    </Form.Group>
                    
                    <Form.Group as={Col}>
                        <TextInput 
                            //label={Device.osName=='Windows' ? 'senha inicial= gestao@123' : 'senha inicial= gestao@123'}
                            label={<Text style={{backgroundColor:"#FFF", padding: 5}}>{Device.osName=='Windows' ? 'senha inicial= gestao@123' : 'senha inicial= gestao@123'}</Text>}
                            ref={senha}
                            //placeholder={'primeironome.últimonome'}
                            //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                            style={{margin: 10}}
                            mode='outlined'
                            selectionColor={'#004b9e'}
                            underlineColor={'#004b9e'}
                            activeUnderlineColor={'#004b9e'}
                            outlineColor={'#004b9e'}
                            activeOutlineColor={'#004b9e'}
                            textColor={'#004b9e'}
                            dense={true}
                            disabled={true}
                            left={<TextInput.Icon icon="account-key" disabled={true}/>}
                            //value={loginuser}
                            //onChangeText={(text) => {setLoginUser(text)}}
                        />
                    </Form.Group>

                    <Form.Group as={Col}>
                            <TextInput 
                                //label={'Celular'}
                                label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Celular</Text>}
                                placeholder={'Ex.: 92 91234-5678'}
                                //style={{width: Device.osName=='Windows' ? 300 : '95%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                style={{margin: 10}}
                                mode='outlined'
                                selectionColor={'#004b9e'}
                                underlineColor={'#004b9e'}
                                activeUnderlineColor={'#004b9e'}
                                outlineColor={'#004b9e'}
                                activeOutlineColor={'#004b9e'}
                                textColor={'#004b9e'}
                                dense={true}
                                maxLength={15}
                                value={celularuser}
                                onChangeText={(text) => {formatCelular(text)}}
                                left={<TextInput.Icon icon="cellphone-check" disabled={true}/>}
                                
                            />
                    </Form.Group>
                    
                    <Form.Group as={Col}>
                            <TextInput 
                                //label={'Email'}
                                label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Email</Text>}
                                placeholder={'Ex.: joao.silva@email.com.br'}
                                //style={{width: Device.osName=='Windows' ? 300 : '95%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                style={{margin: 10}}
                                mode='outlined'
                                selectionColor={'#004b9e'}
                                underlineColor={'#004b9e'}
                                activeUnderlineColor={'#004b9e'}
                                outlineColor={'#004b9e'}
                                activeOutlineColor={'#004b9e'}
                                textColor={'#004b9e'}
                                dense={true}
                                value={emailuser}
                                onChangeText={(text) => {setEmailUser(text)}}
                                left={<TextInput.Icon icon="email-fast-outline" disabled={true}/>}
                            />
                    </Form.Group>

                    <Form.Group as={Col} style={{height: 50, position:'relative',zIndex: 2000}}>
                            
                            <AutocompleteDropdown
                                    ref={searchRef}
                                    controller={controller => {
                                        dropdownController.current = controller
                                    }}
                                    direction='down'
                                    onOpenSuggestionsList={onOpenSuggestionsList}
                                    ChevronIconComponent={<Feather name="chevron-down" size={20} style={{position: 'absolute', /*left:-5,*/}} color="#FF0000" />}
                                    ClearIconComponent={<Feather name="x-circle" size={20}  style={{position: 'absolute', /*left:-10,top:-10,*/ }} color="#FF0000" />}                                                                                                    
                                    clearOnFocus={true}
                                    closeOnBlur={false}
                                    closeOnSubmit={false}
                                    initialValue={{ id: 0}} // or just '2'
                                    position='absolute'
                                    debounce={600}
                                    onSelectItem={(item) => {
                                        if(item?.name){
                                            getItem(item)
                                        }
                                    }}
                                    onFocus={() => {                                                            
                                        setPredio('');
                                        setCodColigada('');
                                        setCodFilial('');
                                        setCodPredio('');
                                    }}
                                    onClear={() => {
                                        setPredio('');
                                        setCodColigada('');
                                        setCodFilial('');
                                        setCodPredio('');
                                    }}
                                    suggestionsListMaxHeight={height * 0.4}
                                    dataSet={dadospermissao}
                                    //dataSet={Perfil}
                                    textInputProps={{
                                        placeholder: 'Selecione Permissão Acesso e Diretórios',
                                        autoCorrect: false,
                                        autoCapitalize: 'none',
                                        style: {
                                            borderRadius: 5,
                                            color: '#000',                                                          
                                            backgroundColor: '#fff',
                                            fontSize: 15,
                                            height: 40,
                                            margin: 1,                                            
                                            //width: Device.osName=='Windows'? '95%': 500,
                                        }
                                    }}
                                    inputContainerStyle={{
                                        borderRadius: 5,
                                        color:'#000',
                                        //width: '95%',
                                        margin: 5,                                        
                                        //marginTop: 14
                                        
                                    }}

                                    containerStyle={{ flexGrow: 1, flexShrink: 1,}}
                                    renderItem={(item) => <Text style={{ color: '#000',  padding: 10, }}>{item.title}</Text>}
                                    inputHeight={30}
                                    EmptyResultComponent={<Text style={{ padding: 10, fontSize: 15, fontWeight: 'bold', color: '#FF0000'  }}>Nada Encontrado!</Text>}
                                    useFilter={false}
                                    editable={true}                                  
                                />
                    </Form.Group> 
                    <Form.Group as={Col} style={{zIndex: 1}}>
                        <Card.Content style={{borderColor:'#004b9e', borderWidth:1,borderRadius:5, top: 10, flexDirection:Device.osName=='Windows'?'row':'column', marginBottom: 80, flexWrap:'wrap', width: '80vw' }}>
                            <Text style={{position:'absolute', top:-10, backgroundColor:'#004b9e', width: 100, textAlign: 'center', borderRadius: 5, fontWeight:'bold', color:'#FFF'}}>Perfil - Login</Text>
                            <View style={{marginTop:Device.osName=='Windows'?null:10,flexDirection:Device.osName=='Windows'?'row':'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                {user?.superadmin == 1 ? (
                                <>
                                <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 1}}>
                                    SuperAdmin ( TI )
                                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={persuperadmin}>
                                        <Button variant=""><Icon2 name="help-circle" size={20} color="#004b9e"/></Button>
                                    </OverlayTrigger>
                                </Text>
                                <Switch value={issuperadmin} onValueChange={()=>{
                                    if(!issuperadmin){
                                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                            '## PERFIL - LOGIN ##', // This is a title
                                            `Este perfil é somente para TI!\nDeseja Continuar?`,
                                            {
                                                textConfirm: 'SIM',      // Label of button confirm
                                                textCancel: 'NÃO',     // Label of button cancel
                                                onCancel: () => { { } }, // Call your cancel function 
                                                onConfirm: () => { { 
                                                    setIsAdministrativo(false);
                                                    setIsGestor(false);
                                                    setIsAdmin(false);
                                                    setIsSuperAdmin(!issuperadmin);
                                                } } // Call your confirm function 
                                            }
                                        )
                                    }else{
                                        setIsAdministrativo(false);
                                        setIsGestor(false);
                                        setIsAdmin(false);
                                        setIsSuperAdmin(!issuperadmin);
                                    }
                                    }} />
                                    </>
                                ): null}
                            </View>
                            <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>Admin</Text>
                                <Switch value={isadmin} onValueChange={()=>{
                                        setIsAdministrativo(false);
                                        setIsGestor(false);
                                        setIsAdmin(!isadmin);
                                        setIsSuperAdmin(false);
                                                                            
                                }} />
                            </View>
                            <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>Gestor</Text>
                                <Switch value={isgestor} onValueChange={()=>{
                                    setIsAdministrativo(false);
                                    setIsAdmin(false);
                                    setIsGestor(!isgestor);
                                    setIsSuperAdmin(false);
                                }} />
                            </View>
                            <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>Administrativo</Text>
                                <Switch value={isadministrativo} onValueChange={()=>{
                                    setIsAdmin(false);
                                    setIsGestor(false);
                                    setIsAdministrativo(!isadministrativo);
                                    setIsSuperAdmin(false);
                                }} />
                                
                            </View>
                            <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>
                                    Upload
                                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={perupload}>
                                        <Button variant=""><Icon2 name="help-circle" size={20} color="#004b9e"/></Button>
                                    </OverlayTrigger>
                                    
                                </Text>
                                <Switch value={isupload} onValueChange={()=>{
                                    setIsUpload(!isupload);
                                }} />
                                
                            </View>
                        </Card.Content>
                    </Form.Group>       
                </Row>        
                <Form.Group as={Col} style={{zIndex: -10}}>
                        <TouchableOpacity
                            onPress={()=>{
                                salvar_login();
                            }}
                            style={{backgroundColor:'#004b9e', top: Device.osName=='Windows' ? null : -60,width: Device.osName=='Windows' ? 130 : 100, height: Device.osName=='Windows' ? 40 : 30, alignItems:'centrer', justifyContent:'center', borderRadius:10,}}
                        >
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                {loading ? (
                                    <ActivityIndicator size={25} style={{position:'relative',marginRight: 20}} color='#eee' />
                                ):(
                                    <Icon name="save" size={20} color="#A9A9A9" style={{marginRight: 20}}/>
                                )}

                                <Text style={{textAlign:'center', fontSize: Device.osName=='Windows' ? 15 : 13, fontWeight:'bold', color:'#FFF'}}>SALVAR</Text>
                            </View>
                        </TouchableOpacity>                    
                </Form.Group>         
            </Form>
      </Container>
    )
}
