import React from "react";

import Computador from '../processoseletivo/indexComp';
import Mobile from '../processoseletivo/indexMobile';

import * as Device from 'expo-device';//Device.osName

export default function App({navigation}) {

    return(
        Device.osName=='Windows' ? <Computador navigation={navigation}/> : <Mobile navigation={navigation}/>
    )

}