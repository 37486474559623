import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState} from "react";
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, Modal } from "react-native";
import { Card, TextInput } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { ScrollView } from 'react-native-web';
import { useIsFocused  } from '@react-navigation/native';
import validator from 'validator'

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const {width, height} = Dimensions.get('screen');

export default function App({route, navigation}) {
    const { signed, user , signOut} = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso } = useContext(UtilContext);
    const [url,  setUrl] = useState('');
    const [novourl,  setNovoUrl] = useState('');
    const [idurl,  setIdUrl] = useState('');
    const [urlmsg, setUrlMsg] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [random, setRandom]=useState('');

    /* useEffect(() => { 
        document.title = dotenv.TITLE;
        //alert(JSON.stringify(name));
        //console.log("Home-Index: "+signed);
    }, []); */

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
            if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
                //console.log(JSON.stringify(user));
                document.title = dotenv.TITLE;
                let data = new Date();
                setRandom(data);
                fetchDataDashBoard(user?.codpredio);   
                //console.log(user);
            }
    }, [focus]);


    const salvarDashBoard = async (id, valueurl) => {

        try {
              await fetch(`${dotenv.RESTGESTOR}/savedashboard`, {
                  method: 'POST',
                  body: JSON.stringify({
                      id: id,                        
                      url: valueurl                        
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                  },
              })
              .then((response) => response.json())
              .then((responseJson) => {
                    if(responseJson.sucesso == true){
                            Aviso("DashBoard", responseJson.message, "success", 3000);
                            fetchDataDashBoard(user?.codpredio);
                            setNovoUrl('');
                    }else{
                        Aviso("DashBoard Erro1", responseJson.message, "danger", 3000);                        
                    }
              })
              .catch(function (error) {
    
                console.log(error);
                if(error.code ==='ERR_NETWORK'){
                  alert('Erro Comunicação com Servidor');
                  return
                }else{
                  Aviso("Error DashBoard0:", error.message, "danger", 3000);  
                }                   
              })
              .finally(function () {});
          } catch (err) {
              console.log(err);
              Aviso("Error DashBoard2:", err.message, "danger", 3000);
          } finally {}
        
    }
    
    const fetchDataDashBoard = async (value) => {
        try {
            //alert(`Value: X${value}X`);
            /* if(value == '000'){
                return false;
            } */
                
             await fetch(`${dotenv.RESTGESTOR}/dashboard`, {
                  method: 'POST',
                  body: JSON.stringify({
                      codpredio: value                        
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                  },
              })
              .then((response) => response.json())
              .then((responseJson) => {
                    if(responseJson.sucesso == true){  
                        
                        if (responseJson.validtoken==false){
                            //alert(responseJson.message); 
                            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                '## TOKEN - EXPIRADO ##', // This is a title
                                `${responseJson.message}`,
                                {
                                    textConfirm: 'OK',      // Label of button confirm
                                    textCancel: '',     // Label of button cancel
                                    onCancel: () => { { } }, // Call your cancel function 
                                    onConfirm: () => { { 
                                        signOut();
                                    } } // Call your confirm function 
                                }
                            )
                            
                            //setLoading(-1);
                            //signOut();
                            return
                        }
                        //console.log(JSON.stringify(responseJson));
                        //https://bi.literatus.edu.br/public/dashboard/f5f30061-f49d-41c6-8300-d981bc9ec77b 
                        
                        if (validator.isURL(responseJson.dados[0].url) && responseJson.dados[0].url !='') {
                            setUrl(responseJson.dados[0].url); 
                            setIdUrl(responseJson.dados[0].id); 
                            setUrlMsg('');
                        } else {
                            if (responseJson.dados[0].url ==''){
                                Aviso("Error DashBoard:", 'URL DashBoard Não Informado', "danger", 3000);  
                                setUrlMsg('URL DashBoard Não Informado');
                            }else{
                                Aviso("Error DashBoard:", `URL DashBoard ${responseJson.dados[0].url} Inválido`, "danger", 3000);  
                                setUrlMsg(`URL DashBoard ${responseJson.dados[0].url} Inválido`);
                            }
                            
                        }                        
                        
                    }else{
                        if (responseJson.validtoken==false){
                            //alert(responseJson.message); 
                            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                '## TOKEN - EXPIRADO ##', // This is a title
                                `${responseJson.message}`,
                                {
                                    textConfirm: 'OK',      // Label of button confirm
                                    textCancel: '',     // Label of button cancel
                                    onCancel: () => { { } }, // Call your cancel function 
                                    onConfirm: () => { { 
                                        signOut();
                                    } } // Call your confirm function 
                                }
                            )
                            
                            //setLoading(-1);
                            //signOut();
                            return
                        }
                        //Aviso("", responseJson.message, "danger", 3000);
                        //console.log(JSON.stringify(responseJson));
                        setUrlMsg(responseJson.message);
                    }
              })
              .catch(function (error) {
    
                console.log(error);
                if(error.code ==='ERR_NETWORK'){
                  alert('Erro Comunicação com Servidor');
                  return
                }else{
                  Aviso("Error DashBoard4:", error.message, "danger", 3000);  
                }                    
              })
              .finally(function () {});
          } catch (err) {
              console.log(err);
              Aviso("Error DashBoard5:", err.message, "danger", 3000);
          } finally {}
        
    }

    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    
                    <Card style={[styles.card,{}]}>
                    <Container>
                    <Row xs="auto" >
                        <Col style={{position:'relative',left: -50}}>
                        {Device.osName!='Windows' ? (
                                <TouchableOpacity
                                    style={{ position:'relative',left:10,borderRadius: 5,  backgroundColor: '#004b9e', alignItems: 'center', justifyContent: 'center', zIndex:1001 }}
                                    onPress={() => {
                                    setIsMenu(!ismenu)
                                    }}
                                    disabled={false}
                                >               
                                    <View style={{width: '100%',flexDirection: 'row',}}>
                                        <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                                        <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                                            MENU
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            ):null}
                        </Col>
                                               
                        <Col >
                                <Text style={{textAlign: 'center',alignItems:'center', justifyContent:'center',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 12}}>
                                    {`:: ${dotenv.TITLE} ::`}
                                </Text>
                        </Col>
                        <Col style={{position:'relative',left: 50}}>
                            {Device.osName!='Windows' ? (
                                <View style={{ width: 40, backgroundColor: '#FFF' , borderRadius: 5 }}>
                                    <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                                </View>
                            ):null}
                        </Col>                         
                        <Col >
                                {/* <TouchableOpacity
                                    style={{ borderRadius: 5, backgroundColor: '#004b9e',  alignItems: 'center', justifyContent: 'center' }}
                                    onPress={() => { 
                                        setModalVisible(!modalVisible);              
                                    }}
                                    >
                                    <View style={{flexDirection: 'row', }}>
                                        <Icon name="globe" size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{ padding: Device.osName=='Windows'? 10: 3,paddingLeft: 5}} />
                                        <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 8, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                        ALTERAR URL
                                        </Text>
                                    </View>
                                </TouchableOpacity> */}
                        </Col>
                    </Row>
                    </Container>
                        {/* <View style={{position:'relative',width: Device.osName=='Windows' ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : 10 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{width: Device.osName=='Windows' ? '100%' : 200, top: Device.osName=='Windows' ? null : 10,padding:Device.osName=='Windows'?10: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <View style={{flexDirection: 'row', width:'100%'}}>
                                
                                
                                </View>
                            </View>                            
                        </View> */}                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', top: 5}}>
                    <Card style={styles.card}> 
                        {url != '' ? (
                        <iframe
                            key={random}
                            src= {url}
                            //frameborder="0"
                            //allowtransparency={true}
                            width={width * 0.8}
                            height={height* 0.64}
                            style={{borderRadius: 10}}
                        ></iframe>
                        ):(
                            <View>
                                <Text style={{fontSize: 15, fontWeight:'bold', color:'#FF0000'}}>{urlmsg}</Text>
                            </View>
                        )}
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
            <Modal animationType='slide' transparent={true} visible={modalVisible}>
                    <ScrollView>
                    <Card style={[styles.card,{height: 150, width: '50vw', alignSelf:'center',marginVertical: '50%'}]} elevation={5} mode={'elevated'}> 
                    <Container>
                            <Form style={{width: '50vw' }}>
                                <Row>
                                    <Form.Group as={Col}>
                                        <TextInput 
                                            label={'URL DASHBOARD'}
                                            autoCorrect={false}
                                            //autoComplete={false}
                                            autoComplete="off"//DESATIVAR O AUTOCOMPLETE DO INPUT
                                            placeholder={'Ex.: https://abc.com.br'}
                                            style={{margin: 10}}
                                            mode='outlined'
                                            selectionColor={'#004b9e'}
                                            underlineColor={'#004b9e'}
                                            activeUnderlineColor={'#004b9e'}
                                            outlineColor={'#004b9e'}
                                            activeOutlineColor={'#004b9e'}
                                            textColor={'#004b9e'}
                                            dense={true}
                                            value={novourl}
                                            onChangeText={(text) => {setNovoUrl(text)}}                        
                                            left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                                        />
                                    </Form.Group> 
                                </Row> 
                                <Row> 
                                <Form.Group as={Col}>
                                    <TouchableOpacity
                                            style={{ margin: 5,borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: 140, alignItems: 'center', justifyContent: 'center', marginLeft: 12 }}
                                            onPress={() => { 
                                                if (validator.isURL(novourl)) {
                                                    setModalVisible(!modalVisible);
                                                    salvarDashBoard(idurl, novourl);          
                                                } else {
                                                    Aviso("Error DashBoard0:", 'URL DashBoard Inválido', "danger", 3000);  
                                                }
                                                
                                                
                                                
                                            }}
                                            >
                                            <View style={{flexDirection: 'row', }}>
                                                <Icon name="save" size={20} color="#FFD700" style={{ padding: 10,paddingLeft: 5}} />
                                                <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                                SALVAR
                                                </Text>
                                            </View>
                                    </TouchableOpacity>                    
                                </Form.Group>  
                                <Form.Group as={Col}>
                                    <TouchableOpacity
                                            style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: 140, alignItems: 'center', justifyContent: 'center', marginLeft: 12 }}
                                            onPress={() => { 
                                                setModalVisible(!modalVisible);             
                                            }}
                                            >
                                            <View style={{flexDirection: 'row', }}>
                                                <Icon name="door-open" size={20} color="#FFD700" style={{ padding: 10,paddingLeft: 5}} />
                                                <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                                SAIR
                                                </Text>
                                            </View>
                                    </TouchableOpacity>                    
                                </Form.Group> 
                                </Row>
                            </Form>
                    </Container>
                    </Card>
                    </ScrollView>
        </Modal>
        <StatusBar style="auto" />
        </View>
    )
}
