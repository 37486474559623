import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, Modal, ScrollView, FlatList, ActivityIndicator } from "react-native";

import { Card, TextInput } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialIcons';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const {width, height} = Dimensions.get('screen');

export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso } = useContext(UtilContext);
    const [pergunta, setPergunta] = useState('');
    const [resposta, setResposta] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [dadosfaq, setDadosFaq] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    /* useEffect(() => { 
        document.title = dotenv.TITLE;
        fetchData(user.codpredio?? "000");
    }, []); */

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            document.title = dotenv.TITLE;
            fetchData(user.codpredio?? "000");     
        }
    }, [focus]);

    async function procurarpesquisa(){
        if(search ==''){
            fetchData(user.codpredio?? "000");
            alert('Informe o que deseja procurar');
            return false;
        }
        
        if(search.length < 5){
            fetchData(user.codpredio?? "000");
            alert('Aumente a palavra que está procurando, a atual não atende o requisito mínimo.');
            return false;
        }

         //alert(search);
         fetchData(user.codpredio?? "000", search);
    }

    function getresult({ item: pesquisa }) {
        return (
          
          <>
            {Device.osName=='Windows' ? (
                <View style={{ width: width*0.83,  flexDirection: 'column', backgroundColor: pesquisa.id %2 ? '#D3D3D3' : '', margin: 5, borderRadius: 5}}>
                    {user.superadmin==1 || user.admin==1 ?(
                        <View style={{flexDirection:'row', margin: 5 }}>
                            <TouchableOpacity
                                onPress={()=>{
                                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                        '## FAQ ##', // This is a title
                                        `${user.codpredio=='000' ? 'Você tem acesso a todos os FAQ, verifique se está excluindo corretamente.\n':''}Deseja excluir pergunta "${pesquisa.pergunta}"\ncontinuar?`,
                                        {
                                            textConfirm: 'SIM',      // Label of button confirm
                                            textCancel: 'NÃO',     // Label of button cancel
                                            onCancel: () => { { } }, // Call your cancel function 
                                            onConfirm: () => { { 
                                                fetch(`${dotenv.RESTGESTOR}/deletefaq`, {
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        id: pesquisa.id,
                                                    }),
                                                    timeout: 5000,
                                                    headers: {
                                                        "Content-type": "application/json; charset=UTF-8",
                                                        "x-access-token": user.token,
                                                    },
                                                })
                                            .then((response) => response.json())
                                            .then((responseJson) => {                  
                                                    fetchData(user.codpredio?? "000");                                           
                                            })
                                            .catch((error) => {
                                                Aviso("Error:", error.message, "danger", 3000);                         
                                            })
                                            .finally(function () {}); 
                                            } } // Call your confirm function 
                                        }
                                    )
                                    
                                    
                                }}
                            >
                                <Icon name={"trash"} size={15} color="#FF0000" style={{padding:  3,}}/>
                            </TouchableOpacity>
                        </View> 
                    ): null}                   
                    {user.codpredio == '000' ? (
                    <View style={{flexDirection:'row', margin: 5 }}>
                        <Text style={{fontWeight:'bold', marginRight: 5}}>Faq:</Text>
                        <Text style={{width: '100%'}}>{
                            pesquisa.codpredio=='000' ? 'TODAS SEDE': 
                            pesquisa.codpredio=='001' ? 'SEDE PARA' : 
                            pesquisa.codpredio=='002' ? 'SEDE DJALMA' : 
                            pesquisa.codpredio=='003' ? 'SEDE PARAIBA' : 
                            pesquisa.codpredio=='004' ? 'SEDE GRANDE CIRCULAR' : 
                            pesquisa.codpredio=='005' ? 'POLO CENTRO' :
                            pesquisa.codpredio=='006' ? 'POLO TABATINGA' : 'POLO TEFE'
                            }
                        </Text>
                    </View>  
                    ):null}                  
                    <View style={{flexDirection:'row', margin: 5 }}>
                        <Text style={{fontWeight:'bold', marginRight: 5}}>Pergunta:</Text>
                        <Text style={{width: '100%'}}>{pesquisa.pergunta}</Text>
                    </View>                    
                    <View style={{ flexDirection:'row', margin: 5 }}>
                        <Text style={{fontWeight:'bold'}}>Resposta: </Text>
                        <Text style={{width: '90%', height: '100%',flexWrap:'wrap', textAlign: 'justify'}}>{pesquisa.resposta}</Text>
                    </View>                    
                </View>
            ):null}
          </>
        )
      }

    async function salvar_faq(){

        try {
            await fetch(`${dotenv.RESTGESTOR}/savefaq`, {
                method: 'POST',
                body: JSON.stringify({
                    codpredio: user.codpredio,
                    pergunta: pergunta,                        
                    resposta: resposta                     
                }),
                timeout: 5000,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-access-token": user.token,
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                  if(responseJson.sucesso == true){
                    if (responseJson.validtoken==false){
                        //alert(responseJson.message); 
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TOKEN - EXPIRADO ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        
                        //setLoading(-1);
                        //signOut();
                        return
                    }
                          setPergunta('');
                          setResposta('');
                          Aviso("Faq", responseJson.message, "success", 3000);
                          fetchData(user.codpredio?? "000");
                          setModalVisible(!modalVisible);   
                  }else{
                      Aviso("Faq Erro1", responseJson.message, "danger", 3000);                        
                  }
            })
            .catch(function (error) {
  
              console.log(error);
              if(error.code ==='ERR_NETWORK'){
                alert('Erro Comunicação com Servidor');
                return
              }else{
                Aviso("Error Faq0:", error.message, "danger", 3000);  
              }                   
            })
            .finally(function () {});
        } catch (err) {
            console.log(err);
            Aviso("Error Faq2:", err.message, "danger", 3000);
        } finally {}
    }

    const fetchData = async (value, pesq) => {
        try {
            //alert(`Value: X${value}X`);
            setLoading(true);
            setDadosFaq([]);
            await fetch(`${dotenv.RESTGESTOR}/loadfaq`, {
                  method: 'POST',
                  body: JSON.stringify({
                      codpredio: value,
                      pesq: pesq?? null       
                  }),
                  timeout: 5000,
                  headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      "x-access-token": user.token,
                  },
              })
              .then((response) => response.json())
              .then((responseJson) => {
                    if(responseJson.sucesso == true){   
                        setDadosFaq(responseJson.dados);
                        setLoading(false);
                        //console.log(responseJson.dados);
                    }else{
                        if(responseJson.dados[0])
                        Aviso("Error Faq:", `Faq ${responseJson.dados[0]?.message}`, "danger", 3000);  
                        setLoading(false);
                    }
              })
              .catch(function (error) {
    
                console.log(error);
                if(error.code ==='ERR_NETWORK'){
                  alert('Erro Comunicação com Servidor');
                  setLoading(false);
                  return
                }else{
                  Aviso("Error FAQ:", error.message, "danger", 3000); 
                  setLoading(false); 
                }                    
              })
              .finally(function () {setLoading(false);});
          } catch (err) {
              console.log(err);
              Aviso("Error FAQ:", err.message, "danger", 3000);
              setLoading(false);
          } finally {setLoading(false);}
        
    }

    
    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : 10 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{flexDirection:'row',width: Device.osName=='Windows' ? '100%' : 200, top: Device.osName=='Windows' ? null : 10,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <View>
                                    <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                        {`:: FAQ - ${user.predio} ::`}
                                    </Text>
                                </View>
                                <View style={{position:'absolute', marginLeft: 300}}>
                                    {user.superadmin==1 || user.admin==1 ?(
                                    <TouchableOpacity
                                        style={{ borderRadius: 5, height: 35,  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                                        onPress={() => {
                                            setModalVisible(!modalVisible);
                                        }}
                                        disabled={false}
                                        
                                    >               
                                        <View style={{flexDirection: 'row',}}>
                                            <Icon2 name="post-add" size={40} color="#004b9e" style={{padding: 1,}}/>                                        
                                        </View>
                                    </TouchableOpacity>
                                    ): null}
                                </View>
                                <View style={{position:'absolute', marginLeft: 800}}>
                                        <TextInput 
                                            label={'Pesquisa'}
                                            autoCorrect={false}
                                            //autoComplete={false}
                                            autoComplete="off"//DESATIVAR O AUTOCOMPLETE DO INPUT
                                            //placeholder={''}
                                            //style={{margin: 10}}
                                            mode='outlined'
                                            selectionColor={'#004b9e'}
                                            underlineColor={'#004b9e'}
                                            activeUnderlineColor={'#004b9e'}
                                            outlineColor={'#004b9e'}
                                            activeOutlineColor={'#004b9e'}
                                            textColor={'#004b9e'}
                                            dense={true}
                                            value={search}
                                            onChangeText={(text) => {setSearch(text)}}
                                            onSubmitEditing={()=> {procurarpesquisa()}}                        
                                            //left={<TextInput.Icon icon="text-search" disabled={true}/>}                                    
                                            right={
                                                <TextInput.Icon 
                                                    icon="search-web"
                                                    color='#004b9e'
                                                    onPress={()=>{procurarpesquisa();}}
                                                />}                                    
                                        />
                                </View>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', top: 5}}>
                    <Card style={styles.card}>
                        <View style={{flex:1, position:'relative',width:'100%', height:'100%', borderColor:'#004b9e', borderWidth: 1, borderRadius: 10}}>
                                <ScrollView style={{}}>
                                    <View style={{width: '83vw', height: '70vh', overflow:'hidden', flexWrap: 'wrap'}}>
                                        {loading ? (
                                            <View style={{position: 'relative', alignItems:'center', justifyContent:'center', width:'100%', height:'100%'}}>
                                                <ActivityIndicator size={50} color='#004b9e' />
                                            </View>
                                        ):(
                                        <FlatList
                                            data={dadosfaq}
                                            style={{ width: '100%', }}
                                            keyExtractor={(item) => item.id.toString()}
                                            showsVerticalScrollIndicator={true}
                                            renderItem={(item) => getresult(item)}
                                        />)} 
                                    </View>
                                </ScrollView>
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
        
        
        
            <Modal animationType='slide' transparent={true} visible={modalVisible}>
                <ScrollView>
                <Card style={[styles.card,{height: 250, width: '70vw', alignSelf:'center',/* marginVertical: '70%' */}]} elevation={5} mode={'elevated'}> 
                <Container>
                        <Form style={{width: '70vw' }}>
                            <Row>
                                <Form.Group as={Col}>
                                    <TextInput 
                                        label={'Pergunta'}
                                        autoCorrect={false}
                                        //autoComplete={false}
                                        autoComplete="off"//DESATIVAR O AUTOCOMPLETE DO INPUT
                                        //placeholder={''}
                                        style={{margin: 10}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={pergunta}
                                        onChangeText={(text) => {setPergunta(text)}}                        
                                        //left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                                    />
                                    <TextInput 
                                        label={'Resposta'}
                                        autoCorrect={false}
                                        //autoComplete={false}
                                        autoComplete="off"//DESATIVAR O AUTOCOMPLETE DO INPUT
                                        placeholder={''}
                                        style={{margin: 10, height: 100,}}
                                        mode='outlined'
                                        multiline={true}
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={resposta}
                                        onChangeText={(text) => {setResposta(text)}}                        
                                        //left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                                    />
                                </Form.Group> 
                            </Row> 
                            <Row>
                            <View style={{position: 'relative', alignItems:'center', justifyContent:'space-between', flexDirection:'row', width:'100vw'}}>
                            <Form.Group as={Col}>
                                <TouchableOpacity
                                        style={{ margin: 5,borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: 140, alignItems: 'center', justifyContent: 'center', marginLeft: 12 }}
                                        onPress={() => { salvar_faq();  }}
                                        >
                                        <View style={{flexDirection: 'row', }}>
                                            <Icon name="save" size={20} color="#FFD700" style={{ padding: 10,paddingLeft: 5}} />
                                            <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                            SALVAR
                                            </Text>
                                        </View>
                                </TouchableOpacity>                    
                            </Form.Group>  
                            <Form.Group as={Col}>
                                <TouchableOpacity
                                        style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: 140, alignItems: 'center', justifyContent: 'center', marginLeft: 12 }}
                                        onPress={() => { 
                                            setModalVisible(!modalVisible);                                                     
                                        }}
                                        >
                                        <View style={{flexDirection: 'row', }}>
                                            <Icon name="door-open" size={20} color="#FFD700" style={{ padding: 10,paddingLeft: 5}} />
                                            <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                            SAIR
                                            </Text>
                                        </View>
                                </TouchableOpacity>                    
                            </Form.Group> 
                            </View>
                            </Row>
                        </Form>
                </Container>
                </Card>
                </ScrollView>
            </Modal>
        <StatusBar style="auto" />
        </View>
        
    )
}
