import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import ReactDOM from 'react-dom'
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, ActivityIndicator, Modal, ScrollView } from "react-native";

import { Card, Checkbox, TextInput, RadioButton } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';

const {width, height} = Dimensions.get('screen');

//var mount = document.querySelectorAll('div.browser-mount');
export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso, iscoligada3, iscoligada5 } = useContext(UtilContext);
    const [loading, setLoading]=useState(false);
    const [loadingpwd, setLoadingPwd]=useState(false);
    const [dados, setDados]=useState([]);
    //const [ra, setRA]=useState('');
    const [ranome, setRANOME]=useState('');
    const [mesano, setMesAno]=useState('');
    //const [nome, setNome]=useState('');
    const [plativo, setPlAtivo]=useState(true);
    const [alativo, setAlAtivo]=useState(true);
    const [alnativo, setAlNAtivo]=useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [senhaold, setSenhaOld] = useState('');
    const [senhanew, setSenhaNew] = useState('');
    const [ConfSenhaNew, setConfSenhaNew] = useState('');
    const [raaltersenha, setRaAlterSenha]=useState('');
    const [nomealtersenha, setNomeAlterSenha]=useState('');
    const [checkedInst, setCheckedInst]=useState(false);
    const [checkedPMat, setCheckedPMat]=useState(false);
    const [checkedMat, setCheckedMat]=useState(true);
    const [checkedCan, setCheckedCan]=useState(false);
    const [checkedOu, setCheckedOu]=useState(false);
    const [turma, setTurma]=useState('');
    const [token, setToken]=useState(false);
    const [email, setEmail]=useState('');
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const [timeoutloading2, setTimeOutLoading2]=useState(100);
    const [timeoutloading3, setTimeOutLoading3]=useState(0);
    const tempotimeout = 77900;
    const [tempolimite, setTempoLimite] = useState('');
    


    const alterpwd = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Alterar Senha</Popover.Header>
          <Popover.Body>
            <strong>É obrigatório ter a senha antiga</strong>, para 
            que possa informar a senha nova.
          </Popover.Body>
        </Popover>
      );
    const recuperapwd = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Recuperar Senha</Popover.Header>
          <Popover.Body>
            <strong>É obrigatório ter o email do aluno cadastrado no RM</strong>, pois,
            será enviado um email com o TOKEN. Caso não tenha email ou
            email seja incorreto o aluno não receberá as orientações. 
          </Popover.Body>
        </Popover>
      );

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            document.title = dotenv.TITLE;
            //fetchData();
            /* setDados([]);
            setRANOME('');
            setTimeOutLoading(0);
            setTimeOutLoading2(100);
            setTimeOutLoading3(0);
            setTempoLimite('');
            let dataHoje = new Date();
            let filtropesq='';
            if(checkedInst!='')
                filtropesq= `'INSCRITO'`;
            if(checkedPMat!='')
                filtropesq= filtropesq=='' ? `'PRÉ-MATRICULADO'` : `${filtropesq},'PRÉ-MATRICULADO'`;
            if(checkedMat!='')
                filtropesq= filtropesq=='' ? `'MATRICULADO'` : `${filtropesq},'MATRICULADO'`;
            if(checkedCan!='')
                filtropesq= filtropesq=='' ? `'CANCELADO'` : `${filtropesq},'CANCELADO'`;
            if(checkedOu!='')
                filtropesq= filtropesq='OUTROS';
            fetchData({mesano: '',ra: '', nome: '', predio: user?.codpredio, filtro: filtropesq, turma: ''}); */
        }
    }, [focus]);

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(modalVisible == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            //setToken('0')
        }
    }, [modalVisible]);

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading){
            setTimeOutLoading(0);
            setTimeOutLoading2(100);
            setTimeOutLoading3(0);
            setTempoLimite('');
        }
    
        if(loading)
        setTimeout(() => {
            const tempoaut = parseInt(timeoutloading)+1;         
            let tempoaut2 = parseInt(timeoutloading3)+1000;         
            let ms = parseInt(tempotimeout) - parseInt(tempoaut2);
            let sec = Math.floor((ms / 1000) % 60);
            let min = Math.floor((ms / 1000 / 60) % 60);         
            let temp = (min < 10 ? '0' : '') + min + ":" + (sec < 10 ? '0' : '') + sec;                  
            setTimeOutLoading(   tempoaut    )
            setTimeOutLoading2(    /* Math.round( */convertRange( tempoaut, [ 0, tempotimeout/1000 ], [ 99, 0 ] )/* ) */        )
            setTimeOutLoading3(    tempoaut2     )
            setTempoLimite(temp);         
       }, 1000);
    }, [loading, timeoutloading]);
    
    

    function timeoutPromise(ms, promise) {
        return new Promise((resolve, reject) => {
          const timeoutId = setTimeout(() => {
            reject(new Error("Servidor não respondeu em tempo hábil"))
          }, ms);
          promise.then(
            (res) => {
              clearTimeout(timeoutId);
              resolve(res);
            },
            (err) => {
              clearTimeout(timeoutId);
              reject(err);
            }
          );
        })
      }

      function convertRange( value, r1, r2 ) { 
        return (( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ]).toFixed(1);
      }

    function formatCelular(v){
        if(v=='' ||v==null||v==undefined||v=='undefined')
        return '--'

        var r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 5) {
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
            r = r.replace(/^(\d*)/, "($1");
        }
        //setCelularUser(r);
        return r;
   }

   async function updatesenha(){    
        setLoadingPwd(true);
        
        await timeoutPromise(tempotimeout,fetch(`${dotenv.RESTGESTOR}/senha`, {
            method: 'POST',
            body: JSON.stringify({
                ra: raaltersenha?? '',
                pwdold: senhaold,
                pwdnew: senhanew,
                pwdconf: ConfSenhaNew,
                tiporeset: token?? '',
                email: email?? ''
            }),
            //timeout: 5000,
            redirect: 'follow',
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "x-access-token": user.token,
            },
        }))
        .then((response) => response.json())
        .then((responseJson) => {   
            
                if (responseJson.validtoken==false){
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## TOKEN - EXPIRADO ##', // This is a title
                        `${responseJson.message}`,
                        {
                            textConfirm: 'OK',      // Label of button confirm
                            textCancel: '',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { { 
                                signOut();
                            } } // Call your confirm function 
                        }
                    )
                    return
                }
                
                if(responseJson.sucesso == true){
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## SENHA ##', // This is a title
                        `${responseJson.message}`,
                        {
                            textConfirm: 'OK',      // Label of button confirm
                            textCancel: '',         // Label of button cancel
                            onCancel: () =>  { {} },// Call your cancel function 
                            onConfirm: () => { {} } // Call your confirm function 
                        }
                    )
                    setSenhaNew('');
                    setSenhaOld('');
                    setConfSenhaNew('');
                }else{
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## SENHA ##', // This is a title
                        `${responseJson.message}`,
                        {
                            textConfirm: 'OK',      // Label of button confirm
                            textCancel: '',         // Label of button cancel
                            onCancel: () =>  { {} },// Call your cancel function 
                            onConfirm: () => { {} } // Call your confirm function 
                        }
                    )  
                                          
                }
                setLoadingPwd(false);
                setModalVisible(!modalVisible);
        })
        .catch((error) => {
            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                '## TROCA SENHA ##', // This is a title
                `${error}`,
                {
                    textConfirm: 'OK',      // Label of button confirm
                    textCancel: '',         // Label of button cancel
                    onCancel: () =>  { {} },// Call your cancel function 
                    onConfirm: () => { {} } // Call your confirm function 
                }
            )
            setLoadingPwd(false);                                     
        })
        .finally(function () { setLoadingPwd(false);    });
    
   }

    async function fetchData(dados){

        
        if (iscoligada3 ==false && iscoligada5== false){
            Aviso("Error:", 'Selecione a Coligada', "danger", 3000); 
            return false;
        }
        
        setLoading(true);
        setDados([]);
        const dataHoje = new Date();
        const datapesq = mesano!='' ? dados.mesano.split('/') :  '';
        
        const ano = datapesq!='' ? datapesq[1] :  '';//dataHoje.getFullYear();
        const mes = datapesq!='' ? datapesq[0] :  '';//dataHoje.getMonth() + 1;
        if(datapesq==''){
                //setMesAno(`${('00'+(dataHoje.getMonth() + 1)).slice(-2)}/${dataHoje.getFullYear()}`);
        }
        //alert(`Mês: ${mes}, Ano: ${ano}, tamanho: ${datapesq.length},datapesq:${JSON.stringify(datapesq)} `);
        //return false;
        await timeoutPromise(tempotimeout,fetch(`${dotenv.RESTGESTOR}/dadosaluno`, {
                method: 'POST',
                body: JSON.stringify({
                    ano: ano,
                    mes: mes,
                    ra:dados.ra?? '',
                    predio: user.codpredio=='000' ? '' : user.codpredio,
                    nomepredio: user.predio,
                    nome: dados.nome?? '',
                    plativo: ((alativo==true && alnativo==true) || (alativo==false && alnativo==false)) ? 'S,N' : alativo==true ? 'S' : 'N',
                    filtro: dados.filtro?? '',
                    turma: dados.turma?? '',
                    coligada: (iscoligada3 && iscoligada5) ? 0 : iscoligada3 ? 3 : iscoligada5 ? 5 : 0
                }),
                //timeout: 5000,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-access-token": user.token,
                },
            }))
            .then((response) => response.json())
            .then((responseJson) => {   
                
                    if (responseJson.validtoken==false){
                        setLoading(false);
                        //alert(responseJson.message); 
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TOKEN - EXPIRADO ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        
                        //setLoading(-1);
                        //signOut();
                        return
                    }
                    
                    if(responseJson.sucesso == true){
                        //Aviso("", responseJson.message, "success", 3000); 
                        setDados(responseJson.dados);
                        setLoading(false);
                    }else{
                        Aviso("", responseJson.message, "danger", 3000); 
                        setLoading(false);                        
                    }
                    
            })
            .catch((error) => {
                setLoading(false);
                Aviso("Error:", error.message, "danger", 3000);  
                setEdtDesativar('');                                     
            })
            .finally(function () { }); 
    }    

    function filtroDadosAluno(){
        //const filtropesq = [];        
        
        /* if(ranome==''){
            alert('Informe o RA ou Parte do Nome');
            return false
        } */

        let filtropesq='';
        if(checkedInst!='')
            filtropesq= `'INSCRITO'`;
        if(checkedPMat!='')
            filtropesq= filtropesq=='' ? `'PRÉ-MATRICULADO'` : `${filtropesq},'PRÉ-MATRICULADO'`;
        if(checkedMat!='')
            filtropesq= filtropesq=='' ? `'MATRICULADO'` : `${filtropesq},'MATRICULADO'`;
        if(checkedCan!='')
            filtropesq= filtropesq=='' ? `'CANCELADO'` : `${filtropesq},'CANCELADO'`;
        if(checkedOu!='')
            filtropesq= filtropesq='OUTROS';

        if(filtropesq==''){
            alert('Selecione um dos filtros');
            return false;
        }

       /*  if(mesano==''){
            alert('Informe o Mês/Ano');
            return false;
        } */
        
        fetchData({mesano: mesano?? '', ra: !isNaN(ranome) ? ranome : '', nome:isNaN(ranome) ? ranome : '', predio: user?.codpredio, filtro: filtropesq?? '', turma: turma?? ''});
        
        
        
    }

    const imprimirlista = async () => {  
        
        let filtropesq='';
        if(checkedInst!='')
            filtropesq= `'INSCRITO'`;
        if(checkedPMat!='')
            filtropesq= filtropesq=='' ? `'PRÉ-MATRICULADO'` : `${filtropesq},'PRÉ-MATRICULADO'`;
        if(checkedMat!='')
            filtropesq= filtropesq=='' ? `'MATRICULADO'` : `${filtropesq},'MATRICULADO'`;
        if(checkedCan!='')
            filtropesq= filtropesq=='' ? `'CANCELADO'` : `${filtropesq},'CANCELADO'`;
        if(checkedOu!='')
            filtropesq= filtropesq='OUTROS';

        if(filtropesq==''){
            alert('Selecione um dos filtros');
            return false;
        }

        let dataHoje = new Date();
        let datapesq = mesano !='' ? mesano?.split('/') :  '';
        let ano = datapesq!='' ? datapesq[1] :  '';//dataHoje.getFullYear();
        let mes = datapesq!='' ? datapesq[0] :  '';//dataHoje.getMonth() + 1;
        let mesano2 = (ano!='' && mes!='') ? `${mes}/${ano}` : '';
        
        //alert(`${dotenv.SERVER_PHP}/relatoriopdf/dadosalunos.php?token=${user?.token}&ra=${!isNaN(ranome) ? ranome : ''}&predio=${user?.codpredio=='000' ? '' : user?.codpredio}&nome=${isNaN(ranome) ? ranome : ''}&plativo=${plativo==true ? 'S' : 'N'}&filtro=${filtropesq?? ''}&turma=${turma?? ''}&mesano=${mesano2}&coligada=${(iscoligada3 && iscoligada5) ? 0 : iscoligada3 ? 3 : iscoligada5 ? 5 : 0}`);
        //return false;
        
        //Linking.openURL(`${dotenv.SERVER_PHP}/relatoriopdf/dadosalunos.php?token=${user?.token}&ra=${!isNaN(ranome) ? ranome : ''}&predio=${user?.codpredio=='000' ? '' : user?.codpredio}&nome=${isNaN(ranome) ? ranome : ''}&plativo=${plativo==true ? 'S' : 'N'}&filtro=${filtropesq?? ''}&turma=${turma?? ''}&mesano=${mesano2}&coligada=${(iscoligada3 && iscoligada5) ? 0 : iscoligada3 ? 3 : iscoligada5 ? 5 : 0}`);   
        Linking.openURL(`${dotenv.SERVER_PHP}/relatoriopdf/dadosalunos.php?token=${user?.token}&ra=${!isNaN(ranome) ? ranome : ''}&predio=${user?.codpredio=='000' ? '' : user?.codpredio}&nome=${isNaN(ranome) ? ranome : ''}&plativo=${(alativo==true && alnativo==true) ? 'T' : (alativo==true && alnativo==false)  ? 'S' : (alativo==false && alnativo==true) ? 'N' : ''}&filtro=${filtropesq?? ''}&turma=${turma?? ''}&mesano=${mesano2}&coligada=${(iscoligada3 && iscoligada5) ? 0 : iscoligada3 ? 3 : iscoligada5 ? 5 : 0}`);   
            
        
    }

    function validarfiltroData(v){
        v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        setMesAno(v);
      }

    
    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5, right: 10 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',/* width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, */ top: Device.osName=='Windows' ? null : null ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{width: Device.osName=='Windows' ? '100%' : 200, top: Device.osName=='Windows' ? null : 1,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                    {`:: DADOS DO ALUNO ${iscoligada3 && iscoligada5 ? 'COLIGADA: 3 e 5' : iscoligada3 ? 'COLIGADA: 3' : iscoligada5 ? 'COLIGADA: 5' : ''} ::`}
                                </Text>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', /* top: 2 */}}>
                    <Card style={styles.card}>
                        <View style={{flex:1, position:'relative',width:'100%', height:'100%'}}>
                        <Container fluid className="Rules-Container">
                            <View style={{position:'relative',/* overflow:'scroll', */ height: Device.osName=='Windows'? 500 : 350, minWidth: '70vw', width:'83vw'}}>
                            <View style={{position:'relative', flexDirection: 'row', justifyContent:'space-between'}}>
                                     <Checkbox.Item 
                                        label="Inscrito" 
                                        //status="checked"
                                        status={checkedInst ? 'checked' : 'unchecked'}
                                        onPress={()=> {
                                            setCheckedInst(!checkedInst);
                                            setCheckedOu(false);
                                        }} 
                                    />
                                    <Checkbox.Item 
                                        label="Pré-Matriculado" 
                                        //status="checked"
                                        status={checkedPMat ? 'checked' : 'unchecked'}
                                        onPress={()=> {
                                            setCheckedPMat(!checkedPMat);
                                            setCheckedOu(false);
                                        }} 
                                    />
                                    <Checkbox.Item 
                                        label="Matriculado" 
                                        //status="checked"
                                        status={checkedMat ? 'checked' : 'unchecked'}
                                        onPress={()=> {
                                            setCheckedMat(!checkedMat);
                                            setCheckedOu(false);
                                        }} 
                                    />
                                    <Checkbox.Item 
                                        label="Cancelado" 
                                        //status="checked"
                                        status={checkedCan ? 'checked' : 'unchecked'}
                                        onPress={()=> {
                                            setCheckedCan(!checkedCan)
                                            setCheckedOu(false);
                                        }} 
                                    />
                                    <Checkbox.Item 
                                        label="Outros" 
                                        //status="checked"
                                        status={checkedOu ? 'checked' : 'unchecked'}
                                        onPress={()=> {
                                                setCheckedInst(false);
                                                setCheckedPMat(false);
                                                setCheckedMat(false);
                                                setCheckedCan(false);
                                                setCheckedOu(!checkedOu);
                                                //setPlAtivo(false);
                                            }} 
                                    /> 
                                    <Checkbox.Item 
                                            label="Ativo" 
                                            //status="checked"
                                            status={alativo ? 'checked' : 'unchecked'}
                                            onPress={()=> {
                                                //setPlAtivo(true);
                                                setAlAtivo(!alativo);
                                            }} 
                                        />
                                    <Checkbox.Item 
                                            label="Não Ativo" 
                                            //status="checked"
                                            status={alnativo ? 'checked' : 'unchecked'}
                                            onPress={()=> {
                                                //setPlAtivo(false);
                                                setAlNAtivo(!alnativo);
                                            }} 
                                        />                                   
                                </View>
                                <View style={{position:'relative', flexDirection: 'row',top: -10}}>                                     
                                    <TextInput 
                                        //label={'Ra / Parte Nome'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Ra / Parte Nome</Text>}
                                        autoCorrect={false}
                                        autoComplete={false}
                                        //placeholder={'Ex.: João Silva'}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        style={{margin: 1,marginRight:30}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={ranome}
                                        onChangeText={(text) => {setRANOME(text)}}       
                                        onSubmitEditing={()=>filtroDadosAluno()}                 
                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroDadosAluno()}} />} 
                                    />                                    
                                    <TextInput 
                                        //label={'Mês/Ano'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Mês/Ano</Text>}
                                        autoCorrect={false}
                                        autoComplete={false}
                                        //placeholder={'Ex.: João Silva'}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        style={{margin: 1,marginRight:30}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={mesano}
                                        onChangeText={(text) => {validarfiltroData(text)}}       
                                        onSubmitEditing={()=>filtroDadosAluno()}                 
                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroDadosAluno()}} />} 
                                    />                                    
                                    <TextInput 
                                        //label={'Turma'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Turma</Text>}
                                        autoCorrect={false}
                                        autoComplete={false}
                                        //placeholder={'Ex.: João Silva'}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        style={{margin: 1,marginRight:30}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={turma}
                                        onChangeText={(text) => {setTurma(text)}}       
                                        onSubmitEditing={()=>filtroDadosAluno()}                 
                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroDadosAluno()}} />} 
                                    />
                                    <TouchableOpacity
                                        style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 50, alignItems: 'center', justifyContent: 'center', top: 10, marginLeft: 100 }}
                                        onPress={() => {
                                            imprimirlista();
                                        }}
                                        disabled={false}
                                    >               
                                        <View style={{/* width: 50, */flexDirection: 'row',}}>
                                            <Icon name="print" size={20} color="#FFD700" style={{padding:  3,}}/>    
                                        </View>
                                    </TouchableOpacity>                                   
                                    {/* <TextInput 
                                        label={'NOME'}
                                        autoCorrect={false}
                                        autoComplete={false}
                                        //placeholder={'Ex.: João Silva'}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        style={{margin: 1}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={nome}
                                        onChangeText={(text) => {setNome(text)}}       
                                        onSubmitEditing={()=>filtroDadosAluno()}                 
                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroDadosAluno()}} />} 
                                    /> */}
                                                                      
                                </View>
                            {loading ? (
                                    <>    
                                    <ActivityIndicator size={50} color='#004b9e' />
                                    {timeoutloading2 <= 33 ? (<ProgressBar variant="danger" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    {timeoutloading2 > 33 && timeoutloading2 <= 66  ? (<ProgressBar variant="warning" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    {timeoutloading2 > 66 ? (<ProgressBar variant="success" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    </>
                                    ):null}
                                    <Table striped bordered hover /* size="sm" fixed="top" */ responsive>
                                        <thead style={{position: 'sticky',top: '0px',backgroundColor:'#FFF', zIndex: 100,}}>
                                            <tr>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>#</Text></th> */}
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>PREDIO</Text></th> */}
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>COLIGADA</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>TURNO</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>TURMA</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>RA</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>DT MATRIC.</Text></th>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>COD.CFO</Text></th>*/}
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>NOME</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>CPF</Text></th> 
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>DATA NASC.</Text></th>
                                                <th colSpan={2}><Text style={{fontSize: 13, fontWeight:'bold'}}>ENDEREÇO</Text></th>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>CPF</Text></th> */}
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>TELEFONE</Text></th>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>TELEFONE2</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>TELEFONE3</Text></th> */}
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>EMAIL</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>STATUS</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>ATIVO</Text></th>
                                                <th colSpan={2}><Text style={{fontSize: 13, fontWeight:'bold', alignSelf:'center'}}>SENHA</Text></th>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>RECUPERA SENHA</Text></th> */}
                                            </tr>
                                        </thead>
                                        
                                        <tbody style={{position:'relative', zIndex: 10}}>  
                                        {   
                                                dados?.map(obj => (
                                                    
                                                <tr key={obj.ITEM.toString()}>                                                    
                                                    {/* <td><Text style={{fontSize: 10}}>{obj.ITEM}</Text></td> */}
                                                    {/* <td><Text style={{fontSize: 10}}>{obj.PREDIO}</Text></td> */}
                                                    <td><Text style={{fontSize: 10}}>{obj.COLIGADA}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.TURNO.slice(0,5)}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.TURMA}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.RA}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.DTMATRICULA}</Text></td>
                                                    {/* <td>{obj.CODCFO}</td>                                                    
                                                    <td>{obj.CODPESSOA}</td> */}                                                    
                                                    <td><Text style={{fontSize: 10}}>{obj.NOME}</Text></td>                                                    
                                                    <td><Text style={{fontSize: 10}}>{obj.CPF}</Text></td>                                                    
                                                    <td><Text style={{fontSize: 10}}>{obj.DTNASCIMENTO}</Text></td>                                                    
                                                    <td colSpan={2}><Text style={{fontSize: 10}}>{obj.ENDERECO}</Text></td>                                                    
                                                    {/* <td><Text style={{fontSize: 10}}>{obj.CPF}</Text></td>  */}                                                   
                                                    <td><Text style={{fontSize: 10}}>{obj.TELEFONE1!=null?obj.TELEFONE1+', ':''}{obj.TELEFONE2!=null?obj.TELEFONE2+', ':''}{obj.TELEFONE3!=null?obj.TELEFONE3 :''}</Text></td>                                                    
                                                    {/* <td><Text style={{fontSize: 10}}>{obj.TELEFONE2?? '--'}</Text></td>                                                    
                                                    <td><Text style={{fontSize: 10}}>{obj.TELEFONE3?? '--'}</Text></td>  */}                                                   
                                                    <td><Text style={{fontSize: 10,}}>{obj.EMAIL}</Text></td>                                                    
                                                    <td><Text style={{fontSize: 10, maxWidth: 30}}>{obj.STATUSALUNO}</Text></td>                                                    
                                                    <td><View style={{ }}><Text style={{fontSize: 10, color: obj.PLATIVO=='NÃO' ? 'red' : null,}}><strong>{obj.PLATIVO}</strong></Text></View></td>                                                    
                                                    <td>
                                                        <TouchableOpacity
                                                            style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}
                                                            onPress={() => {
                                                                
                                                                    /* setRaAlterSenha(obj.RA);
                                                                    setNomeAlterSenha(obj.NOME);
                                                                    setEmail('');
                                                                    setToken('2');
                                                                    setModalVisible(!modalVisible); */

                                                                    alert(`Oriente o aluno a ir no site:\n\nhttps://trocasenha.literatus.edu.br/\n\ne efetuar a troca/recuperação de senha.`);
                                                                
                                                            }}
                                                            disabled={false}
                                                        >               
                                                            <View style={{width: '100%',flexDirection: 'row',}}>
                                                            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="auto" overlay={alterpwd}>
                                                                <Button variant=""><Icon name="user-lock" size={20} color={obj.PLATIVO=='NÃO' ? "#A9A9A9" : "#004b9e"} /></Button>
                                                            </OverlayTrigger>
                                                                
                                                            </View>
                                                        </TouchableOpacity>                                                        
                                                    </td>                                                    
                                                    <td>
                                                        <TouchableOpacity
                                                            style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}
                                                            onPress={() => {
                                                                
                                                                /* setRaAlterSenha(obj.RA);
                                                                setNomeAlterSenha(obj.NOME);
                                                                setEmail(obj.EMAIL);
                                                                setToken('0');
                                                                setModalVisible(!modalVisible); */
                                                                alert(`Oriente o aluno a ir no site:\n\nhttps://trocasenha.literatus.edu.br/\n\ne efetuar a troca/recuperação de senha.`);
                                                                
                                                            }}
                                                            disabled={false}
                                                        >               
                                                            <View style={{width: '100%',flexDirection: 'row',}}>
                                                            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="auto" overlay={recuperapwd}>
                                                                <Button variant=""><Icon2 name="email-lock" size={23} color={obj.PLATIVO=='NÃO' ? "#A9A9A9" : "#004b9e"} /></Button>
                                                            </OverlayTrigger>                                                                
                                                            </View>
                                                        </TouchableOpacity>                                                        
                                                    </td>                                                    
                                                </tr>))
                                                
                                            }               
                                        </tbody>
                                    </Table>
                            </View>
                                
                        </Container>
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
            <Modal animationType='slide' transparent={true} visible={modalVisible}>
                <ScrollView>
                <Card style={[styles.card,{height: 350, width: '30vw', alignSelf:'center',/* marginVertical: '70%' */}]} elevation={5} mode={'elevated'}> 
                <Container>
                        <Form style={{width: '30vw', }}>
                            <Row>
                                <Form.Group as={Col}>
                                    <Text style={{marginLeft: 10}}>{`RA: ${raaltersenha} NOME: ${nomealtersenha}`}</Text>
                                    {token!='2' ? (
                                        <View style={{flexDirection: 'row'}}>
                                                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'flex-start', width: 150, marginLeft: 20}}>
                                                    <Text>Enviar Email</Text>
                                                    <RadioButton
                                                        value={'0'}
                                                        status={ token === '0' ? 'checked' : 'unchecked' }
                                                        onPress={() => setToken('0')}
                                                    />
                                                </View>
                                                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'flex-start', width: 150, marginLeft: 50}}>
                                                    <Text>Já recebi o Token</Text>
                                                    <RadioButton
                                                        value={'1'}
                                                        status={ token === '1' ? 'checked' : 'unchecked' }
                                                        onPress={() => setToken('1')}
                                                    />
                                                </View>
                                        </View>
                                    ):null}
                                    {token=='1' || token=='2' ? (  
                                    <>                                 
                                    <TextInput 
                                        //label={token=='2' ? 'Senha Antiga' : 'Informe o Token'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>{token=='2' ? 'Senha Antiga' : 'Informe o Token'}</Text>}
                                        autoCorrect={false}
                                        //autoComplete={false}
                                        autoComplete="off"//DESATIVAR O AUTOCOMPLETE DO INPUT
                                        //placeholder={''}
                                        style={{margin: 10}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        multiline={token == '1' ? true : false}
                                        numberOfLines={token == '1' ? 3 : 1}
                                        secureTextEntry={true}
                                        value={senhaold}
                                        onChangeText={(text) => {setSenhaOld(text)}}                        
                                        //left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                                    />
                                    <TextInput 
                                        //label={'Senha Nova'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Senha Nova</Text>}
                                        autoCorrect={false}
                                        //autoComplete={false}
                                        autoComplete="off"//DESATIVAR O AUTOCOMPLETE DO INPUT
                                        placeholder={''}
                                        style={{margin: 10}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        secureTextEntry={true}
                                        value={senhanew}
                                        onChangeText={(text) => {setSenhaNew(text)}}                        
                                        //left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                                    />
                                    <TextInput 
                                        //label={'Confirma Nova Senha'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Confirma Nova Senha</Text>}
                                        autoCorrect={false}
                                        //autoComplete={false}
                                        autoComplete="off"//DESATIVAR O AUTOCOMPLETE DO INPUT
                                        placeholder={''}
                                        style={{margin: 10, marginBottom: 10}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        secureTextEntry={true}
                                        value={ConfSenhaNew}
                                        onChangeText={(text) => {setConfSenhaNew(text)}}                        
                                        //left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                                    />
                                    </>
                                    ) : <View style={{width:'100%'}}><Text style={{textAlign:'center', fontWeight:'bold', paddingTop: 60}}>{`Será enviado um email para ${email}\ncom o Token, assim que receber informe que já recebeu no formulário.`}</Text></View>}
                                </Form.Group> 
                            </Row> 
                            <Row>
                            <View style={{position: 'relative', alignItems:'center', justifyContent:'space-between', flexDirection:'row', width:'100vw', marginBottom:10, marginTop: token=='0' ? 55 : null}}>
                            <Form.Group as={Col}>
                                <TouchableOpacity
                                        style={{ margin: 5,borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: 140, alignItems: 'center', justifyContent: 'center', marginLeft: 12 }}
                                        onPress={() => { 
                                            if((senhanew=='' ||senhaold==''||ConfSenhaNew=='') && token!='0'){
                                                Aviso("Error:", 'Campos Obrigatórios!', "danger", 3000);  
                                                return false;
                                            }
                                            if((senhanew !== ConfSenhaNew) && token != '0'){
                                                setModalVisible(false);
                                                alert('Nova  Senha e Confirmação não são iguais!');
                                                return false;
                                            }

                                            if(senhaold.length <= 115 && token=='1'){
                                                alert('Token incorreto! ');
                                                setModalVisible(false);
                                                return false
                                            }
                                            
                                            updatesenha();  
                                        }}
                                        >
                                        <View style={{flexDirection: 'row', }}>
                                            
                                            {loadingpwd ? (
                                                <ActivityIndicator size={20} color='#FFF' />
                                            ):(
                                                <Icon2 name={token=='0' ? "email-fast" : "content-save-move"} size={20} color="#FFD700" style={{ padding: 10,paddingLeft: 5}} />
                                            )}
                                            <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                            {token=='0' ? 'ENVIAR' : 'ALTERAR'}
                                            </Text>
                                        </View>
                                </TouchableOpacity>                    
                            </Form.Group>  
                            <Form.Group as={Col}>
                                <TouchableOpacity
                                        style={{ borderRadius: 5, backgroundColor: '#004b9e', height: 35, width: 140, alignItems: 'center', justifyContent: 'center', marginLeft: 12 }}
                                        onPress={() => { 
                                            setRaAlterSenha('');
                                            setNomeAlterSenha('');
                                            setSenhaNew('');
                                            setSenhaOld('');
                                            setConfSenhaNew('');
                                            setModalVisible(!modalVisible);                                                     
                                        }}
                                        >
                                        <View style={{flexDirection: 'row',}}>
                                            <Icon name="door-open" size={20} color="#FFD700" style={{ padding: 10,paddingLeft: 5}} />
                                            <Text style={{ padding: Device.osName=='Windows'? 10: 3, fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFFF', fontWeight: 'bold' }} >
                                            SAIR
                                            </Text>
                                        </View>
                                </TouchableOpacity>                    
                            </Form.Group> 
                            </View>
                            </Row>
                        </Form>
                </Container>
                </Card>
                </ScrollView>
            </Modal>
        <StatusBar style="auto" />
        </View>
    )
}
