import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import ReactDOM from 'react-dom'
import { Text, View, Dimensions, TouchableOpacity, Linking, Image } from "react-native";

import { Card } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';




const {width, height} = Dimensions.get('screen');

//var mount = document.querySelectorAll('div.browser-mount');
export default function App({navigation}) {
    const { user } = useContext(AuthContext);
    const { ismenu, setIsMenu } = useContext(UtilContext);
    const [url, setUrl]=useState('');
    const [random, setRandom]=useState('');

    /* useEffect(() => { 
        
    }, []); */

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            document.title = dotenv.TITLE;
            //console.log(`${dotenv.SERVER_PHP}/filebrowser/?directory=institucional&token=${user?.token}`);
            let data = new Date();
            setRandom(data);
            setUrl(`${dotenv.SERVER_PHP}/filebrowser/?directory=institucional&token=${user?.token}`);    
        }
    }, [focus]);

    
    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5, right:10 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : 10 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{width: Device.osName=='Windows' ? '100%' : 350, top: Device.osName=='Windows' ? null : -20,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                    :: POLÍTICAS INSTITUCIONAIS ::
                                </Text>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', top: 5}}>
                    <Card style={styles.card}>
                        <View style={{flex:1, position:'relative',width:'100%', height:'100%'}}>
                        <iframe
                            key={random}
                            src={url}
                            width={width * 0.8}
                            height={height* 0.64}
                        ></iframe>
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
        <StatusBar style="auto" />
        </View>
    )
}
