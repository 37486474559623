import React, { createContext, useState } from 'react';
import { showMessage } from "react-native-flash-message";
import Icon from 'react-native-vector-icons/FontAwesome5';

export const UtilContext = createContext({});

function UtilProvider({ children }) {
    const [ismenu, setIsMenu] = useState(false);
    const [iscoligada3, setIsColigada3] = useState(true);
    const [iscoligada5, setIsColigada5] = useState(true);
    /* 
          AO CRIAR NOVO PERFIL SE ATENTAR
          1 - CRIAR O DIRETÓRIO
              LISTAS É A DE BAIXO - IGNORA ESSAS ETAPAS NAS TELAS
              2.1 - INCLUIR NA LISTA DE CADASTRO DE LOGIN
              3.2 - INCLUIR NA CONSULTA DE LOGIN
          4 - INCLUIR NO support\file-manager.js - BLOQUEAR EXCLUIR DIRETÓRIO
          5 - CADASTRAR TEXTINPUT E INCLUIR OS CODPREDIO NO MYSQL
          6 - INCLUIR NA TELA FAQ O NOVO NOME 
          7 - INCLUIR NA LISTA / MENU ALTERAR PREDIO
    */
    const Perfil = [
      {id: 1, title:'000-TODOS', name: 'TODOS', codcoligada: 3, codfilial: 1, codpredio: '000'},
      {id: 2, title:'001-SEDE PARA', name: 'SEDE PARA', codcoligada: 3, codfilial: 1, codpredio: '001'},
      {id: 3, title:'002-SEDE DJALMA', name: 'SEDE DJALMA', codcoligada: 3, codfilial: 1, codpredio: '002'},
      {id: 4, title:'003-SEDE PARAIBA', name: 'SEDE PARAIBA', codcoligada: 3, codfilial: 1, codpredio: '003'},
      {id: 5, title:'004-SEDE GRANDE CIRCULAR', name: 'SEDE GRANDE CIRCULAR', codcoligada: 3, codfilial: 1, codpredio: '004'},
      {id: 6, title:'005-POLO CENTRO', name: 'POLO CENTRO', codcoligada: 3, codfilial: 1, codpredio: '005'},
      {id: 7, title:'006-POLO TABATINGA', name: 'POLO TABATINGA', codcoligada: 3, codfilial: 1, codpredio: '006'},
      {id: 8, title:'007-POLO TEFÉ', name: 'POLO TEFE', codcoligada: 3, codfilial: 1, codpredio: '007'},
      {id: 9, title:'008-PRESIDENTE FIGUEIREDO', name: 'POLO PRESIDENTE FIGUEIREDO', codcoligada: 3, codfilial: 1, codpredio: '008'},
      {id: 10, title:'009-POLO IRANDUBA', name: 'POLO IRANDUBA', codcoligada: 3, codfilial: 1, codpredio: '009'},
    ]

    
    function Aviso(titulo, mensagem, tipo, tempo) {
      let tempodelay = tempo?? 8000;
      showMessage({
        //message: `<- Clique para Fechar`,
        message: `${titulo}`,
        description: `\n${mensagem}`,
        type: `${tipo}`,
        duration: tempodelay,
        //autoHide: false,
        titleStyle: { fontSize: 20, },
        textStyle: { fontSize: 18, fontWeight: 'bold' },
        //icon: props => <Image style={{resizeMode: 'stretch', width: 200, height: 200}} source={require('../../assets/icon6.png')} {...props} />,
        icon: props => <Icon name="window-close" size={20} color="#FFF" {...props} />,
        onPress: () => {
          /* THIS FUNC/CB WILL BE CALLED AFTER MESSAGE PRESS */
          //alert('OK');
        },
      });
  
    }

  return (
    //Exportar funcão para os filhos
    <UtilContext.Provider value={{ ismenu, setIsMenu, Aviso, Perfil, iscoligada3, setIsColigada3, iscoligada5, setIsColigada5  }}>
      {children}
    </UtilContext.Provider>
  )
}

export default UtilProvider