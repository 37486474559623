import AsyncStorage from '@react-native-async-storage/async-storage'
import React, {useState, createContext} from 'react'

export const AuthContext = createContext({})

function AuthProvider({ children }){
    const [user, setUser] = useState(null);  
    const [alterperfil, setAlterPerfil] = useState(false); 
    
    //async function signUp(dados, coligada, filial, token){
    async function signUp(nome,superadmin,admin,gestor,administrativo,foto,coligada,filial,predio,codpredio,ativo,trocasenha, token, alterperfil2){

        if(token == '' || token == undefined){
            alert(`Ocorreu um erro na autenticação, aguarde um pouco e tente novamente.`);
            signOut();
            return false;
        }

        let timeatual = new Date();
        timeatual.setHours(timeatual.getHours() + 4); 

        if(codpredio=='000')
            setAlterPerfil(true);
            
        let data = {
            nome: nome,
            superadmin: superadmin==1 ? true : false,
            admin: admin==1 ? true : false,
            gestor: gestor==1 ? true : false,
            administrativo: administrativo==1 ? true : false,
            foto: foto,
            coligada: coligada,
            filial: filial,
            predio: predio,
            codpredio: codpredio,
            ativo: ativo,
            trocasenha: trocasenha,
            token: token,
            expired: timeatual, 
            alterperfil: alterperfil2,    
        }
        //console.log("UserNovo: "+JSON.stringify(data));
        setUser(data)            
        AsyncStorage.setItem('bdlogingestor',JSON.stringify(data)) 
          
    }        

    function signOut(){
        setAlterPerfil(false);
        AsyncStorage.removeItem('bdlogingestor');
        AsyncStorage.removeItem('bddadoslogingestor');
        setUser(null);
        AsyncStorage.clear();     
        window.location.reload(false);         
    }


    return(
        <AuthContext.Provider value={{ signed: !!user , user, signUp, signOut, alterperfil, setAlterPerfil}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider