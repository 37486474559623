import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, ActivityIndicator } from "react-native";

import { Card, TextInput } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import validator from 'validator';


import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

const {width, height} = Dimensions.get('screen');

//var mount = document.querySelectorAll('div.browser-mount');
export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso } = useContext(UtilContext);
    const [prediotodos, setPredioTodos]=useState('');
    const [predio001, setPredio001]=useState('');
    const [predio002, setPredio002]=useState('');
    const [predio003, setPredio003]=useState('');
    const [predio004, setPredio004]=useState('');
    const [predio005, setPredio005]=useState('');
    const [predio006, setPredio006]=useState('');
    const [predio007, setPredio007]=useState('');
    const [predio008, setPredio008]=useState('');
    const [predio009, setPredio009]=useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => { 
        document.title = dotenv.TITLE;
        fetchData();
    }, []);

    async function fetchData(){
        try {
            await fetch(`${dotenv.RESTGESTOR}/loaddashboard`, {
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    //console.log(responseJson.dados);

                    if (responseJson.validtoken==false){
                        //alert(responseJson.message); 
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TOKEN - EXPIRADO ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        
                        //setLoading(-1);
                        //signOut();
                        return
                    }

                    responseJson.dados.map((obj) => {
                        if(obj.id == 1)
                        setPredioTodos(obj.url);
                        if(obj.id == 2)
                        setPredio001(obj.url);
                        if(obj.id == 3)
                        setPredio002(obj.url);
                        if(obj.id == 4)
                        setPredio003(obj.url);                          
                        if(obj.id == 5)
                        setPredio004(obj.url);                          
                        if(obj.id == 6)
                        setPredio005(obj.url);                          
                        if(obj.id == 7)
                        setPredio006(obj.url);                          
                        if(obj.id == 8)
                        setPredio007(obj.url);                          
                    })

                    //setListUser(responseJson.dados);
                    
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                })
                .finally(function () {});  
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000);          //setLoadingFaltas(false); 
        } finally {{}}
    }

    async function salvarDashBoard(dados){
        try {           
          setLoading(true);
          await fetch(`${dotenv.RESTGESTOR}/savedashboard`, {
                    method: 'POST',
                    body: JSON.stringify({
                        dados: dados
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                    setLoading(false);
                    
                    if(responseJson.sucesso == true){
                        Aviso("", responseJson.message, "success", 3000);                           
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);                         
                    }
              })
              .catch((error) => {
                  setLoading(false);
                  Aviso("Error:", error.message, "danger", 3000);                                       
              })
              .finally(function () { setLoading(false); });  
            
        } catch (err) {
            setLoading(false);
            Aviso("Error:", err.message, "danger", 3000);
            
        } finally {{setLoading(false);}}
    }

    
    return (
        <View style={[styles.container,{overflow:'hidden'}]}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '99%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5, justifyContent:'flex-end', right:10 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',/* width: Device.osName=='Windows' ? width*0.80 : ismenu ? width*0.50 : width*0.50, */ top: Device.osName=='Windows' ? null : null ,flexDirection:'row', alignItems: 'center', /* justifyContent:'space-between', */}}>
                            <View style={{/* width: Device.osName=='Windows' ? '100%' : 350, */ top: Device.osName=='Windows' ? null : 1,padding:Device.osName=='Windows'?30: null,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                    :: CADASTRO DASHBOARD - PREDIOS ::
                                </Text>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '99%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', top: 5, overflow:'hidden'}}>
                    <Card style={[styles.card,{overflow:'auto'}]}>
                    <Container fluid="md">
                        <Form style={{height: '90vh'}}>                            
                            <Row xs="auto">
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'000-TODOS'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>000-TODOS</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={prediotodos}
                                    onChangeText={(text) => {setPredioTodos(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                            
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'001-SEDE PARA'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>001-SEDE PARA</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio001}
                                    onChangeText={(text) => {setPredio001(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                            
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'002-SEDE DJALMA'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>002-SEDE DJALMA</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio002}
                                    onChangeText={(text) => {setPredio002(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                            
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'003-SEDE PARAIBA'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>003-SEDE PARAIBA</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio003}
                                    onChangeText={(text) => {setPredio003(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                            
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'004-SEDE GRANDE CIRCULAR'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>004-SEDE GRANDE CIRCULAR</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio004}
                                    onChangeText={(text) => {setPredio004(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                            
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'005-POLO CENTRO'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>005-POLO CENTRO</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio005}
                                    onChangeText={(text) => {setPredio005(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>

                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'006-POLO TABATINGA'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>006-POLO TABATINGA</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio006}
                                    onChangeText={(text) => {setPredio006(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'007-POLO TEFÉ'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>007-POLO TEFÉ</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio007}
                                    onChangeText={(text) => {setPredio007(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'008-POLO P. FIGUEIREDO'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>008-POLO PRES. FIGUEIREDO</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio008}
                                    onChangeText={(text) => {setPredio008(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>
                                <Form.Group as={Col} xs="md-8">
                                <TextInput 
                                    //label={'009-POLO IRANDUBA'}
                                    label={<Text style={{backgroundColor:"#FFF", padding: 5}}>009-POLO IRANDUBA</Text>}
                                    autoCorrect={false}
                                    autoComplete={false}
                                    style={{margin: 10}}
                                    mode='outlined'
                                    selectionColor={'#004b9e'}
                                    underlineColor={'#004b9e'}
                                    activeUnderlineColor={'#004b9e'}
                                    outlineColor={'#004b9e'}
                                    activeOutlineColor={'#004b9e'}
                                    textColor={'#004b9e'}
                                    dense={true}
                                    value={predio009}
                                    onChangeText={(text) => {setPredio009(text)}}                        
                                    left={<TextInput.Icon icon="web" disabled={true}/>}                                    
                                />
                                </Form.Group>

                            </Row>  

                            <Form.Group as={Col} style={{zIndex: -10, margin: 5, paddingBottom: 30}}>
                                    <TouchableOpacity
                                        onPress={()=>{
                                            if (!validator.isURL(prediotodos) && prediotodos != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 000 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            if (!validator.isURL(predio001) && predio001 != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 001 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            if (!validator.isURL(predio002) && predio002 != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 002 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            if (!validator.isURL(predio003) && predio003 != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 003 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            if (!validator.isURL(predio004) && predio004 != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 004 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            if (!validator.isURL(predio005) && predio005 != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 005 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            if (!validator.isURL(predio006) && predio006 != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 006 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            if (!validator.isURL(predio007) && predio007 != '') {
                                                Aviso("DashBoard", 'URL DashBoard Prédio 007 Inválido', "danger", 3000);  
                                                return false;
                                            } 
                                            const dadosUrlDashBoard = [
                                                {id: 1, codpredio: "000", url: prediotodos},
                                                {id: 2, codpredio: "001", url: predio001},
                                                {id: 3, codpredio: "002", url: predio002},
                                                {id: 4, codpredio: "003", url: predio003},
                                                {id: 5, codpredio: "004", url: predio004},
                                                {id: 6, codpredio: "005", url: predio005},
                                                {id: 7, codpredio: "006", url: predio006},
                                                {id: 8, codpredio: "007", url: predio007},
                                                {id: 9, codpredio: "008", url: predio008},
                                                {id: 10, codpredio: "009", url: predio009}
                                            ];

                                            salvarDashBoard(dadosUrlDashBoard);

                                        }}
                                        style={{backgroundColor:'#004b9e', width: Device.osName=='Windows' ? 130 : 100, height: Device.osName=='Windows' ? 40 : 30, alignItems:'centrer', justifyContent:'center', borderRadius:10,}}
                                    >
                                        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                            {loading ? (
                                                <ActivityIndicator size={25} style={{position:'relative',marginRight: 20}} color='#eee' />
                                            ):(
                                                <Icon name="save" size={20} color="#FFD700" style={{marginRight: 20}}/>
                                            )}

                                            <Text style={{textAlign:'center', fontSize: Device.osName=='Windows' ? 15 : 13, fontWeight:'bold', color:'#FFF'}}>SALVAR</Text>
                                        </View>
                                    </TouchableOpacity>                    
                            </Form.Group>         
                        </Form>
                </Container>
                    </Card>
                </View>
                <View style={{width: '99%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
        <StatusBar style="auto" />
        </View>
    )
}
