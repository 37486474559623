import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import ReactDOM from 'react-dom'
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, ActivityIndicator, Modal, ScrollView } from "react-native";

import { Card, Checkbox, TextInput } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';


const {width, height} = Dimensions.get('screen');

//var mount = document.querySelectorAll('div.browser-mount');
export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu } = useContext(UtilContext);
    const [loading, setLoading]=useState(false);
    const [dados, setDados]=useState([]);
    const [checkedInst, setCheckedInst]=useState(false);
    const [checkedPMat, setCheckedPMat]=useState(false);
    const [checkedMat, setCheckedMat]=useState(false);
    const [checkedCan, setCheckedCan]=useState(false);
    const [checkedOu, setCheckedOu]=useState(false);
    const [mesano, setMesAno]=useState('');
    const [modalVisible, setModalVisible] = useState(false);

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            document.title = dotenv.TITLE;
            //fetchData();
            setDados([]);
            setCheckedInst(false);
            setCheckedPMat(false);
            setCheckedMat(false);
            setCheckedCan(false);
            setCheckedOu(false);
            setMesAno('');

        }
    }, [focus]);

    async function fetchData(dados){
        
        if(!dados)
            return false;
        //alert(JSON.stringify(dados));
        //return false;
        setLoading(true);
        setDados([]);
        setModalVisible(false);
        await fetch(`${dotenv.RESTGESTOR}/procseletivo`, {
                method: 'POST',
                body: JSON.stringify({
                    ano:dados.ano,
                    mes:dados.mes,
                    predio:dados.predio,
                    filtro: dados.filtro//"'MATRICULADO','INSCRITO', 'CANCELADO','PRÉ-MATRICULADO'"
                }),
                timeout: 5000,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-access-token": user.token,
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {     
                
                if (responseJson.validtoken==false){
                    //alert(responseJson.message); 
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## TOKEN - EXPIRADO ##', // This is a title
                        `${responseJson.message}`,
                        {
                            textConfirm: 'OK',      // Label of button confirm
                            textCancel: '',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { { 
                                signOut();
                            } } // Call your confirm function 
                        }
                    )
                    
                    //setLoading(-1);
                    //signOut();
                    return
                }
                    
                    if(responseJson.sucesso == true){
                        //Aviso("", responseJson.message, "success", 3000); 
                        setDados(responseJson.dados);
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);                         
                    }
                    
            })
            .catch((error) => {
                Aviso("Error:", error.message, "danger", 3000);  
                setEdtDesativar('');                                     
            })
            .finally(function () { setLoading(false); setModalVisible(!modalVisible)}); 
    }

    function validarfiltroData(v){
        v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        setMesAno(v);
      }

    function filtroProcSeletivo(){
        //const filtropesq = [];
        
        if(mesano==''){
         alert('Informe mês e ano');
         return false;
        }
        
        
        let filtropesq='';
        if(checkedInst!='')
            filtropesq= `'INSCRITO'`;
        if(checkedPMat!='')
            filtropesq= filtropesq=='' ? `'PRÉ-MATRICULADO'` : `${filtropesq},'PRÉ-MATRICULADO'`;
        if(checkedMat!='')
            filtropesq= filtropesq=='' ? `'MATRICULADO'` : `${filtropesq},'MATRICULADO'`;
        if(checkedCan!='')
            filtropesq= filtropesq=='' ? `'CANCELADO'` : `${filtropesq},'CANCELADO'`;
        if(checkedOu!='')
            filtropesq= filtropesq='OUTROS';

        //salert(JSON.stringify(filtropesq));

        if(filtropesq==''){
            alert('Selecione um dos filtros');
            return false;
        }


        let data = mesano.split('/')
        let search = {
            mes: data[0],
            ano: data[1],
            predio: user.codpredio=='000' ? '' : user.codpredio,
            filtro: filtropesq
        }

        //alert(JSON.stringify(search));
        fetchData(search);

    }

    const imprimirlista = async () => {  
        
        if(mesano==''){
            alert('Informe mês e ano');
            return false;
           }
           
           
           let filtropesq='';
           if(checkedInst!='')
               filtropesq= `'INSCRITO'`;
           if(checkedPMat!='')
               filtropesq= filtropesq=='' ? `'PRÉ-MATRICULADO'` : `${filtropesq},'PRÉ-MATRICULADO'`;
           if(checkedMat!='')
               filtropesq= filtropesq=='' ? `'MATRICULADO'` : `${filtropesq},'MATRICULADO'`;
           if(checkedCan!='')
               filtropesq= filtropesq=='' ? `'CANCELADO'` : `${filtropesq},'CANCELADO'`;
           if(checkedOu!='')
               filtropesq= filtropesq='OUTROS';
   
           let data = mesano.split('/');
   
           if(filtropesq==''){
               alert('Selecione um dos filtros');
               return false;
           }
        
        Linking.openURL(`${dotenv.SERVER_PHP}/relatoriopdf/procseletivogestor.php?token=${user?.token}&mes=${data[0]}&ano=${data[1]}&predio=${user.codpredio=='000' ? '' : user.codpredio}&filtro=${filtropesq}`);   
            
        
    }

    
    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ? 80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5, right: 10 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : 10 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{width: Device.osName=='Windows' ? '100%' : 350, top: Device.osName=='Windows' ? null : -20,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                    :: PROCESSO SELETIVO ::
                                </Text>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'90%',alignItems: 'center', justifyContent:'center', top: 5}}>
                    <Card style={styles.card}>
                        <View style={{flex:1, position:'relative',width:'100%', height:'100%'}}>
                        <Container fluid className="Rules-Container">
                            <View style={{position:'relative',/* overflow:'scroll', */ height: 600 , width:'98vw'}}>
                                <View style={{position:'relative', flexDirection: 'row', justifyContent:'space-between', margin: 10, marginTop: -20, marginBottom: 20}}>
                                <Form>
                                    <Row>
                                        <Form.Group as={Col} >
                                            <TouchableOpacity
                                                style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 100, 
                                                         alignItems: 'center', justifyContent: 'center', top: 30, marginLeft: 10, }}
                                                onPress={() => {
                                                    setModalVisible(!modalVisible);
                                                }}
                                                disabled={false}
                                            >               
                                                <View style={{/* width: 50, */flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                                                    <Icon name="search" size={20} color="#FFD700" style={{padding:  3,}}/>
                                                    <Text style={{fontSize: 13, fontWeight:'bold', color:'#FFF'}}>Pesquisar</Text>    
                                                </View>
                                            </TouchableOpacity>                                             
                                        </Form.Group>
                                        <Form.Group as={Col} >
                                            <TouchableOpacity
                                                style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 100, 
                                                         alignItems: 'center', justifyContent: 'center', top: -5, 
                                                         marginLeft: 250 }}
                                                onPress={() => {
                                                    imprimirlista();
                                                }}
                                                disabled={false}
                                            >               
                                                <View style={{/* width: 50, */flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                                                    <Icon name="print" size={20} color="#FFD700" style={{padding:  3,}}/>  
                                                    <Text style={{fontSize: 13, fontWeight:'bold', color:'#FFF'}}>Imprimir</Text>   
                                                </View>
                                            </TouchableOpacity> 
                                        </Form.Group>
                                    </Row>
                                </Form>                                
                                </View>
                            {loading ? (<ActivityIndicator size={50} color='#004b9e' />):null}
                                    <Table striped bordered hover responsive>
                                        <thead style={{position: 'sticky',/* top: -600, */backgroundColor:'#FFF', zIndex: 100,}}>
                                            <tr>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>#</Text></th>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>SEDE</Text></th> */}
                                                <th><Text style={{fontSize: 10, fontWeight:'bold'}}>RA</Text></th>
                                                <th><Text style={{fontSize: 10, fontWeight:'bold'}}>NOME</Text></th>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>CPF</Text></th> */}
                                                <th><Text style={{fontSize: 10, fontWeight:'bold'}}>STATUS</Text></th>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>DATA MATRÍCULA</Text></th> */}
                                            </tr>
                                        </thead>
                                        
                                        <tbody style={{position:'relative', zIndex: 10}}>  
                                        {   
                                                dados?.map(obj => (
                                                <tr key={obj.ITEM.toString()}>
                                                    <td>{obj.ITEM}</td>
                                                    {/* <td>{obj.SEDE}</td> */}
                                                    <td><Text style={{fontSize: 10}}>{obj.RA}</Text></td>                                                    
                                                    <td><Text style={{fontSize: 10}}>{obj.NOME}</Text></td>                                                    
                                                    {/* <td>{obj.CPF}</td>  */}                                                   
                                                    <td><Text style={{fontSize: 10}}>{obj.STATUS}</Text></td>                                                    
                                                    {/* <td>{obj.DTMATRICULA}</td> */}                                                    
                                                </tr>))
                                                
                                            }               
                                        </tbody>
                                    </Table>
                            </View>
                                
                        </Container>
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
            <Modal animationType='slide' transparent={true} visible={modalVisible}>
                <ScrollView>
                <Card style={[styles.card,{height: '100vh', width: '100%', alignSelf:'center',/* marginVertical: '70%' */}]} elevation={5} mode={'elevated'}> 
                <Container>
                <Form>
                    <Row>
                        <Form.Group as={Col} >
                            <Checkbox.Item 
                                label="Inscrito"
                                style={{width: 100, margin: 5}} 
                                //status="checked"
                                status={checkedInst ? 'checked' : 'unchecked'}
                                onPress={()=> {
                                    setCheckedInst(!checkedInst);
                                    setCheckedOu(false);
                                }} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Checkbox.Item 
                                label="Pré-Matriculado" 
                                //status="checked"
                                style={{width: 190, margin: 5}} 
                                status={checkedPMat ? 'checked' : 'unchecked'}
                                onPress={()=> {
                                    setCheckedPMat(!checkedPMat);
                                    setCheckedOu(false);
                                }} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Checkbox.Item 
                                label="Matriculado" 
                                //status="checked"
                                style={{width: 100, margin: 5}} 
                                status={checkedMat ? 'checked' : 'unchecked'}
                                onPress={()=> {
                                    setCheckedMat(!checkedMat);
                                    setCheckedOu(false);
                                }} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Checkbox.Item 
                                label="Cancelado" 
                                //status="checked"
                                style={{width: 100, margin: 5}} 
                                status={checkedCan ? 'checked' : 'unchecked'}
                                onPress={()=> {
                                    setCheckedCan(!checkedCan)
                                    setCheckedOu(false);
                                }} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <Checkbox.Item 
                                label="Outros" 
                                //status="checked"
                                style={{width: 80, margin: 5}} 
                                status={checkedOu ? 'checked' : 'unchecked'}
                                onPress={()=> {
                                        setCheckedInst(false);
                                        setCheckedPMat(false);
                                        setCheckedMat(false);
                                        setCheckedCan(false);
                                        setCheckedOu(!checkedOu)
                                    }} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <TextInput 
                                //label={'Mês/Ano'}
                                label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Mês/Ano</Text>}
                                autoCorrect={false}
                                autoComplete={false}
                                //placeholder={'Ex.: João Silva'}
                                //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                style={{margin: 1, width: 200}}
                                mode='outlined'
                                selectionColor={'#004b9e'}
                                underlineColor={'#004b9e'}
                                activeUnderlineColor={'#004b9e'}
                                outlineColor={'#004b9e'}
                                activeOutlineColor={'#004b9e'}
                                textColor={'#004b9e'}
                                dense={true}
                                value={mesano}
                                onChangeText={(text) => {validarfiltroData(text)}}       
                                onSubmitEditing={()=>filtroProcSeletivo()}                 
                                right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroProcSeletivo()}} />} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} >
                            <TouchableOpacity
                                style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 50, alignItems: 'center', justifyContent: 'center', top: 10, marginLeft: 100 }}
                                onPress={() => {
                                    setModalVisible(!modalVisible);
                                }}
                                disabled={false}
                            >               
                                <View style={{/* width: 50, */flexDirection: 'row',}}>
                                    <Icon name="door-open" size={20} color="#FFD700" style={{padding:  3,}}/>    
                                </View>
                            </TouchableOpacity> 
                        </Form.Group>
                    </Row>
                </Form>
                </Container>
                </Card>
                </ScrollView>
            </Modal>
        <StatusBar style="auto" />
        </View>
    )
}
