
/*
    Site Buscar Icons: https://oblador.github.io/react-native-vector-icons/
    Site de Fonts: https://fonts.google.com/
*/
import React, {useContext, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthContext } from '../contexts/auth';
import AuthRoutes from '../screen/login'; //CHAMA TELA DE LOGIN
import AppRoutes from '../screen/home';
import moment from 'moment';
//import { UtilContext } from '../contexts/utilidade';

function Routes({navigation}){
    const { signUp, signOut, signed } = useContext(AuthContext);
    useEffect(() => {
        loginIn();             
    }, [])

    async function loginIn(){
        let bdintlogin = await AsyncStorage.getItem('bdlogingestor');  
        let parsed = JSON.parse(bdintlogin);
        if(parsed){
            let date = new Date().getDate();
            let month = new Date().getMonth() + 1;
            let year = new Date().getFullYear();
            let dataagora = `${month}/${date}/${year}`
            //let dataagora = `02/28/2023`
            const date2 = new Date(dataagora);
            const date1 = new Date(parsed.expired);

            let diff = moment(date1,"DD/MM/YYYY").diff(moment(date2,"DD/MM/YYYY"));
            let dias = moment.duration(diff).asDays();
            //console.log('date1:', date1)
            //console.log('date2:', date2)
            //console.log('Dias exp:', dias)
            if(parsed && dias >= 0){
                signUp(parsed.nome, parsed.superadmin, parsed.admin, parsed.gestor, parsed.administrativo, parsed.foto, parsed.coligada, parsed.filial,  parsed.predio,  parsed.codpredio, parsed.ativo,parsed.trocasenha, parsed.token, parsed.alterperfil);
                              
            }else{
                if(dias < 0){
                    //alert('');
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## LOGIN - EXPIRADO ##', // This is a title
                            `Sua Sessão expirou faça login novamente!`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                }  
                //signOut();
            }
        }

    }
    return(  (signed) ? <AppRoutes navigation={navigation}/> : <AuthRoutes/>  )
}

export default Routes
