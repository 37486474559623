import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, useCallback, useRef} from "react";
import { Text, View, Dimensions, TouchableOpacity, Linking,PixelRatio, Image, FlatList, Modal, ScrollView, ActivityIndicator } from "react-native";
import { Card, TextInput, Switch  } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import * as Device from 'expo-device';//Device.osName
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import { useIsFocused  } from '@react-navigation/native';
import Feather from 'react-native-vector-icons/Feather';
Feather.loadFont();

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';

const {width, height} = Dimensions.get('screen');
const SelUser = [];

export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso, Perfil } = useContext(UtilContext);
    const [iduser, setIduser] = useState('');
    const [nomeuser, setNomeUser] = useState('');
    const [loginuser, setLoginUser] = useState('');
    const [celularuser, setCelularUser] = useState('');
    const [emailuser, setEmailUser] = useState('');
    const [isadmin, setIsAdmin] = useState(false);
    const [isgestor, setIsGestor] = useState(false);
    const [isadministrativo, setIsAdministrativo] = useState(false);
    const [issuperadmin, setIsSuperAdmin] = useState(false);
    const [isupload, setIsUpload] = useState(false);
    const [listuser, setListUser] = useState([]);
    const [modalEditUser, setModalEditUser] = useState(false);

    const [loading, setLoading] = useState(false);

    const [predio, setPredio] = useState('');
    const [idpredio, setIdPredio] = useState('');
    const [codcoligada, setCodColigada] = useState('');
    const [codfilial, setCodFilial] = useState('');
    const [codpredio, setCodPredio] = useState('');

    const [edtdesativar, setEdtDesativar] = useState('');
    const [edtexcluir, setEdtExcluir] = useState('');
    const [upd, setUpd]= useState('');
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const [timeoutloading2, setTimeOutLoading2]=useState(100);
    const [timeoutloading3, setTimeOutLoading3]=useState(0);
    const tempotimeout = 77900;
    const [tempolimite, setTempoLimite] = useState('');



    const onOpenSuggestionsList = useCallback(isOpened => {}, []);
    const dropdownController = useRef(null);
    const searchRef = useRef(null);
    const dadospermissao = Perfil;
    const ItemViewPermissao = ({ item }) => {
        return (
            <Text onPress={() => getItem(item)}>
                {item.name}
            </Text>
        );
      }
      
      const perupload = (
        <Popover id="popover-basic" style={{zIndex: 1000}}>
          <Popover.Header as="h3">Permissão Upload</Popover.Header>
          <Popover.Body>
            Com essa permissão o usuário poderá <strong>Criar Diretórios</strong>, <strong>Enviar Arquivos</strong> e 
            <strong> Deletar Arquivos/Diretório</strong>.
            Desmarcado só terá permissão de <strong>Download</strong> e acesso aos diretórios.
          </Popover.Body>
        </Popover>
      );

      const persuperadmin = (
        <Popover id="popover-basic" style={{zIndex: 1000}}>
          <Popover.Header as="h3">Permissão SuperAdmin</Popover.Header>
          <Popover.Body>
            Essa permissão só deve ser marcada se for funcionário TI.
            Terá funcionalidades exclusiva para TI
          </Popover.Body>
        </Popover>
      );

          
      //GET DO FORM MODAL - TIPO
      const getItem = (item) => {
        setPredio(item.name);
        setIdPredio(item.id);
        setCodColigada(item.codcoligada);
        setCodFilial(item.codfilial);
        setCodPredio(item.codpredio);
      };


    useEffect(() => { 
        document.title = dotenv.TITLE;
    }, []);

    useEffect(() => { 

        if(!issuperadmin && !isadmin && !isgestor && !isadministrativo && isupload)
         setIsUpload(false);
        
        if(!issuperadmin && !isadmin && isgestor && !isadministrativo && isupload)
         setIsUpload(false);

        if(issuperadmin && !isadmin && !isgestor && !isadministrativo)
         setIsUpload(true);

        if(!issuperadmin && isadmin && !isgestor && !isadministrativo)
         setIsUpload(true);


    }, [issuperadmin, isadmin, isgestor, isadministrativo, isupload]);

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading){
            setTimeOutLoading(0);
            setTimeOutLoading2(100);
            setTimeOutLoading3(0);
            setTempoLimite('');
        }
    
        if(loading)
        setTimeout(() => {
            const tempoaut = parseInt(timeoutloading)+1;         
            let tempoaut2 = parseInt(timeoutloading3)+1000;         
            let ms = parseInt(tempotimeout) - parseInt(tempoaut2);
            let sec = Math.floor((ms / 1000) % 60);
            let min = Math.floor((ms / 1000 / 60) % 60);         
            let temp = (min < 10 ? '0' : '') + min + ":" + (sec < 10 ? '0' : '') + sec;                  
            setTimeOutLoading(   tempoaut    )
            setTimeOutLoading2(    /* Math.round( */convertRange( tempoaut, [ 0, tempotimeout/1000 ], [ 99, 0 ] )/* ) */        )
            setTimeOutLoading3(    tempoaut2     )
            setTempoLimite(temp);        
       }, 1000);
    }, [loading, timeoutloading]);

    function timeoutPromise(ms, promise) {
        return new Promise((resolve, reject) => {
          const timeoutId = setTimeout(() => {
            reject(new Error("Servidor não respondeu em tempo hábil"))
          }, ms);
          promise.then(
            (res) => {
              clearTimeout(timeoutId);
              resolve(res);
            },
            (err) => {
              clearTimeout(timeoutId);
              reject(err);
            }
          );
        })
      }

      function convertRange( value, r1, r2 ) { 
        return (( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ]).toFixed(1);
      }

    const focus = useIsFocused();  // useIsFocused as shown 
   useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
       if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            fetchData();     
       }
   }, [focus]);

   const fetchData = async () => {
        try {
            setLoading(true);
            await timeoutPromise(tempotimeout,fetch(`${dotenv.RESTGESTOR}/listuser`, {
                    //timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                }))
                .then((response) => response.json())
                .then((responseJson) => {
                    //console.log(responseJson.dados)
                    if (responseJson.validtoken==false){
                        //alert(responseJson.message); 
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TOKEN - EXPIRADO ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        
                        //setLoading(-1);
                        //signOut();
                        return
                    }
                    setListUser(responseJson.dados);
                    setLoading(false);
                    
                })
                .catch((error) => {
                    Aviso("Error:", error.message, "danger", 3000);  
                    setLoading(false);
                })
                .finally(function () {setLoading(false);});  
        } catch (err) {
            Aviso("Error:", err.message, "danger", 3000);
            setLoading(false);
        } finally {{setLoading(false);}}
    }
    
    async function update_login(){        
        try {
            
            if(nomeuser==''||loginuser==''||celularuser==''||emailuser==''){
                Aviso("Error Novo Login:", 'Campos Obrigatórios Pendentes!!!', "danger", 3000);   
                return false;
            }
            
            if(predio==''||codcoligada==''||codfilial==''||codpredio==''){
                Aviso("Error Novo Login:", 'permissão Pendentes!!!', "danger", 3000);   
                return false;
            }
            
            if(isadmin==false && isgestor==false&&isadministrativo==false && issuperadmin==false){
                Aviso("Error Novo Login:", 'Perfil Pendente!!!', "danger", 3000);   
                return false;
            }
            if(celularuser.length < 15){
                Aviso("Error:", 'Celular no formato Inválido!', "danger", 3000);
                return false;
            }
          setLoading(true);
          await fetch(`${dotenv.RESTGESTOR}/updatelogin`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: iduser,
                        celular: celularuser,
                        email: emailuser,
                        superadmin: issuperadmin,
                        admin: isadmin,
                        gestor: isgestor,
                        administrativo: isadministrativo,
                        upload: isupload,
                        predio: predio,
                        idpredio: idpredio,
                        codpredio: codpredio,
                        codcoligada: codcoligada,
                        codfilial: codfilial
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                    setLoading(false);
                    
                    if(responseJson.sucesso == true){
                        Aviso("", responseJson.message, "success", 3000); 
                        setIduser('');
                        setNomeUser('');
                        setLoginUser('');
                        formatCelular('');
                        setEmailUser('');
                        setIsSuperAdmin('');
                        setIsAdmin('');
                        setIsGestor('');
                        setIsAdministrativo('');
                        setIsUpload('');
                        setPredio('');                       
                        dropdownController.current.clear();  
                        setModalEditUser(!modalEditUser);
                        fetchData();
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);                         
                    }
              })
              .catch((error) => {
                  setLoading(false);
                  Aviso("Error:", error.message, "danger", 3000);                                       
              })
              .finally(function () { setLoading(false); });  
            
        } catch (err) {
            setLoading(false);
            Aviso("Error:", err.message, "danger", 3000);
            
        } finally {{setLoading(false);}}
   }

   function seluser (userid){        
        SelUser.splice(0); //Limpar Array
        listuser.forEach(obj => {
            if (obj.id === userid) {                        
                SelUser.push({ 
                    id: obj.id,
                    nome: obj.nome,
                    login: obj.login,
                    codpredio: obj.codpredio,
                    predio: obj.predio,
                    idpredio: obj.idpredio,
                    superadmin: obj.superadmin,
                    admin: obj.admin,
                    gestor: obj.gestor,
                    administrativo: obj.administrativo,
                    celular: obj.celular,
                    email: obj.email,
                    upload: obj.upload

                })            
            }
        })
        
        setIduser(SelUser[0].id);
        setNomeUser(SelUser[0].nome);
        setLoginUser(SelUser[0].login);
        formatCelular(SelUser[0].celular);
        setEmailUser(SelUser[0].email);
        setIsSuperAdmin(SelUser[0].superadmin);
        setIsAdmin(SelUser[0].admin);
        setIsGestor(SelUser[0].gestor);
        setIsAdministrativo(SelUser[0].administrativo);
        setIsUpload(SelUser[0].upload);
        setPredio(SelUser[0].predio);
        setModalEditUser(!modalEditUser);
   }

   function deluser (userid, nomeuser){    
    
    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
        '## LOGIN ##', // This is a title
        `Deseja realmente excluir ${nomeuser},\nDeseja Continuar?`,
        {
            textConfirm: 'SIM',      // Label of button confirm
            textCancel: 'NÃO',     // Label of button cancel
            onCancel: () => { { } }, // Call your cancel function 
            onConfirm: () => { { 
                setEdtExcluir(userid);
                fetch(`${dotenv.RESTGESTOR}/deletelogin`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: userid
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                    setEdtExcluir('');
                    if(responseJson.sucesso == true){
                        Aviso("", responseJson.message, "success", 3000); 
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);
                    }

                    fetchData();
              })
              .catch((error) => {
                  Aviso("Error:", error.message, "danger", 3000); 
                  setEdtExcluir('');                                      
              })
              .finally(function () { setLoading(false); setEdtExcluir('');}); 
            } } // Call your confirm function 
        }
    )
        
   }

   function upduser (userid, nomeuser, loginuser){    
    
    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
        '## LOGIN ##', // This is a title
        `Deseja realmente reiniciar a senha para o padrão do ${nomeuser},\nDeseja Continuar?`,
        {
            textConfirm: 'SIM',      // Label of button confirm
            textCancel: 'NÃO',     // Label of button cancel
            onCancel: () => { { } }, // Call your cancel function 
            onConfirm: () => { { 
                setUpd(userid);
                fetch(`${dotenv.RESTGESTOR}/resetlogin`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: userid,
                        login: loginuser
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
              .then((response) => response.json())
              .then((responseJson) => {
                        setUpd('');
                    if(responseJson.sucesso == true){
                        Aviso("", responseJson.message, "success", 3000); 
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);
                    }

                    fetchData();
              })
              .catch((error) => {
                  Aviso("Error:", error.message, "danger", 3000); 
                  setUpd('');                                      
              })
              .finally(function () { setLoading(false); setUpd('');}); 
            } } // Call your confirm function 
        }
    )
        
   }   

   function desativaruser (userid, nomeuser, value){    
    
    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
        '## LOGIN ##', // This is a title
        `Deseja realmente ${value==1 ? "DESATIVAR" : "ATIVAR"} conta ${nomeuser},\nDeseja Continuar?`,
        {
            textConfirm: 'SIM',      // Label of button confirm
            textCancel: 'NÃO',     // Label of button cancel
            onCancel: () => { { } }, // Call your cancel function 
            onConfirm: () => { { 
                setEdtDesativar(userid);
                fetch(`${dotenv.RESTGESTOR}/desativarlogin`, {
                    method: 'POST',
                    body: JSON.stringify({
                        id: userid,
                        value: value==1 ? 0 : 1
                    }),
                    timeout: 5000,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "x-access-token": user.token,
                    },
                })
              .then((response) => response.json())
              .then((responseJson) => {                  
                     
                    if(responseJson.sucesso == true){
                        Aviso("", responseJson.message, "success", 3000); 
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);                         
                    }
                    setEdtDesativar('');
                    fetchData();
              })
              .catch((error) => {
                  Aviso("Error:", error.message, "danger", 3000);  
                  setEdtDesativar('');                                     
              })
              .finally(function () { setLoading(false); setEdtDesativar('');}); 
            } } // Call your confirm function 
        }
    )
        
   }   

  function formatCelular(v){
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
        r = r.replace(/^(\d*)/, "($1");
    }
    setCelularUser(r);
  }
  const excluiadmin = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Dados Desenvolvedor</Popover.Header>
      <Popover.Body>
        Conta do <strong>desenvolvedor</strong> não pode ser editada e nem excluída.
      </Popover.Body>
    </Popover>
  );

  const updadmin = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Dados Desenvolvedor</Popover.Header>
      <Popover.Body>
        Conta do <strong>desenvolvedor</strong> não pode ter a senha reiniciada.
      </Popover.Body>
    </Popover>
  );

  const excluiproprio = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Dados Usuário Logado</Popover.Header>
      <Popover.Body>
      <strong>Não</strong> pode <strong>editar/excluir</strong> o próprio login
      </Popover.Body>
    </Popover>
  );

    return (
        <View style={{position:'relative', width: width*0.83, top:10 ,alignItems:'center', justifyContent:'center', borderColor:'#004b9e', borderWidth:1,borderRadius:5, }}>
            {!modalEditUser ? (
            <Container fluid className="Rules-Container">
                <View style={{position:'relative',overflow:'scroll', height: 430}}>
                {loading ? (
                <>    
                <ActivityIndicator size={50} color='#004b9e' />
                        {timeoutloading2 <= 33 ? (<ProgressBar variant="danger" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                        {timeoutloading2 > 33 && timeoutloading2 <= 66  ? (<ProgressBar variant="warning" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                        {timeoutloading2 > 66 ? (<ProgressBar variant="success" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                </>
                ):null}
                <Table striped bordered hover size="sm" fixed="top">
                    <thead style={{position: 'sticky',top: '0px',backgroundColor:'#FFF', zIndex: 100}}>
                        <tr>
                            <th>#</th>
                            <th>NOME</th>
                            <th>LOGIN</th>
                            <th>PERFIL</th>
                            <th>PREDIO</th>
                            <th>ATIVO</th>
                            <th>EDIT</th>
                            <th>RESET SENHA</th>
                            <th>EXCLUIR</th>
                        </tr>
                    </thead>
                    
                    <tbody style={{position:'relative', zIndex: 10}}>  
                            {   
                                listuser.map(obj => (
                                <tr key={obj.id.toString()}>
                                    <td>{obj.id}</td>
                                    <td>{obj.nome}</td>
                                    <td>{obj.login}</td>
                                    <td>{obj.superadmin == 1 ? 'SuperAdmin' : obj.admin == 1 ? 'Admin' : obj.gestor==1 ? 'Gestor' : 'Administrativo'}</td>
                                    <td>{obj.predio}</td>
                                    <td>
                                        { (obj.login == 'segundo' || obj.nome == user.nome) ? ( 
                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={obj.login=='segundo' ? excluiadmin : excluiproprio}>
                                            <Button variant="">
                                                <TouchableOpacity
                                                        variant=""
                                                        //onPress={()=>{{seluser(obj.id)}}}
                                                        disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                        //style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                                >
                                                    <Icon name={obj.ativo==1 ? "check-circle" : "ban"} size={20} color={!(obj.login != 'segundo' && obj.nome != user.nome) ? "#808080": obj.ativo==1 ? "green" : "red"} style={{ padding: 2 }} />
                                                </TouchableOpacity>
                                            </Button>
                                        </OverlayTrigger>
                                        ):(
                                            <Button variant=""><TouchableOpacity
                                                onPress={()=>{{desativaruser(obj.id, obj.nome, obj.ativo)}}}
                                                disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                //style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                            >
                                                    {edtdesativar==obj.id ? (
                                                        <ActivityIndicator size={20} color='#004b9e' />
                                                    ):(
                                                        <Icon name={obj.ativo==1 ? "check-circle" : "ban"} size={20} color={!(obj.login != 'segundo' /* && obj.nome != user.nome */) ? "#808080": obj.ativo==1 ? "green" : "red"} style={{ padding: 2 }} />
                                                    )}

                                        </TouchableOpacity></Button>
                                        )}
                                    </td>
                                    <td>
                                        { (obj.login == 'segundo' || obj.nome == user.nome) ? ( 
                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={obj.login=='segundo' ? excluiadmin : excluiproprio}>
                                            <Button variant="">
                                                <TouchableOpacity
                                                        variant=""
                                                        //onPress={()=>{{seluser(obj.id)}}}
                                                        disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                        //style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                                    >
                                                        <Icon name="user-edit" size={20} color={!(obj.login != 'segundo' && obj.nome != user.nome) ? "#808080": "#004b9e"} style={{ padding: 2 }} />
                                                </TouchableOpacity>
                                            </Button>
                                        </OverlayTrigger>
                                        ):(
                                            <Button variant=""><TouchableOpacity
                                                onPress={()=>{{seluser(obj.id)}}}
                                                disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                //style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                            >
                                                <Icon name="user-edit" size={20} color={!(obj.login != 'segundo' && obj.nome != user.nome) ? "#808080": "#004b9e"} style={{ padding: 2 }} />
                                        </TouchableOpacity></Button>
                                        )}
                                    </td>
                                    <td>
                                    { (obj.login == 'segundo' ) ? (  // || obj.nome == user.nome                                    
                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={obj.login=='segundo' ? updadmin : null}>
                                            <Button variant="">
                                                <TouchableOpacity
                                                        //onPress={()=>{{deluser(obj.id, obj.nome)}}}
                                                        disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                        style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                                    >
                                                        <Icon name="unlock" size={20} color={!(obj.login != 'segundo' && obj.nome != user.nome) ? "#808080": "#FF0000"} style={{ padding: 2 }} />
                                                </TouchableOpacity>
                                            </Button>
                                        </OverlayTrigger>
                                        ) : (
                                        <Button variant=""><TouchableOpacity
                                                onPress={()=>{{upduser(obj.id, obj.nome, obj.login)}}}
                                                //disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                //style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                            >
                                                {upd==obj.id ? (
                                                        <ActivityIndicator size={20} color='#004b9e' />
                                                ):(
                                                        <Icon name="unlock" size={20} color={!(obj.login != 'segundo' /* && obj.nome != user.nome */) ? "#808080": "#FF0000"} style={{ padding: 2 }} />
                                                )}
                                                
                                        </TouchableOpacity></Button>
                                        )}
                                    </td>
                                    <td>
                                        { (obj.login == 'segundo' || obj.nome == user.nome) ? (                                      
                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={obj.login=='segundo' ? excluiadmin : excluiproprio}>
                                            <Button variant="">
                                                <TouchableOpacity
                                                        //onPress={()=>{{deluser(obj.id, obj.nome)}}}
                                                        disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                        style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                                    >
                                                        <Icon name="trash-alt" size={20} color={!(obj.login != 'segundo' && obj.nome != user.nome) ? "#808080": "#FF0000"} style={{ padding: 2 }} />
                                                </TouchableOpacity>
                                            </Button>
                                        </OverlayTrigger>
                                        ) : (
                                            <Button variant=""><TouchableOpacity
                                                onPress={()=>{{deluser(obj.id, obj.nome)}}}
                                                disabled={!(obj.login != 'segundo' && obj.nome != user.nome) ? true : false}
                                                //style={{position: 'relative',alignItems:'center', justifyContent:'center'}}
                                            >
                                                {edtexcluir==obj.id ? (
                                                        <ActivityIndicator size={20} color='#004b9e' />
                                                ):(
                                                        <Icon name="trash-alt" size={20} color={!(obj.login != 'segundo' && obj.nome != user.nome) ? "#808080": "#FF0000"} style={{ padding: 2 }} />
                                                )}
                                                
                                        </TouchableOpacity></Button>
                                        )}
                                    </td>
                                </tr>))
                                
                            }                    
                    </tbody>
                    </Table></View>
                    
            </Container>
            ) : (
                    <View>
                    <ScrollView>
                            <Container fluid="md">
                                <Form>
                                    <Row>
                                        <Form.Group as={Col}>
                                        <TextInput 
                                            label={'Nome Usuário'}
                                            autoCorrect={false}
                                            autoComplete={false}
                                            placeholder={'Ex.: João Silva'}
                                            //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                            style={{margin: 10}}
                                            mode='outlined'
                                            selectionColor={'#004b9e'}
                                            underlineColor={'#004b9e'}
                                            activeUnderlineColor={'#004b9e'}
                                            outlineColor={'#004b9e'}
                                            activeOutlineColor={'#004b9e'}
                                            textColor={'#004b9e'}
                                            dense={true}
                                            value={nomeuser}
                                            disabled={true}
                                            //onChangeText={(text) => {setNomeUser(text)}}                        
                                            left={<TextInput.Icon icon="account" disabled={true}/>}                                    
                                        />
                                        </Form.Group>

                                        <Form.Group as={Col}>
                                        <TextInput 
                                            label={'Login Usuário'}
                                            placeholder={'Ex.: joao.silva'}
                                            //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                            style={{margin: 10}}
                                            mode='outlined'
                                            selectionColor={'#004b9e'}
                                            underlineColor={'#004b9e'}
                                            activeUnderlineColor={'#004b9e'}
                                            outlineColor={'#004b9e'}
                                            activeOutlineColor={'#004b9e'}
                                            textColor={'#004b9e'}
                                            dense={true}
                                            value={loginuser}
                                            disabled={true}
                                            onChangeText={(text) => {setLoginUser(text)}}
                                            left={<TextInput.Icon icon="account-edit" disabled={true}/>}
                                        />
                                        </Form.Group>
                                        
                                        <Form.Group as={Col}>
                                                <TextInput 
                                                    label={'Celular'}
                                                    placeholder={'Ex.: 92 91234-5678'}
                                                    style={{margin: 10}}
                                                    mode='outlined'
                                                    selectionColor={'#004b9e'}
                                                    underlineColor={'#004b9e'}
                                                    activeUnderlineColor={'#004b9e'}
                                                    outlineColor={'#004b9e'}
                                                    activeOutlineColor={'#004b9e'}
                                                    textColor={'#004b9e'}
                                                    dense={true}
                                                    maxLength={15}
                                                    value={celularuser}
                                                    onChangeText={(text) => {formatCelular(text)}}
                                                    left={<TextInput.Icon icon="cellphone-check" disabled={true}/>}
                                                    
                                                />
                                        </Form.Group>
                                        
                                        <Form.Group as={Col}>
                                                <TextInput 
                                                    label={'Email'}
                                                    placeholder={'Ex.: joao.silva@email.com.br'}
                                                    //style={{width: Device.osName=='Windows' ? 300 : '95%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                                    style={{margin: 10}}
                                                    mode='outlined'
                                                    selectionColor={'#004b9e'}
                                                    underlineColor={'#004b9e'}
                                                    activeUnderlineColor={'#004b9e'}
                                                    outlineColor={'#004b9e'}
                                                    activeOutlineColor={'#004b9e'}
                                                    textColor={'#004b9e'}
                                                    dense={true}
                                                    value={emailuser}
                                                    onChangeText={(text) => {setEmailUser(text)}}
                                                    left={<TextInput.Icon icon="email-fast-outline" disabled={true}/>}
                                                />
                                        </Form.Group>

                                        <Form.Group as={Col} style={{zIndex: 10,}}>
                                                <AutocompleteDropdown
                                                        ref={searchRef}
                                                        controller={controller => {
                                                            dropdownController.current = controller
                                                        }}
                                                        direction='down'
                                                        onOpenSuggestionsList={onOpenSuggestionsList}
                                                        ChevronIconComponent={<Feather name="chevron-down" size={20} style={{position: 'absolute', left:-5,}} color="#FF0000" />}
                                                        ClearIconComponent={<Feather name="x-circle" size={20}  style={{position: 'absolute', left:-10,top:-10, }} color="#FF0000" />}                                                                                                    
                                                        clearOnFocus={true}
                                                        closeOnBlur={false}
                                                        closeOnSubmit={false}
                                                        initialValue={{ id: SelUser[0]?.idpredio}} // or just '2'
                                                        position='absolute'
                                                        debounce={600}
                                                        onSelectItem={(item) => {
                                                            if(item?.name){
                                                                getItem(item)
                                                            }
                                                        }}
                                                        onFocus={() => {                                                            
                                                            setPredio('');
                                                            setCodColigada('');
                                                            setCodFilial('');
                                                            setCodPredio('');
                                                        }}
                                                        onClear={() => {
                                                            setPredio('');
                                                            setCodColigada('');
                                                            setCodFilial('');
                                                            setCodPredio('');
                                                        }}
                                                        suggestionsListMaxHeight={height}
                                                        dataSet={dadospermissao}
                                                        textInputProps={{
                                                            placeholder: 'Selecione Permissão Acesso e Diretórios',
                                                            autoCorrect: false,
                                                            autoCapitalize: 'none',
                                                            style: {
                                                                borderRadius: 5,
                                                                color: '#000',                                                          
                                                                backgroundColor: '#fff',
                                                                fontSize: 15,
                                                                height: 40,
                                                                margin: 1,
                                                                /* width: Device.osName=='Windows'? '95%': 500,  */                                                             
                                                            }
                                                        }}
                                                        inputContainerStyle={{
                                                            borderRadius: 5,
                                                            color:'#000',
                                                            /* width: '95%', */
                                                            margin: 5,
                                                            //marginTop: 14
                                                        }}
                                                        containerStyle={{ flexGrow: 1, flexShrink: 1 }}
                                                        renderItem={(item, text) => <Text style={{ color: '#000',  padding: 10, }}>{item.title}</Text>}
                                                        inputHeight={30}
                                                        EmptyResultComponent={<Text style={{ padding: 10, fontSize: 15, fontWeight: 'bold', color: '#FF0000'  }}>Nada Encontrado!</Text>}
                                                        useFilter={false}
                                                        editable={true}                                  
                                                    />
                                        </Form.Group> 
                                        <Form.Group as={Col} style={{zIndex: 1}}>
                                            <Card.Content style={{borderColor:'#004b9e', borderWidth:1,borderRadius:5, top: 10, flexDirection:Device.osName=='Windows'?'row':'column', marginBottom: 80 }}>
                                                <Text style={{position:'absolute', top:-10, backgroundColor:'#004b9e', width: 100, textAlign: 'center', borderRadius: 5, fontWeight:'bold', color:'#FFF'}}>Perfil - Login</Text>
                                                <View style={{marginTop:Device.osName=='Windows'?null:10,flexDirection:Device.osName=='Windows'?'row':'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                                    {user?.superadmin == 1 ? (
                                                    <>
                                                    <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 1}}>
                                                        SuperAdmin ( TI )
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={persuperadmin}>
                                                            <Button variant=""><Icon2 name="help-circle" size={20} color="#004b9e"/></Button>
                                                        </OverlayTrigger>
                                                    </Text>
                                                    <Switch value={issuperadmin} onValueChange={()=>{
                                                        if(!issuperadmin){
                                                            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                                                '## PERFIL - LOGIN ##', // This is a title
                                                                `Este perfil é somente para TI!\nDeseja Continuar?`,
                                                                {
                                                                    textConfirm: 'SIM',      // Label of button confirm
                                                                    textCancel: 'NÃO',     // Label of button cancel
                                                                    onCancel: () => { { } }, // Call your cancel function 
                                                                    onConfirm: () => { { 
                                                                        setIsAdministrativo(false);
                                                                        setIsGestor(false);
                                                                        setIsAdmin(false);
                                                                        setIsSuperAdmin(!issuperadmin);
                                                                    } } // Call your confirm function 
                                                                }
                                                            )
                                                        }else{
                                                            setIsAdministrativo(false);
                                                            setIsGestor(false);
                                                            setIsAdmin(false);
                                                            setIsSuperAdmin(!issuperadmin);
                                                        }
                                                        }} />
                                                        </>
                                                    ): null}
                                                </View>
                                                <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                                    <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>Admin</Text>
                                                    <Switch value={isadmin} onValueChange={()=>{
                                                            setIsAdministrativo(false);
                                                            setIsGestor(false);
                                                            setIsAdmin(!isadmin);
                                                            setIsSuperAdmin(false);
                                                                                                
                                                        }} />
                                                </View>
                                                <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                                    <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>Gestor</Text>
                                                    <Switch value={isgestor} onValueChange={()=>{
                                                        setIsAdministrativo(false);
                                                        setIsAdmin(false);
                                                        setIsGestor(!isgestor);
                                                        setIsSuperAdmin(false);
                                                        }} />
                                                </View>
                                                <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                                    <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>Administrativo</Text>
                                                    <Switch value={isadministrativo} onValueChange={()=>{
                                                        setIsAdmin(false);
                                                        setIsGestor(false);
                                                        setIsAdministrativo(!isadministrativo);
                                                        setIsSuperAdmin(false);
                                                        }} />
                                                    
                                                </View>
                                                <View style={{flexDirection:'row', padding: Device.osName=='Windows' ? 10 : 1, alignItems:'center', justifyContent: Device.osName=='Windows'?null:'space-between'}}>
                                                    <Text style={{position:'relative', margin:Device.osName=='Windows' ? 10 : 10}}>
                                                        Upload
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={perupload}>
                                                            <Button variant=""><Icon2 name="help-circle" size={20} color="#004b9e"/></Button>
                                                        </OverlayTrigger>
                                                        
                                                    </Text>
                                                    <Switch value={isupload} onValueChange={()=>{
                                                        setIsUpload(!isupload);
                                                    }} />
                                                    
                                                </View>
                                            </Card.Content>
                                        </Form.Group>       
                                    </Row>        
                                    <Form.Group as={Col} style={{zIndex: -10}}>
                                            <View style={{flexDirection: 'row',position: 'relative', alignContent:'center', justifyContent:'space-between', marginBottom:10}}>
                                            <TouchableOpacity
                                                onPress={()=>{
                                                    //salvar_login();
                                                    update_login();
                                                    
                                                }}
                                                style={{backgroundColor:'#004b9e', top: Device.osName=='Windows' ? null : -60,width: Device.osName=='Windows' ? 130 : 100, height: Device.osName=='Windows' ? 40 : 30, alignItems:'centrer', justifyContent:'center', borderRadius:10,}}
                                            >
                                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                                    {loading ? (
                                                        <ActivityIndicator size={25} style={{position:'relative',marginRight: 20}} color='#eee' />
                                                    ):(
                                                        <Icon name="save" size={20} color="#FFD700" style={{marginRight: 20}}/>
                                                    )}

                                                    <Text style={{textAlign:'center', fontSize: Device.osName=='Windows' ? 15 : 13, fontWeight:'bold', color:'#FFF'}}>ATUALIZAR</Text>
                                                </View>
                                            </TouchableOpacity>    
                                            <TouchableOpacity
                                                onPress={()=>{
                                                    setIduser('');
                                                    setNomeUser('');
                                                    setLoginUser('');
                                                    formatCelular('');
                                                    setEmailUser('');
                                                    setIsSuperAdmin('');
                                                    setIsAdmin('');
                                                    setIsGestor('');
                                                    setIsAdministrativo('');
                                                    setIsUpload('');
                                                    setPredio('');                       
                                                    dropdownController.current.clear();  
                                                    setModalEditUser(!modalEditUser);
                                                    
                                                }}
                                                style={{backgroundColor:'#004b9e', top: Device.osName=='Windows' ? null : -60,width: Device.osName=='Windows' ? 130 : 100, height: Device.osName=='Windows' ? 40 : 30, alignItems:'centrer', justifyContent:'center', borderRadius:10,}}
                                            >
                                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                                    {loading ? (
                                                        <ActivityIndicator size={25} style={{position:'relative',marginRight: 20}} color='#eee' />
                                                    ):(
                                                        <Icon name="backspace" size={20} color="#FFD700" style={{marginRight: 20}}/>
                                                    )}

                                                    <Text style={{textAlign:'center', fontSize: Device.osName=='Windows' ? 15 : 13, fontWeight:'bold', color:'#FFF'}}>CANCELAR</Text>
                                                </View>
                                            </TouchableOpacity> 
                                            </View>                
                                    </Form.Group>         
                                </Form>
                            </Container>
                    </ScrollView>
                    </View>
            )}
        </View>
    )
}
