import React from "react";

import Computador from '../dadosaluno/indexComp';
import Mobile from '../dadosaluno/indexMobile';

import * as Device from 'expo-device';//Device.osName

export default function App({navigation}) {

    return(
        Device.osName=='Windows' ? <Computador navigation={navigation}/> : <Mobile navigation={navigation}/>
    )

}