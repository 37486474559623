import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import ReactDOM from 'react-dom'
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, ActivityIndicator } from "react-native";

import { Card, Checkbox, TextInput } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';


const {width, height} = Dimensions.get('screen');

//var mount = document.querySelectorAll('div.browser-mount');
export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso, iscoligada3, iscoligada5 } = useContext(UtilContext);
    const [loading, setLoading]=useState(false);
    const [dados, setDados]=useState([]);
    const [dadosalunos, setDadosAlunos]=useState([]);
    const [checkedInst, setCheckedInst]=useState(false);
    const [checkedPMat, setCheckedPMat]=useState(false);
    const [checkedMat, setCheckedMat]=useState(false);
    const [checkedCan, setCheckedCan]=useState(false);
    const [checkedOu, setCheckedOu]=useState(false);
    const [mesano, setMesAno]=useState('');
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const [timeoutloading2, setTimeOutLoading2]=useState(100);
    const [timeoutloading3, setTimeOutLoading3]=useState(0);
    const tempotimeout = 180000;//77900;
    const [tempolimite, setTempoLimite] = useState('');

    const total = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">TOTAL</Popover.Header>
          <Popover.Body>
            Total a <strong>receber</strong> de pagamento(s) efetuado pelos alunos.
          </Popover.Body>
        </Popover>
      );
    
    const recebido = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">RECEBIDO</Popover.Header>
          <Popover.Body>
            Total <strong>pago</strong> pelos alunos.
          </Popover.Body>
        </Popover>
      );
    
    const descjuros = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">DESC/JUROS</Popover.Header>
          <Popover.Body>
            Desconto / Juros no pagamento efetuado pelo aluno .
          </Popover.Body>
        </Popover>
      );
    
    const pendente = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">PENDENTE</Popover.Header>
          <Popover.Body>
            Total pendente de pagamento pelo(s) aluno(s).
          </Popover.Body>
        </Popover>
      );

          
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading){
            setTimeOutLoading(0);
            setTimeOutLoading2(100);
            setTimeOutLoading3(0);
            setTempoLimite('');
        }
    
        if(loading)
        setTimeout(() => {
         const tempoaut = parseInt(timeoutloading)+1;         
         let tempoaut2 = parseInt(timeoutloading3)+1000;         
         let ms = parseInt(tempotimeout) - parseInt(tempoaut2);
         let sec = Math.floor((ms / 1000) % 60);
         let min = Math.floor((ms / 1000 / 60) % 60);         
         let temp = (min < 10 ? '0' : '') + min + ":" + (sec < 10 ? '0' : '') + sec;                  
         setTimeOutLoading(   tempoaut    )
         setTimeOutLoading2(    /* Math.round( */convertRange( tempoaut, [ 0, tempotimeout/1000 ], [ 99, 0 ] )/* ) */        )
         setTimeOutLoading3(    tempoaut2     )
         setTempoLimite(temp);   
         //alert(`Tempo: ${temp}`);
       }, 1000);
    }, [loading, timeoutloading]);

    function timeoutPromise(ms, promise) {
        return new Promise((resolve, reject) => {
          const timeoutId = setTimeout(() => {
            reject(new Error("Servidor não respondeu em tempo hábil"))
          }, ms);
          promise.then(
            (res) => {
              clearTimeout(timeoutId);
              resolve(res);
            },
            (err) => {
              clearTimeout(timeoutId);
              reject(err);
            }
          );
        })
      }

      function convertRange( value, r1, r2 ) { 
        return (( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ]).toFixed(1);
      }
    
    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            document.title = dotenv.TITLE;
            //fetchData();
            setDados([]);
            setCheckedInst(false);
            setCheckedPMat(false);
            setCheckedMat(false);
            setCheckedCan(false);
            setCheckedOu(false);
            setMesAno('');

        }
    }, [focus]);

    

    async function fetchData(dados){
        
        if(!dados)
            return false;
        //alert(JSON.stringify(dados));
        //return false;

        if (iscoligada3 ==false && iscoligada5== false){
            Aviso("Error:", 'Selecione a Coligada', "danger", 3000); 
            return false;
        }

        //alert(JSON.stringify(dados));
        //return false;

        setLoading(true);
        setDados([]);
        //await timeoutPromise(10*60*1000,fetch(`${dotenv.RESTGESTOR}/finpolo`, {
        await timeoutPromise(tempotimeout,fetch(`${dotenv.RESTGESTOR}/${dados.predio != '000' ? 'finpolo' : 'finpoloresumo'}`, {
                method: 'POST',
                body: JSON.stringify({
                    ano:dados.ano,
                    mes:dados.mes,
                    predio:dados.predio,
                    nomepredio:dados.nomepredio,
                    coligada: (iscoligada3 && iscoligada5) ? 0 : iscoligada3 ? 3 : iscoligada5 ? 5 : 0                    
                }),
                //timeout: tempotimeout,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-access-token": user.token,
                },
            }))
            .then((response) => response.json())
            .then((responseJson) => {     
                //console.log(JSON.stringify(responseJson))
                
                if (responseJson.validtoken==false){
                    setLoading(false);
                    //alert(responseJson.message); 
                    alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                        '## TOKEN - EXPIRADO ##', // This is a title
                        `${responseJson.message}`,
                        {
                            textConfirm: 'OK',      // Label of button confirm
                            textCancel: '',     // Label of button cancel
                            onCancel: () => { { } }, // Call your cancel function 
                            onConfirm: () => { { 
                                signOut();
                            } } // Call your confirm function 
                        }
                    )
                    
                    //setLoading(-1);
                    //signOut();
                    return
                }
                    
                    if(responseJson.sucesso == true){
                        //console.log(responseJson)                        
                        if(responseJson.dados.length>0){
                            //finalizar loading
                            setLoading(false);

                            //SALVAR CONSULTA LOGFATURA 
                            //console.log(responseJson.dados);
                            let datahj = new Date();                            
                            let dataformat = `${datahj.getFullYear()}-${datahj.getMonth() +1}-${datahj.getDate()} ${datahj.getHours()}:${datahj.getMinutes()}:${datahj.getSeconds()}`
                            
                              if (dados.predio != '000')
                              fetch(`${dotenv.RESTGESTOR}/insertlogfatura`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    dtconsulta: dataformat,
                                    user: user.nome,
                                    polo: responseJson.dados[0].NOMEPREDIO,
                                    total: responseJson.dados[0].FATTOTAL,
                                    recebido: responseJson.dados[0].FATPAGO,
                                    descjuros: responseJson.dados[0].DESC_JUROS,
                                    pendente: responseJson.dados[0].FATINAD,
                                    repasseperc: responseJson.dados[0].PERCREPASSE,
                                    repassevalor: numberFormat(responseJson.dados[0].VLRREPASSE),
                                    mesano: responseJson.dados[0].MESANO,
                                    ultimamod: responseJson.dados[0].ULTIMAMOD.replaceAll('T',' ').replaceAll('.000Z', ''),
                                    ultimopag: responseJson.dados[0].ULTIMOPAG!=null ? responseJson.dados[0].ULTIMOPAG.replaceAll('T',' ').replaceAll('.000Z', '') : '0000-00-00 00:00:00.000',
                                    dadosalunos: responseJson.dadosaluno
                                    
                                }),
                                headers: {
                                    "Content-type": "application/json; charset=UTF-8",
                                    "x-access-token": user.token,
                                },
                            })

                            
                            setDados(responseJson.dados);
                            if(responseJson.dadosaluno){
                                //console.log(responseJson.dadosaluno);
                                setDadosAlunos(responseJson.dadosaluno);
                            }

                            //setLoading(false);
                        }else{
                            Aviso("", 'Não foi encontrado informações para está pesquisa.', "danger", 3000);
                            //setLoading(false);
                        }
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);  
                        //setLoading(false);                       
                    }
                    
            })
            .catch((error) => {
                Aviso("Error:", error.message, "danger", 3000);   
                setLoading(false);             
            })
            .finally(function () { setLoading(false); }); 
    }

    function validarfiltroData(v){
        v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        setMesAno(v);
      }

    function filtrofaturamento(){
        //const filtropesq = [];
        
        if(mesano==''){
         alert('Informe mês/ano do Faturamento!');
         return false;
        }
        
        
        let data = mesano.split('/')
        let search = {
            mes: data[0],
            ano: data[1],
            predio: user?.codpredio,//=='000' ? '' : user.codpredio
            nomepredio: user?.predio
        }

        setDados([]);
        setDadosAlunos([]);

        //alert(JSON.stringify(search));
        fetchData(search);

    }

    
    const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);
    
    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5, right: 10 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : 10 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{width: Device.osName=='Windows' ? '100%' : 350, top: Device.osName=='Windows' ? null : -20,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                    {`:: FATURAMENTO ${iscoligada3 && iscoligada5 ? 'COLIGADA: 3 e 5' : iscoligada3 ? 'COLIGADA: 3' : iscoligada5 ? 'COLIGADA: 5' : ''} ::`}
                                </Text>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', top: 5}}>
                    <Card style={styles.card}>
                        <View style={{flex:1, position:'relative',width:'100%', height:'100%'}}>
                        <Container fluid className="Rules-Container">
                            <View style={{position:'relative',/* overflow:'scroll', */ height: 450, minWidth: '70vw', width:'83vw'}}>
                                <View style={{position:'relative', flexDirection: 'row', justifyContent:'space-between'}}>
                                    <TextInput 
                                        //label={'Mês/Ano Faturamento'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Mês/Ano Faturamento</Text>}
                                        autoCorrect={false}
                                        autoComplete={false}
                                        //placeholder={'Ex.: João Silva'}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        style={{margin: 1, top: -10, marginBottom: 5}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={mesano}
                                        onChangeText={(text) => {validarfiltroData(text)}}       
                                        onSubmitEditing={()=>filtrofaturamento()}                 
                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtrofaturamento()}} />} 
                                    />
                                    {user.superadmin==1 || user.admin==1 || user.gestor==1?(
                                    <TouchableOpacity
                                        style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 50, alignItems: 'center', justifyContent: 'center', top: 10, marginLeft: 100 }}
                                        onPress={() => {

                                            if(mesano == ''){
                                                alert(`Informe o Mês/Ano que deseja gerar a planilha.`);
                                                return false;
                                            }

                                            let datapesq = mesano?.split('/');
                                            let ano = datapesq[1];
                                            let mes = datapesq[0];

                                            alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                                            '## FATURAMENTO - EXCEL ##', // This is a title
                                            `Dependendo da quantidade de registros o processo pode demorar em torno de 3min, aguarde!\nDeseja continuar?`,
                                            {
                                                textConfirm: 'SIM',      // Label of button confirm
                                                textCancel: 'NÃO',     // Label of button cancel
                                                onCancel: () => { { } }, // Call your cancel function 
                                                onConfirm: () => { { 
                                                    Linking.openURL(`${dotenv.SERVER_PHP}/faturamento/exp_excel.php?token=${user?.token}&predio=${user?.codpredio}&mes=${mes}&ano=${ano}`);   
                                                } } // Call your confirm function 
                                            }
                                        )
                                            
                                            
                                        }}
                                        disabled={false}
                                    >               
                                        <View style={{flexDirection: 'row',}}>
                                            <Icon2 name="microsoft-excel" size={30} color="#FFD700" style={{padding:  1,}}/>    
                                        </View>
                                    </TouchableOpacity>
                                    ):null}                                    
                                </View>
                            {loading ? (
                                <>
                                    <ActivityIndicator size={50} color='#004b9e' />
                                    {timeoutloading2 <= 33 ? (<ProgressBar variant="danger" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    {timeoutloading2 > 33 && timeoutloading2 <= 66  ? (<ProgressBar variant="warning" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    {timeoutloading2 > 66 ? (<ProgressBar variant="success" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    
                                </>
                                ):null}
                                    <Table striped bordered hover /* size="sm" fixed="top" */ responsive>
                                        <thead style={{position: 'sticky',top: '0px',backgroundColor:'#FFF', zIndex: 100,}}>
                                            <tr>
                                                <th colSpan={2}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 10, fontWeight:'bold'}}>COLIGADA-PRÉDIO</Text></th>
                                                <th colSpan={2}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={total}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{position:'relative', height: 10, fontSize: 13, fontWeight:'bold', color:'blue', textDecorationLine: 'underline',}}>TOTAL</Text></Button>
                                                        </OverlayTrigger>
                                                </Text></th>
                                                <th colSpan={4}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={recebido}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{position:'relative', height: 10, fontSize: 13, fontWeight:'bold', color:'blue',textDecorationLine: 'underline',}}>RECEBIDO</Text></Button>
                                                        </OverlayTrigger>
                                                </Text></th>
                                                {/* <th colSpan={2}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>ALUNOS/OK</Text></th> */}
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={descjuros}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{position:'relative', height: 10, fontSize: 13, fontWeight:'bold', color:'blue',textDecorationLine: 'underline',}}>DESC./JUROS</Text></Button>
                                                        </OverlayTrigger>
                                                </Text></th>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={pendente}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{position:'relative', height: 10, fontSize: 13, fontWeight:'bold', color:'blue',textDecorationLine: 'underline',}}>PENDENTE</Text></Button>
                                                        </OverlayTrigger>
                                                </Text></th>
                                                {/* <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>ALUNOS/PEND.</Text></th> */}
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>REPASSE(%)</Text></th>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>REPASSE(R$)</Text></th>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>MÊS/ANO</Text></th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody style={{position:'relative', zIndex: 10}}>  
                                            {   
                                                
                                                dados?.map(obj => (
                                                <tr key={obj.ITEM.toString()}>
                                                    <td colSpan={2}><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.COLIGADA}-{obj.NOMEPREDIO}</Text></td>
                                                    <td colSpan={2}><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.FATTOTAL}</Text></td>                                                    
                                                    <td colSpan={4}><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.FATPAGO}</Text></td>                                                    
                                                    {/* <td colSpan={2}><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.ALUNOOK}</Text></td> */}                                                    
                                                    <td><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.DESC_JUROS}</Text></td>                                                    
                                                    <td><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.FATINAD}</Text></td>                                                    
                                                    {/* <td><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10, color: 'red'}}>{obj.ALUNOPEND}</Text></td>   */}                                                  
                                                    <td>{obj.PERCREPASSE=='0%' ? <Text style={{color:'red', fontWeight:'bold', fontSize:Device.osName=='Windows' ? 13 : 10}}>NÃO INFORMADO</Text> : <Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.PERCREPASSE}</Text>}</td>                                                    
                                                    <td>{obj.VLRREPASSE=='-1.00' ? '--' : <Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{numberFormat(obj.VLRREPASSE)}</Text>}</td>                    
                                                    <td><Text style={{fontSize:Device.osName=='Windows' ? 13 : 10}}>{obj.MESANO}</Text></td>
                                                </tr>
                                               ))
                                                
                                            }               
                                        </tbody>
                                    {/* </Table> */}
                                    {user?.codpredio!='000' && dadosalunos.length >0 ? (
                                    <>
                                    {/* <Table striped bordered hover  responsive> */}
                                        <thead style={{position: 'sticky',top: '0px',backgroundColor:'#FFF', zIndex: 100,}}>
                                            <tr>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>COLIGADA</Text></th>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>RA</Text></th>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>NOME</Text></th>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>STATUS</Text></th>
                                                <th><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>VENCIMENTO</Text></th>
                                                <th colSpan={4}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>DATA PAG.</Text></th>
                                                <th colSpan={1}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>VALOR/ORIGINAL</Text></th>
                                                <th colSpan={1}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>VALOR/PAGO</Text></th>
                                                <th colSpan={5}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, fontWeight:'bold'}}>DESCRIÇÃO</Text></th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody style={{position:'relative', zIndex: 10}}>  
                                            {   
                                                dadosalunos?.map(obj => (                                                    
                                                <tr key={obj.ITEM.toString()}>
                                                    <td><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9,}}>{obj.COLIGADA}</Text></td>
                                                    <td><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9,}}>{obj.RA}</Text></td>
                                                    <td><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{obj.NOME}</Text></td>                                                    
                                                    <td><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{obj.STATUS_LAN}</Text></td>                                                    
                                                    <td><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{obj.VENCIMENTO}</Text></td>                    
                                                    <td colSpan={4}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{obj.DATAPAG}</Text></td>                    
                                                    <td colSpan={1}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{obj.VALORORIGINAL}</Text></td>                 
                                                    <td colSpan={1}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{obj.VALORPAGO}</Text></td>                 
                                                    <td colSpan={5}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{obj.DESCRICAO}</Text></td>                    
                                                </tr>))
                                                
                                            }               
                                        </tbody>
                                        {/* <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>                                                    
                                                <td></td>                                                    
                                                <td></td>                    
                                                <td colSpan={4}></td>                    
                                                <td colSpan={1}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{totalvlrorig}</Text></td>                 
                                                <td colSpan={1}><Text style={{fontSize: Device.osName=='Windows' ? 13 : 9, }}>{totalvlrpg}</Text></td>                 
                                                <td colSpan={5}></td>                    
                                           </tr>
                                        </tbody> */}
                                        </>
                                    ):null}
                                    </Table>
                            </View>
                                
                        </Container>
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>
        <StatusBar style="auto" />
        </View>
    )
}
