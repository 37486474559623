import { StatusBar } from 'expo-status-bar';
import React, {useEffect, useContext, useState, } from "react";
import ReactDOM from 'react-dom'
import { Text, View, Dimensions, TouchableOpacity, Linking, Image, ActivityIndicator, Modal, ScrollView } from "react-native";

import { Card, Checkbox, TextInput } from "react-native-paper";
import dotenv from '../../../app.config';
import styles from "../../css/globalstyle";
import Menu from '../menu';
import Icon from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import { AuthContext } from '../../contexts/auth';
import { UtilContext } from '../../contexts/utilidade';
import * as Device from 'expo-device';//Device.osName
import { useIsFocused  } from '@react-navigation/native';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';

const {width, height} = Dimensions.get('screen');

//var mount = document.querySelectorAll('div.browser-mount');
export default function App({navigation}) {
    const { user, signOut } = useContext(AuthContext);
    const { ismenu, setIsMenu, Aviso, iscoligada3, iscoligada5 } = useContext(UtilContext);
    const [loading, setLoading]=useState(false);
    const [dados, setDados]=useState([]);
    const [ranome, setRANOME]=useState('');
    const [mesano, setMesAno]=useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [turma, setTurma] = useState('');
    const [timeoutloading, setTimeOutLoading]=useState(0);
    const [timeoutloading2, setTimeOutLoading2]=useState(100);    
    const [timeoutloading3, setTimeOutLoading3]=useState(0);
    const tempotimeout = 77900;
    const [tempolimite, setTempoLimite] = useState('');
    
    const n1 = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">N1</Popover.Header>
          <Popover.Body>
            <strong>NOTA1 - PARCIAL</strong>
          </Popover.Body>
        </Popover>
      );
    const n2 = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">N2</Popover.Header>
          <Popover.Body>
            <strong>NOTA2- PARCIAL</strong>
          </Popover.Body>
        </Popover>
      );
    const n3 = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">N3</Popover.Header>
          <Popover.Body>
            <strong>NOTA3 - PARCIAL</strong>
          </Popover.Body>
        </Popover>
      );
    const n4 = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">N4</Popover.Header>
          <Popover.Body>
            <strong>NOTA4 - PARCIAL</strong>
          </Popover.Body>
        </Popover>
      );
    const n5 = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">N5</Popover.Header>
          <Popover.Body>
            <strong>NOTA5 - PROVA FINAL</strong>
          </Popover.Body>
        </Popover>
      );    
    const fl = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">FL</Popover.Header>
          <Popover.Body>
            <strong>N° FALTAS</strong>
          </Popover.Body>
        </Popover>
      );    
    const rp = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">RP</Popover.Header>
          <Popover.Body>
            <strong>NOTA RECUPERAÇÃO</strong>
          </Popover.Body>
        </Popover>
      );    
    const md = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">MD</Popover.Header>
          <Popover.Body>
            <strong>MÉDIA</strong>
          </Popover.Body>
        </Popover>
      );    
    const mdf = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">MDF</Popover.Header>
          <Popover.Body>
            <strong>MÉDIA FINAL</strong>
          </Popover.Body>
        </Popover>
      );    

    const focus = useIsFocused();  // useIsFocused as shown 
    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(focus == true){ // if condition required here because it will call the function even when you are not focused in the screen as well, because we passed it as a dependencies to useEffect hook
            document.title = dotenv.TITLE;
            //fetchData();
            setDados([]);
            setRANOME('');
            /* if(user?.codpredio !== '000'){
                //fetchData({ra: '', nome: '', predio: user?.codpredio});
            }else{
                alert('Seu prédio está para acessar todos e neste caso retornará muitos registros podendo ocasionar travamento do sistema.\n Neste caso utilize o filtro ou altere o prédio.');
            } */
        }
    }, [focus]);

    useEffect(() => {   // whenever you are in the current screen, it will be true vice versa
        if(!loading){
          setTimeOutLoading(0);
          setTimeOutLoading2(100);
          setTimeOutLoading3(0);
          setTempoLimite('');
        }
    
        if(loading)
        setTimeout(() => {
          const tempoaut = parseInt(timeoutloading)+1;         
          let tempoaut2 = parseInt(timeoutloading3)+1000;         
          let ms = parseInt(tempotimeout) - parseInt(tempoaut2);
          let sec = Math.floor((ms / 1000) % 60);
          let min = Math.floor((ms / 1000 / 60) % 60);         
          let temp = (min < 10 ? '0' : '') + min + ":" + (sec < 10 ? '0' : '') + sec;                  
          setTimeOutLoading(   tempoaut    )
          setTimeOutLoading2(    /* Math.round( */convertRange( tempoaut, [ 0, tempotimeout/1000 ], [ 99, 0 ] )/* ) */        )
          setTimeOutLoading3(    tempoaut2     )
          setTempoLimite(temp);       
       }, 1000);
    }, [loading, timeoutloading]);

    function timeoutPromise(ms, promise) {
        return new Promise((resolve, reject) => {
          const timeoutId = setTimeout(() => {
            reject(new Error("Servidor não respondeu em tempo hábil"))
          }, ms);
          promise.then(
            (res) => {
              clearTimeout(timeoutId);
              resolve(res);
            },
            (err) => {
              clearTimeout(timeoutId);
              reject(err);
            }
          );
        })
      }

      function convertRange( value, r1, r2 ) { 
        return (( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ]).toFixed(1);
      }

       
    async function fetchData(dados){
        
        setLoading(true);
        setDados([]);
        
        await timeoutPromise(tempotimeout,fetch(`${dotenv.RESTGESTOR}/alunonota`, {
                method: 'POST',
                body: JSON.stringify({
                    ra:dados.ra?? '',
                    nome: dados.nome?? '',
                    predio: user.codpredio=='000' ? '' : user.codpredio,
                    turma: dados.turma?? '',
                    coligada: (iscoligada3 && iscoligada5) ? 0 : iscoligada3 ? 3 : iscoligada5 ? 5 : 0
                }),
                //timeout: 5000,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "x-access-token": user.token,
                },
            }))
            .then((response) => response.json())
            .then((responseJson) => {   
                
                    if (responseJson.validtoken==false){
                        setLoading(false);
                        //alert(responseJson.message); 
                        alert(//https://bestofreactjs.com/repo/zapcriativo-react-native-super-alert
                            '## TOKEN - EXPIRADO ##', // This is a title
                            `${responseJson.message}`,
                            {
                                textConfirm: 'OK',      // Label of button confirm
                                textCancel: '',     // Label of button cancel
                                onCancel: () => { { } }, // Call your cancel function 
                                onConfirm: () => { { 
                                    signOut();
                                } } // Call your confirm function 
                            }
                        )
                        
                        //setLoading(-1);
                        //signOut();
                        return
                    }
                    
                    if(responseJson.sucesso == true){
                        //Aviso("", responseJson.message, "success", 3000);
                        //console.log(responseJson.dados); 
                        setDados(responseJson.dados);
                        setLoading(false);
                    }else{
                        Aviso("", responseJson.message, "danger", 3000);    
                        setLoading(false);                     
                    }
                    
            })
            .catch((error) => {
                Aviso("Error:", error.message, "danger", 3000);  
                setEdtDesativar('');      
                setLoading(false);                               
            })
            .finally(function () { }); 
    }    

    function filtroTurma(){
        //const filtropesq = [];        
        
         if(ranome=='' && turma==''){
            alert('Informe o filtro!\nRA, PARTE NOME OU TURMA.');
            return false
        }

        fetchData({ra: !isNaN(ranome) ? ranome : '', nome:isNaN(ranome) ? ranome : '', predio: user?.codpredio, turma: turma?? ''});
        
        //fetchData({mesano: mesano?? '', ra: !isNaN(ranome) ? ranome : '', nome:isNaN(ranome) ? ranome : '', predio: user?.codpredio, filtro: filtropesq?? ''});
        
        
    }

    const imprimirlista = async () => {  
        
        if(ranome=='' && turma==''){
            alert('Informe o filtro!\nRA, PARTE NOME OU TURMA.');
            return false;
        }
        
        Linking.openURL(`${dotenv.SERVER_PHP}/relatoriopdf/notaalunosgestor.php?token=${user?.token}&ra=${!isNaN(ranome) ? ranome : ''}&predio=${user?.codpredio=='000' ? '' : user?.codpredio}&nome=${isNaN(ranome) ? ranome : ''}&turma=${turma?? ''}&coligada=${(iscoligada3 && iscoligada5) ? 0 : iscoligada3 ? 3 : iscoligada5 ? 5 : 0}`);   
            
        
    }

    function validarfiltroData(v){
        v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
        v=v.replace(/^(\d{2})(\d)/,"$1/$2")             //Coloca ponto entre o segundo e o terceiro dígitos
        //v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
        //v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
        //v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
        setMesAno(v);
      }

    
    return (
        <View style={styles.container}>
            {(ismenu ||Device.osName=='Windows' ) ? (
            <View style={[styles.layoutesq,{position: Device.osName!='Windows' ? 'absolute': null, backgroundColor: '#ddd', zIndex: 1000}]}>
                <Menu navigation={navigation}/>
            </View>
            ):null}
            <View style={[styles.layoutdirsup,{zIndex: 10, left: ismenu ? 150 : null}]}>
            {Device.osName!='Windows' ? (
            <View style={{flex:1,zIndex:10,margin:10,position:'absolute',justifyContent:'flex-start',width: Device.osName=='Windows' ?80 : 50,  height: 50}}>
                <TouchableOpacity
                    style={{ borderRadius: 5,  backgroundColor: '#004b9e',  width: '100%', alignItems: 'center', justifyContent: 'center' }}
                    onPress={() => {
                    setIsMenu(!ismenu)
                    }}
                    disabled={false}
                >               
                    <View style={{width: '100%',flexDirection: 'row',}}>
                    <Icon name={ismenu ? "compress-arrows-alt":"align-justify"} size={Device.osName=='Windows' ?20 : 15} color="#FFD700" style={{padding:  3,}}/>
                    <Text style={{ padding: 3 , fontSize: Device.osName=='Windows'? 12 : 9, textAlign: 'center', alignSelf: 'center', color: '#FFF', fontWeight: 'bold' }} >
                        MENU
                    </Text>
                    </View>
                </TouchableOpacity>
            </View>
            ):null}
                <View style={{position: 'relative',width: '100%', height:'12%',alignItems: 'center', justifyContent:'space-between', top:5}}>
                    {Device.osName!='Windows' ? (
                    <View style={{ position: 'absolute',zIndex:10, top:5, width: 40, backgroundColor: '#FFF' , marginLeft: 5, borderRadius: 5 }}>
                        <Image style={styles.logohomecel} source={require('../../imagem/literatus3.png')} />
                    </View>
                    ):null}
                    <Card style={[styles.card,{}]}>
                        <View style={{position:'relative',width: width>400 ? width*0.80 : ismenu ? width*0.50 : width*0.90, top: Device.osName=='Windows' ? null : 10 ,flexDirection:'row', alignItems: 'center', justifyContent:'space-between',}}>
                            <View style={{width: Device.osName=='Windows' ? '100%' : 200, top: Device.osName=='Windows' ? null : 10,padding:Device.osName=='Windows'?30: 15,height: Device.osName=='Windows' ? 60 : 50, alignItems:'center', justifyContent:'center'}}>
                                <Text style={{alignItems:'center', justifyContent:'center',fontSize: Device.osName=='Windows'?'30px':'20px',fontWeight:'bold', color:'#696969', fontSize: Device.osName=='Windows' ? 20 : 15}}>
                                   {`:: NOTAS DE AVALIAÇÕES ${iscoligada3 && iscoligada5 ? 'COLIGADA: 3 e 5' : iscoligada3 ? 'COLIGADA: 3' : iscoligada5 ? 'COLIGADA: 5' : ''} ::`}
                                </Text>
                            </View>
                        </View>                           
                    </Card>
                </View>
                <View style={{width: '100%', height:Device.osName=='Windows'?'80%':'80%',alignItems: 'center', justifyContent:'center', /* top: 2 */}}>
                    <Card style={styles.card}>
                        <View style={{flex:1, position:'relative',width:'100%', height:'100%'}}>
                        <Container fluid className="Rules-Container">
                            <View style={{position:'relative',/* overflow:'scroll', */ height: 450, minWidth: '70vw', width:'83vw'}}>                            
                                <View style={{position:'relative', flexDirection: 'row',top: -5}}>                                     
                                    <TextInput 
                                        //label={'Ra / Parte Nome'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Ra / Parte Nome</Text>}
                                        autoCorrect={false}
                                        autoComplete={false}
                                        //placeholder={'Ex.: João Silva'}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        style={{margin: 1,marginRight:30}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={ranome}
                                        onChangeText={(text) => {setRANOME(text)}}       
                                        onSubmitEditing={()=>filtroTurma()}                 
                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroTurma()}} />} 
                                    />                                                                   
                                    <TextInput 
                                        //label={'Turma'}
                                        label={<Text style={{backgroundColor:"#FFF", padding: 5}}>Turma</Text>}
                                        autoCorrect={false}
                                        autoComplete={false}
                                        //placeholder={'Ex.: João Silva'}
                                        //style={{width: Device.osName=='Windows' ? 300 : '100%', margin:Device.osName=='Windows' ? 10 : null, height: Device.osName=='Windows' ? null : 40, fontSize: Device.osName=='Windows' ? null : 12}}
                                        style={{margin: 1,marginRight:30}}
                                        mode='outlined'
                                        selectionColor={'#004b9e'}
                                        underlineColor={'#004b9e'}
                                        activeUnderlineColor={'#004b9e'}
                                        outlineColor={'#004b9e'}
                                        activeOutlineColor={'#004b9e'}
                                        textColor={'#004b9e'}
                                        dense={true}
                                        value={turma}
                                        onChangeText={(text) => {setTurma(text)}}       
                                        onSubmitEditing={()=>filtroTurma()}                 
                                        right={<TextInput.Icon icon="text-search" disabled={false} onPress={()=>{filtroTurma()}} />} 
                                    />     
                                    <TouchableOpacity
                                        style={{ borderRadius: 5,  backgroundColor: '#004b9e',  height: 35,  width: 50, alignItems: 'center', justifyContent: 'center', top: 10, marginLeft: 100 }}
                                        onPress={() => {
                                            imprimirlista();
                                        }}
                                        disabled={false}
                                    >               
                                        <View style={{/* width: 50, */flexDirection: 'row',}}>
                                            <Icon name="print" size={20} color="#FFD700" style={{padding:  3,}}/>    
                                        </View>
                                    </TouchableOpacity>                                                                  
                                </View>
                            {loading ? (
                            <>    
                            <ActivityIndicator size={50} color='#004b9e' />
                                    {timeoutloading2 <= 33 ? (<ProgressBar variant="danger" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    {timeoutloading2 > 33 && timeoutloading2 <= 66  ? (<ProgressBar variant="warning" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                                    {timeoutloading2 > 66 ? (<ProgressBar variant="success" animated now={timeoutloading2} label={`Tempo ${tempolimite} restante para cancelar consultar no servidor.`} />) :null}
                            </>
                            ):null}
                                    <Table striped bordered hover /* size="sm" fixed="top" */ responsive>
                                        <thead style={{position: 'sticky',top: '0px',backgroundColor:'#FFF', zIndex: 100,}}>
                                            <tr>
                                                {/* <th><Text style={{fontSize: 13, fontWeight:'bold'}}>#</Text></th> */}
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>COLIGADA</Text></th>
                                                <th colSpan={2}><Text style={{fontSize: 13, fontWeight:'bold'}}>PRÉDIO</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>TURMA</Text></th>
                                                <th><Text style={{fontSize: 13, fontWeight:'bold'}}>RA</Text></th>
                                                <th colSpan={2}><Text style={{fontSize: 13, fontWeight:'bold'}}>ALUNO(A)</Text></th>
                                                <th colSpan={2}><Text style={{fontSize: 13, fontWeight:'bold'}}>DISCIPLINA</Text></th>
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={n1}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{fontSize: 13, fontWeight:'bold', color:'blue', textDecorationLine: 'underline'}}>N1</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                                {/* <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={n2}>
                                                            <Button variant=""><Text style={{fontSize: 13, fontWeight:'bold', color:'blue'}}>N2</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={n3}>
                                                            <Button variant=""><Text style={{fontSize: 13, fontWeight:'bold', color:'blue'}}>N3</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={n4}>
                                                            <Button variant=""><Text style={{fontSize: 13, fontWeight:'bold', color:'blue'}}>N4</Text></Button>
                                                        </OverlayTrigger>
                                                </th> */}
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={n5}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{fontSize: 13, fontWeight:'bold', color:'blue', textDecorationLine: 'underline'}}>N5</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={fl}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{fontSize: 13, fontWeight:'bold', color:'blue', textDecorationLine: 'underline'}}>FL</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={md}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{fontSize: 13, fontWeight:'bold', color:'blue', textDecorationLine: 'underline'}}>MD</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={rp}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{fontSize: 13, fontWeight:'bold', color:'blue', textDecorationLine: 'underline'}}>RP</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                                <th style={{width: 20}}>
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={mdf}>
                                                            <Button variant="" style={{height: 20, marginTop: -25}}><Text style={{fontSize: 13, fontWeight:'bold', color:'blue', textDecorationLine: 'underline'}}>MDF</Text></Button>
                                                        </OverlayTrigger>
                                                </th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody style={{position:'relative', zIndex: 10}}>  
                                        {   
                                                dados?.map(obj => (
                                                    
                                                <tr key={obj.ITEM.toString()}>                                                    
                                                    {/* <td><Text style={{fontSize: 10}}>{obj.ITEM}</Text></td> */}
                                                    <td><Text style={{fontSize: 10}}>{obj.COLIGADA}</Text></td>
                                                    <td colSpan={2}><Text style={{fontSize: 10}}>{obj.NOMEPREDIO}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.TURMA}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.RA}</Text></td>
                                                    <td colSpan={2}><Text style={{fontSize: 10}}>{obj.ALUNO}</Text></td>
                                                    <td colSpan={2}><Text style={{fontSize: 10}}>{obj.DISCIPLINA}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.N1}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.N5}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.FL}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.MD}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.RP}</Text></td>
                                                    <td><Text style={{fontSize: 10}}>{obj.MDF}</Text></td>
                                                                                                       
                                                </tr>))
                                                
                                            }               
                                        </tbody>
                                    </Table>
                            </View>
                                
                        </Container>
                        </View>
                    </Card>
                </View>
                <View style={{width: '100%', heigth: '10%', position: 'relative', alignItems: 'flex-end', padding: 2, right: 10}}>
                    <TouchableOpacity onPress={() => {
                        /*Abrir Link da prefeitura para visualizar/imprimir nota */                
                        const url = `https://t2kconsultoria.com.br`
                        Linking.openURL(url);
                    }}>
                        <Text style={{fontSize: '12px', fontWeight: 'bold', color: '#00'}}>Desenvolvido por T2K Consultoria - www.t2kconsultoria.com.br </Text>
                    </TouchableOpacity>
                </View>
            </View>            
        <StatusBar style="auto" />
        </View>
    )
}
